var disableBodyScroll = function () {
	var a = false,
		o = false,
		i;
	if (!Element.prototype.matches) Element.prototype.matches = Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector;
	if (!Element.prototype.closest) Element.prototype.closest = function (e) {
		var t = this;
		if (!document.documentElement.contains(el)) return null;
		do {
			if (t.matches(e)) return t;
			t = t.parentElement
		} while (t !== null);
		return el
	};
	var n = function (e) {
		if (false === o || !e.target.closest(a)) {
			e.preventDefault()
		}
	};
	var s = function (e) {
		if (e.targetTouches.length === 1) {
			i = e.targetTouches[0].clientY
		}
	};
	var r = function (e) {
		if (e.targetTouches.length !== 1) {
			return
		}
		var t = e.targetTouches[0].clientY - i;
		if (o.scrollTop === 0 && t > 0) {
			e.preventDefault()
		}
		if (o.scrollHeight - o.scrollTop <= o.clientHeight && t < 0) {
			e.preventDefault()
		}
	};
	return function (e, t) {
		if (typeof t !== "undefined") {
			a = t;
			o = document.querySelector(t)
		}
		if (true === e) {
			if (false !== o) {
				o.addEventListener("touchstart", s, {
					passive: false
				});
				o.addEventListener("touchmove", r, {
					passive: false
				})
			}
			$("body, html").css("overflow", "hidden");
			document.body.addEventListener("touchmove", n, {
				passive: false
			})
		} else {
			if (false !== o) {
				o.removeEventListener("touchstart", s, {
					passive: false
				});
				o.removeEventListener("touchmove", r, {
					passive: false
				})
			}
			$("body, html").css("overflow", "");
			document.body.removeEventListener("touchmove", n, {
				passive: false
			})
		}
	}
}();

function GlAnimation(h) {	
	
	var t = h.title;
	var a = 0;
	var o = 0;
	var e, l, i, n, s, r, c, f, d, p, m, u = false,
		g, w, b, v, x, _, y, T, M, k, j, C, E, O, S = 0,
		P = 0,
		H = 30,
		A = window.innerHeight * 1.15,
		D = window.innerWidth,
		L = D / 2,
		I = A / 2,
		V = D / A,
		F = Math.PI,
		R = 0,
		G = 0,
		B = 0,
		z = 1,
		Y = Math.cos,
		W = Math.sin,
		X = new TimelineMax({
			paused: true
		}),
		q = new TimelineMax({
			paused: true
		}),
		N = new TimelineMax({
			paused: true
		}),
		U = new TimelineMax({
			paused: true
		}),
		Z = new TimelineMax({
			paused: true
		}),
		J = new TimelineMax({
			paused: true
		}),
		Q = new TimelineMax({
			paused: true
		}),
		K = new TimelineMax({
			paused: true
		}),
		ee = new TimelineMax({
			paused: true
		}),
		te = new TimelineMax({
			paused: true
		}),
		ae = new THREE.Vector3(0, 0, 0);
	var oe = new THREE.LoadingManager;
	var ie = new THREE.JSONLoader(oe),
		ne = new THREE.TextureLoader(oe),
		se = new TimelineMax({
			paused: true
		}),
		re = new THREE.Clock,
		le = re.getDelta(),
		x = new THREE.Object3D;
	var ce = h.mouseInfluence,
		fe = 0,
		de = 0,
		pe = 100,
		ue = 5,
		H = 30;
	/*var he = "/../public/imgs/studio_1/",
		me = "/../public/imgs/studio_2/";
	var ge = ne.load("/../public/obj/tex/lightMap.jpg");*/
	var we = function () {
		var e = (new THREE.Box3).setFromObject(x);
		var t = e.getSize(ae);
		if (D <= h.desktopMaxWidth) {
			var a = h.objsGroup_ScaleDesktop;
			x.scale.set(a, a, a);
			x.position.set(h.objGroupX_desktop, h.objGroupY_desktop, h.objGroupZ_desktop);
			if (typeof y !== "undefined") {
				y.scale.set(h.objText_ScaleDesktop, h.objText_ScaleDesktop, h.objText_ScaleDesktop)
			}
			if (typeof m !== "undefined") {
				m.scale.set(h.objLetter_ScaleDesktop, h.objLetter_ScaleDesktop, h.objLetter_ScaleDesktop)
			}
			if (typeof objExtra1 !== "undefined") {
				objExtra1.scale.set(h.objExtra1_ScaleDesktop, h.objExtra1_ScaleDesktop, h.objExtra1_ScaleDesktop)
			}
			if (typeof objExtra2 !== "undefined") {
				objExtra2.scale.set(h.objExtra2_ScaleDesktop, h.objExtra2_ScaleDesktop, h.objExtra2_ScaleDesktop);
				objExtra2.position.set(0, h.objExtra2_Y_ScaleDesktop, 0)
			}
		} else if (D <= h.desktopMaxMaxWidth) {
			var a = h.objsGroup_ScaleMaxDesktop;
			x.scale.set(a, a, a);
			x.position.set(h.objGroupX_MaxDesktop, h.objGroupY_MaxDesktop, h.objGroupZ_MaxDesktop);
			if (typeof y !== "undefined") {
				y.scale.set(h.objText_ScaleMaxDesktop, h.objText_ScaleMaxDesktop, h.objText_ScaleMaxDesktop)
			}
			if (typeof m !== "undefined") {
				m.scale.set(h.objLetter_ScaleMaxDesktop, h.objLetter_ScaleMaxDesktop, h.objLetter_ScaleMaxDesktop)
			}
			if (typeof objExtra1 !== "undefined") {
				objExtra1.scale.set(h.objExtra1_ScaleMaxDesktop, h.objExtra1_ScaleMaxDesktop, h.objExtra1_ScaleMaxDesktop)
			}
			if (typeof objExtra2 !== "undefined") {
				objExtra2.scale.set(h.objExtra2_ScaleMaxDesktop, h.objExtra2_ScaleMaxDesktop, h.objExtra2_ScaleMaxDesktop);
				objExtra2.position.set(0, h.objExtra2_Y_ScaleMaxDesktop, 0)
			}
		} else {
			var a = h.objWidthBigDesktop;
			x.scale.set(a, a, a);
			x.position.set(h.objGroupX_bigDesktop, h.objGroupY_bigDesktop, h.objGroupZ_bigDesktop);
			if (typeof y !== "undefined") {
				y.scale.set(h.objText_ScaleBigDesktop, h.objText_ScaleBigDesktop, h.objText_ScaleBigDesktop)
			}
			if (typeof m !== "undefined") {
				m.scale.set(h.objLetter_ScaleBigDesktop, h.objLetter_ScaleBigDesktop, h.objLetter_ScaleBigDesktop)
			}
			if (typeof objExtra1 !== "undefined") {
				objExtra1.scale.set(h.objExtra1_ScaleBigDesktop, h.objExtra1_ScaleBigDesktop, h.objExtra1_ScaleBigDesktop)
			}
			if (typeof objExtra2 !== "undefined") {
				objExtra2.scale.set(h.objExtra2_ScaleBigDesktop, h.objExtra2_ScaleBigDesktop, h.objExtra2_ScaleBigDesktop);
				objExtra2.position.set(0, h.objExtra2_Y_ScaleBigDesktop, 0)
			}
		}
		x.updateMatrix()
	};
	var be = function () {
		//var e = re.getDelta();
		//if (w) {
		//	w.update(e)
		//}
		//i.lookAt(l.position);
		//n.render(l, i);
		//at()
	};

	function ve(e, t, a) {
		return Number(((e - a) / (t - a)).toFixed(2))
	}

	function xe(e, t, a, o, i) {
		return (a - t) * (e - o) / (i - o) + t
	}

	function _e(e, t, a) {
		return h.aniTime * 1e3 / e * t - a
	}

	function ye(e) {
		return e * F / 180
	}

	function $e(e, t) {
		return Math.floor(Math.random() * (t - e + 1)) + e
	}
	/*
	var Te = he;
	var Me = ".jpg";
    var ke = [Te + "px" + Me, Te + "nx" + Me, Te + "py" + Me, Te + "ny" + Me, Te + "pz" + Me, Te + "nz" + Me];
   var je = (new THREE.CubeTextureLoader).load(ke);
	je.format = THREE.RGBFormat;
	je.mapping = THREE.CubeReflectionMapping;
    var Te = me;
	var Me = ".jpg";
	var ke = [Te + "px" + Me, Te + "nx" + Me, Te + "py" + Me, Te + "ny" + Me, Te + "pz" + Me, Te + "nz" + Me];
	var Ce = (new THREE.CubeTextureLoader).load(ke);
	Ce.format = THREE.RGBFormat;
	Ce.mapping = THREE.CubeReflectionMapping;*/

	function Ee() {
		switch (t) {
			case "home":
				container = $(".page-header #webGL-container");
				container.append('<div class="webgl-fallback"><img src="/public/obj/imgFallBack/home.png)" alt="Webgl Fallback"></div>');
				TweenMax.fromTo(container, .5, {
					opacity: 0
				}, {
					opacity: 1,
					ease: Power1.easeInOut
				});
				break;
			case "what-we-do":
				container = $(".page-header #webGL-container");
				container.append('<div class="webgl-fallback"><img src="/public/obj/imgFallBack/about.png)" alt="Webgl Fallback"></div>');
				TweenMax.fromTo(container, .5, {
					opacity: 0
				}, {
					opacity: 1,
					ease: Power1.easeInOut
				});
				break;
			case "contact":
				container = $(".page-header #webGL-container");
				container.append('<div class="webgl-fallback"><img src="/public/obj/imgFallBack/contact.png)" alt="Webgl Fallback"></div>');
				TweenMax.fromTo(container, .5, {
					opacity: 0
				}, {
					opacity: 1,
					ease: Power1.easeInOut
				});
				break;
			case "jobs":
				container = $(".page-header #webGL-container");
				container.append('<div class="webgl-fallback"><img src="/public/obj/imgFallBack/jobs.png)" alt="Webgl Fallback"></div>');
				TweenMax.fromTo(container, .5, {
					opacity: 0
				}, {
					opacity: 1,
					ease: Power1.easeInOut
				});
				break;
			case "who-we-are":
				container = $(".page-header #webGL-container");
				container.append('<div class="webgl-fallback"><img src="/public/obj/imgFallBack/near.png)" alt="Webgl Fallback"></div>');
				TweenMax.fromTo(container, .5, {
					opacity: 0
				}, {
					opacity: 1,
					ease: Power1.easeInOut
				});
				break;
			case "software":
				container = $(".page-header #webGL-container");
				container.append('<div class="webgl-fallback"><img src="/public/obj/imgFallBack/soft.png)" alt="Webgl Fallback"></div>');
				TweenMax.fromTo(container, .5, {
					opacity: 0
				}, {
					opacity: 1,
					ease: Power1.easeInOut
				});
				break;
			case "outsourcing":
				container = $(".page-header #webGL-container");
				container.append('<div class="webgl-fallback"><img src="/public/obj/imgFallBack/out.png)" alt="Webgl Fallback"></div>');
				TweenMax.fromTo(container, .5, {
					opacity: 0
				}, {
					opacity: 1,
					ease: Power1.easeInOut
				});
				break
		}
	}

	function Oe() {
		try {
			var e = document.createElement("canvas");
			return !!window.WebGLRenderingContext && (e.getContext("webgl") || e.getContext("experimental-webgl"))
		} catch (e) {
			return false;
			Ee()
		}
	}

	function Se() {
		var t = document.getElementsByTagName("canvas");
		t[0].addEventListener("webglcontextlost", function (e) {
			e.preventDefault();
			Ee();
			t[0].style.opacity = 0
		}, false)
	}

	function Pe() {
		var o = "image/octet-stream";
		var i = function (e, t) {
			var a = document.createElement("a");
			if (typeof a.download === "string") {
				document.body.appendChild(a);
				a.download = t;
				a.href = e;
				a.click();
				document.body.removeChild(a)
			} else {
				location.replace(uri)
			}
		};

		function e() {
			var e, t;
			try {
				var a = "image/png";
				e = n.domElement.toDataURL(a);
				i(e.replace(a, o), "frame.png")
			} catch (e) {
				return
			}
		}
		document.getElementsByClassName("page-main")[0].addEventListener("click", function () {
			e()
		})
	}

	function He() {
		document.getElementsByClassName("page-main")[0].addEventListener("click", function () {
			if (n) {
				n.forceContextLoss()
			}
		})
	}
	var Ae = function () {
		l = new THREE.Scene;
		i = new THREE.PerspectiveCamera(45, D / A, .1, 1e3);
		n = Oe() ? new THREE.WebGLRenderer({
			antialias: true,
			alpha: true,
			stencil: false,
			preserveDrawingBuffer: false
		}) : new THREE.CanvasRenderer;
		n.setClearColor(0, 0);
		n.shadowMap.autoUpdate = false;
		n.setSize(D, A);
		var e = 1.75;
		var t = Math.min(e, window.devicePixelRatio);
		n.setPixelRatio(t);
		i.position.x = fe;
		i.position.y = de;
		i.position.z = pe
	};
	var De = function () {
		TweenMax.ticker.addEventListener("tick", be);
		document.addEventListener("mousemove", tt, false);
		$(window).on("resize.titlesAnimation", function () {
			et()
		})
	};

	function Le(e) {
		function a(e, t) {
			if (t === false) {
				e.wasFrustumCulled = e.frustumCulled;
				e.wasVisible = e.visible;
				e.visible = true;
				e.frustumCulled = false
			} else {
				e.visible = e.wasVisible;
				e.frustumCulled = e.wasFrustumCulled
			}
			e.children.forEach(function (e) {
				a(e, t)
			})
		}
		a(l, false);
		n.render(l, i);
		n.clear();
		a(l, true);
		e()
	}
	var Ie = function (e) {
		switch (t) {
			case "home":
				container = $(".page-header #webGL-container");
				Ae();
				qe();
				container.append(n.domElement);
				oe.onLoad = function () {
					Le(function () {
						De()
					})
				};
				break;
			case "newsletter":
				container = $(".page-footer #webGL-container");
				Ae();
				n.shadowMap.enabled = true;
				n.shadowMap.type = THREE.PCFSoftShadowMap;
				//Fe();
				Xe();
				container.append(n.domElement);
				oe.onLoad = function () {
					Le(function () {
						De()
					})
				};
				break;
			case "what-we-do":
				container = $(".page-header #webGL-container");
				Ae();
				qe();
				container.append(n.domElement);
				oe.onLoad = function () {
					Le(function () {
						De()
					})
				};
				break;
			case "contact":
				container = $(".page-header #webGL-container");
				Ae();
				//Ge();
				//Ne();
				container.append(n.domElement);
				oe.onLoad = function () {
					Le(function () {
						De()
					})
				};
				break;
			case "jobs":
				container = $(".page-header #webGL-container");
				Ae();
				Be();
				Ue();
				container.append(n.domElement);
				oe.onLoad = function () {
					Le(function () {
						De()
					})
				};
				break;
			case "who-we-are":
				container = $(".page-header #webGL-container");
				Ae();
				ze();
				$(".webGL-main").addClass("owleyes");
				Ze();
				container.append(n.domElement);
				oe.onLoad = function () {
					Le(function () {
						De();
						ct()
					})
				};
				break;
			case "software":
				container = $(".page-header #webGL-container");
				Ae();
				Ye();
				Qe();
				container.append(n.domElement);
				oe.onLoad = function () {
					Le(function () {
						De();
						ft()
					})
				};
				break;
			case "outsourcing":
				container = $(".page-header #webGL-container");
				Ae();
				We();
				Ke();
				container.append(n.domElement);
				oe.onLoad = function () {
					Le(function () {
						De();
						dt()
					})
				};
				break
		}
		if (typeof e == "function") e();
		Se()
	};
/*
	function Ve() {
		ie.load("/../public/obj/objHome.json", function (e, t) {
			t = new THREE.MeshStandardMaterial({
				color: 16777215,
				envMap: Ce,
				envMapIntensity: .55,
				emissive: 16777215,
				emissiveIntensity: .74,
				roughness: .68,
				metalness: .91,
				transparent: true,
				opacity: 0,
				morphTargets: true
			});
			e.faceVertexUvs[1] = e.faceVertexUvs[0];
			m = new THREE.Mesh(e, t);
			m.rotation.set(0, 0, 0);
			m.position.set(0, 0, 0);
			we();
			w = new THREE.AnimationMixer(m);
			b = THREE.AnimationClip.CreateFromMorphTargetSequence("letterMorph", m.geometry.morphTargets, 1, true);
			var a = w.clipAction(b);
			a.loop = THREE.LoopOnce;
			a.setDuration(h.aniTime).play();
			a.paused = true;
			x.add(m);
			X.fromTo(m.position, h.aniTimeLoop, {
				y: 2
			}, {
				y: 3,
				ease: Power1.easeInOut,
				repeat: -1,
				yoyo: true
			});
			X.play();
			TweenMax.fromTo(m.material, h.aniTimeFadeIn, {
				opacity: 0
			}, {
				opacity: .95,
				ease: Power1.easeOut
			}, 0);
			TweenMax.fromTo(m.position, h.aniTimeFadeIn, {
				z: 0
			}, {
				z: 35,
				ease: Power1.easeOut
			}, 0)
		});
		l.add(x)
	}
/*
	function Fe() {
		var e = "/../public/obj/objNewsletter.json";
		x = new THREE.Object3D;
		ie.load(e, function (e, t) {
			t = new THREE.MeshStandardMaterial({
				color: 16777215,
				lightMap: ge,
				envMap: je,
				envMapIntensity: 1,
				ligthMapIntensity: 1,
				envMapIntensity: 1,
				roughness: .7,
				opacity: .9,
				transparent: true,
				morphTargets: true
			});
			e.faceVertexUvs[1] = e.faceVertexUvs[0];
			objNew = new THREE.Mesh(e, t);
			objNew.castShadow = true;
			objNew.scale.set(13, 13, 13);
			objNew.rotation.set(0, 0, 0);
			objNew.position.set(0, 0, 0);
			w = new THREE.AnimationMixer(objNew);
			b = THREE.AnimationClip.CreateFromMorphTargetSequence("letterMorph", objNew.geometry.morphTargets, 1, true);
			var a = w.clipAction(b);
			a.loop = THREE.LoopOnce;
			a.setDuration(h.aniTime).play();
			a.paused = true;
			x.add(objNew);
			we();
			n.render(l, i)
		});
		var t = new THREE.ShadowMaterial;
		t.opacity = .08;
		v = new THREE.Mesh(new THREE.PlaneGeometry(2e3, 2e3), t);
		var a = .25;
		v.scale.set(a, a, a);
		v.receiveShadow = true;
		v.rotation.set(-20, 0, 0);
		v.position.set(0, -20, 35);
		x.add(v);
		l.add(x);
		n.render(l, i)
	}

	function Re() {
		ie.load("/../public/obj/objAbout.json", function (e, t) {
			t = new THREE.MeshStandardMaterial({
				color: 8740564,
				envMap: je,
				envMapIntensity: 1,
				emissive: 8740564,
				emissiveIntensity: .85,
				roughness: .54,
				metalness: 1,
				transparent: true,
				opacity: 0,
				morphTargets: true
			});
			e.faceVertexUvs[1] = e.faceVertexUvs[0];
			m = new THREE.Mesh(e, t);
			m.rotation.set(0, 0, 0);
			m.position.set(0, 0, 0);
			we();
			w = new THREE.AnimationMixer(m);
			b = THREE.AnimationClip.CreateFromMorphTargetSequence("letterMorph", m.geometry.morphTargets, 1, true);
			var a = w.clipAction(b);
			a.loop = THREE.LoopOnce;
			a.setDuration(h.aniTime).play();
			a.paused = true;
			x.add(m);
			we();
			l.add(x);
			X.fromTo(m.position, h.aniTimeLoop, {
				y: 2
			}, {
				y: 3,
				ease: Power1.easeInOut,
				repeat: -1,
				yoyo: true
			});
			X.play();
			TweenMax.fromTo(m.material, h.aniTimeFadeIn, {
				opacity: 0
			}, {
				opacity: .95,
				ease: Power1.easeOut
			}, 0);
			TweenMax.fromTo(m.position, h.aniTimeFadeIn, {
				z: 0
			}, {
				z: 35,
				ease: Power1.easeOut
			}, 0)
		})
	}

	function Ge() {
		ie.load("/../public/obj/objContact.json", function (e, t) {
			t = new THREE.MeshStandardMaterial({
				color: 16215662,
				envMap: je,
				envMapIntensity: 1,
				emissive: 16215662,
				emissiveIntensity: .83,
				roughness: .54,
				metalness: 1,
				transparent: true,
				opacity: 0,
				morphTargets: true
			});
			e.faceVertexUvs[1] = e.faceVertexUvs[0];
			m = new THREE.Mesh(e, t);
			m.scale.set(1, 1, 1);
			m.rotation.set(0, 0, 0);
			m.position.set(0, 0, 0);
			m.matrixAutoUpdate = true;
			w = new THREE.AnimationMixer(m);
			b = THREE.AnimationClip.CreateFromMorphTargetSequence("letterMorph", m.geometry.morphTargets, 1, true);
			var a = w.clipAction(b);
			a.loop = THREE.LoopOnce;
			a.setDuration(h.aniTime).play();
			a.paused = true;
			x.add(m);
			we();
			X.fromTo(m.position, h.aniTimeLoop, {
				y: 2
			}, {
				y: 3,
				ease: Power1.easeInOut,
				repeat: -1,
				yoyo: true
			});
			X.play();
			TweenMax.fromTo(m.material, h.aniTimeFadeIn, {
				opacity: 0
			}, {
				opacity: .95,
				ease: Power1.easeOut
			}, 0);
			TweenMax.fromTo(m.position, h.aniTimeFadeIn, {
				z: 0
			}, {
				z: 35,
				ease: Power1.easeOut
			}, 0)
		});
		l.add(x)
	}

	function Be() {
		ie.load("/../public/obj/obJobs.json", function (e, t) {
			t = new THREE.MeshStandardMaterial({
				color: 1880203,
				envMap: je,
				envMapIntensity: .6,
				emissive: 1880203,
				emissiveIntensity: 1,
				roughness: .59,
				metalness: 1,
				transparent: true,
				opacity: 0,
				morphTargets: true
			});
			e.faceVertexUvs[1] = e.faceVertexUvs[0];
			m = new THREE.Mesh(e, t);
			m.scale.set(1, 1, 1);
			m.rotation.set(0, 0, 0);
			m.position.set(0, 0, 0);
			w = new THREE.AnimationMixer(m);
			b = THREE.AnimationClip.CreateFromMorphTargetSequence("letterMorph", m.geometry.morphTargets, 1, true);
			var a = w.clipAction(b);
			a.loop = THREE.LoopOnce;
			a.setDuration(h.aniTime).play();
			a.paused = true;
			x.add(m);
			we();
			X.fromTo(m.position, h.aniTimeLoop, {
				y: 2
			}, {
				y: 3,
				ease: Power1.easeInOut,
				repeat: -1,
				yoyo: true
			});
			X.play();
			TweenMax.fromTo(m.material, h.aniTimeFadeIn, {
				opacity: 0
			}, {
				opacity: .95,
				ease: Power1.easeOut
			}, 0);
			TweenMax.fromTo(m.position, h.aniTimeFadeIn, {
				z: 0
			}, {
				z: 35,
				ease: Power1.easeOut
			}, 0)
		});
		l.add(x)
	}

	function ze() {
		var e = "/../public/obj/objNear.json",
			t = "/../public/obj/text.json",
			a = "/../public/obj/owl.json";
		var o = ne.load("/../public/obj/tex/colorMapOwl.jpg"),
			i = ne.load("/../public/obj/tex/lightMapOwl.jpg"),
			n = ne.load("/../public/obj/tex/transMapOwl.jpg"),
			s = ne.load("/../public/obj/tex/colorMapTrunk.jpg"),
			r = ne.load("/../public/obj/tex/transMapTrunk.jpg");
		ie.load(t, function (e, t) {
			objTextMaterial = new THREE.MeshBasicMaterial({
				skinning: true,
				color: 0,
				transparent: true,
				opacity: .85,
				opacity: 0
			});
			y = new THREE.SkinnedMesh(e, objTextMaterial);
			e.faceVertexUvs[1] = e.faceVertexUvs[0];
			y.rotation.set(0, 0, 0);
			y.position.set(0, 0, 0);
			we();
			x.add(y);
			y.skeleton.bones[0].rotation.x = 0;
			typeMovement = 10;
			var a = [y.skeleton.bones[1].position, y.skeleton.bones[2].position, y.skeleton.bones[3].position, y.skeleton.bones[4].position, y.skeleton.bones[5].position, y.skeleton.bones[6].position, y.skeleton.bones[7].position, y.skeleton.bones[8].position, y.skeleton.bones[9].position];
			Q.fromTo(y.position, h.aniTimeLoop * .25, {
				y: -3
			}, {
				y: 4.5,
				ease: Power1.easeOut
			}, "start -= 0").fromTo(y.material, h.aniTimeLoop * .1, {
				opacity: 0
			}, {
				opacity: 1,
				ease: Power1.easeOut,
				onComplete: function () {
					N.play()
				}
			}, "start -= 0").staggerTo(a, h.aniTimeLoop, {
				y: .18,
				ease: Power1.easeInOut,
				repeat: -1,
				yoyo: true
			}, .5, "loop -= 6")
		});
		ie.load(e, function (e, t) {
			t = new THREE.MeshStandardMaterial({
				color: 27900,
				envMap: je,
				envMapIntensity: 1,
				emissive: 27900,
				emissiveIntensity: .83,
				roughness: .54,
				metalness: 1,
				transparent: true,
				opacity: 0,
				depthWrite: false,
				depthTest: false,
				morphTargets: true
			});
			e.faceVertexUvs[1] = e.faceVertexUvs[0];
			m = new THREE.Mesh(e, t);
			m.rotation.set(0, 0, 0);
			m.position.set(0, 0, -5);
			we();
			w = new THREE.AnimationMixer(m);
			b = THREE.AnimationClip.CreateFromMorphTargetSequence("letterMorph", m.geometry.morphTargets, 1, true);
			var a = w.clipAction(b);
			a.loop = THREE.LoopOnce;
			a.setDuration(h.aniTime).play();
			a.paused = true;
			x.add(m)
		});
		ie.load(a, function (e, t) {
			objExtra1Material = new THREE.MeshBasicMaterial({
				skinning: true,
				color: 16777215,
				map: o,
				alphaMap: n,
				lightMap: i,
				lightMapIntensity: 1,
				depthWrite: false,
				depthTest: false,
				transparent: true,
				opacity: 0
			});
			e.faceVertexUvs[1] = e.faceVertexUvs[0];
			objExtra1 = new THREE.SkinnedMesh(e, objExtra1Material);
			objExtra1.rotation.set(0, 0, 0);
			objExtra1.position.set(0, -3, 30);
			we();
			x.add(objExtra1)
		});
		trunkMaterial = new THREE.MeshBasicMaterial({
			color: 16777215,
			map: s,
			alphaMap: r,
			lightMap: i,
			lightMapIntensity: 1,
			depthWrite: false,
			depthTest: false,
			transparent: true,
			opacity: 0
		});
		objExtra2 = new THREE.Mesh(new THREE.PlaneGeometry(820, 820), trunkMaterial);
		objExtra2.rotation.set(0, 0, 0);
		objExtra2.position.set(-2, 0, 0);
		we();
		x.add(objExtra2);
		l.add(x)
	}

	function Ye() {
		var e = "/../public/obj/leftHand.json",
			t = "/../public/obj/textSoft.json",
			a = "/../public/obj/objSoft.json";
		var o = ne.load("/../public/obj/tex/colorMapLeftHand.jpg"),
			i = ne.load("/../public/obj/tex/transMapLeftHand.jpg"),
			n = ne.load("/../public/obj/tex/lightMapHand.jpg");
		ie.load(t, function (e, t) {
			objTextMaterial = new THREE.MeshBasicMaterial({
				skinning: true,
				color: 0,
				transparent: true,
				opacity: .85,
				transparent: true,
				opacity: 0
			});
			y = new THREE.SkinnedMesh(e, objTextMaterial);
			e.faceVertexUvs[1] = e.faceVertexUvs[0];
			y.rotation.set(0, 0, 0);
			y.position.set(0, -3, 55);
			we();
			x.add(y);
			y.skeleton.bones[0].rotation.x = 0;
			typeMovement = 10;
			var a = [y.skeleton.bones[1].position, y.skeleton.bones[2].position, y.skeleton.bones[3].position, y.skeleton.bones[4].position, y.skeleton.bones[5].position, y.skeleton.bones[6].position, y.skeleton.bones[7].position, y.skeleton.bones[8].position];
			Q.fromTo(y.position, h.aniTimeLoop * .25, {
				y: -3
			}, {
				y: 0,
				ease: Power1.easeOut
			}, "start -= 0").fromTo(y.material, h.aniTimeLoop * .1, {
				opacity: 0
			}, {
				opacity: 1,
				ease: Power1.easeOut,
				onComplete: function () {
					N.play()
				}
			}, "start -= 0").staggerTo(a, h.aniTimeLoop, {
				y: .18,
				ease: Power1.easeInOut,
				repeat: -1,
				yoyo: true
			}, .5, "loop -= 6")
		});
		ie.load(a, function (e, t) {
			t = new THREE.MeshStandardMaterial({
				color: 27900,
				envMap: je,
				envMapIntensity: 1,
				emissive: 27900,
				emissiveIntensity: .83,
				roughness: .54,
				metalness: 1,
				transparent: true,
				depthWrite: false,
				depthTest: false,
				opacity: 0,
				morphTargets: true
			});
			e.faceVertexUvs[1] = e.faceVertexUvs[0];
			m = new THREE.Mesh(e, t);
			m.rotation.set(0, 0, 0);
			m.position.set(0, 0, 0);
			we();
			w = new THREE.AnimationMixer(m);
			b = THREE.AnimationClip.CreateFromMorphTargetSequence("letterMorph", m.geometry.morphTargets, 1, true);
			var a = w.clipAction(b);
			a.loop = THREE.LoopOnce;
			a.setDuration(h.aniTime).play();
			a.paused = true;
			x.add(m)
		});
		var s = new THREE.Object3D;
		ie.load(e, function (e, t) {
			objLeftHandMaterial = new THREE.MeshBasicMaterial({
				skinning: true,
				color: 16777215,
				map: o,
				alphaMap: i,
				lightMap: n,
				lightMapIntensity: 1,
				depthWrite: false,
				depthTest: false,
				transparent: true,
				opacity: 0
			});
			objLeftHand = new THREE.SkinnedMesh(e, objLeftHandMaterial);
			e.faceVertexUvs[1] = e.faceVertexUvs[0];
			objLeftHand.rotation.set(0, 0, 0);
			objLeftHand.position.set(16, -2, 40);
			objLeftHand.scale.set(2.2, 2.2, 2.2);
			s.add(objLeftHand)
		});
		ie.load(e, function (e, t) {
			objRightHandMaterial = new THREE.MeshBasicMaterial({
				skinning: true,
				color: 16777215,
				map: o,
				alphaMap: i,
				lightMap: n,
				lightMapIntensity: 1,
				depthWrite: false,
				depthTest: false,
				transparent: true,
				opacity: 0
			});
			objRightHand = new THREE.SkinnedMesh(e, objRightHandMaterial);
			e.faceVertexUvs[1] = e.faceVertexUvs[0];
			objRightHand.rotation.set(0, 0, 0);
			objRightHand.position.set(-16, -2, 40);
			objRightHand.scale.set(-2.2, 2.2, 2.2);
			s.add(objRightHand)
		});
		x.add(s);
		we();
		l.add(x)
	}

	function We() {
		var e = "/../public/obj/objOut.json",
			t = "/../public/obj/textOut.json",
			a = "/../public/obj/suri.json";
		var o = ne.load("/../public/obj/tex/lightMapsuri.jpg"),
			i = ne.load("/../public/obj/tex/colorMapSuri.jpg"),
			n = ne.load("/../public/obj/tex/transMapSuri.jpg");
		ie.load(t, function (e, t) {
			objTextMaterial = new THREE.MeshBasicMaterial({
				skinning: true,
				color: 0,
				transparent: true,
				opacity: .85,
				opacity: 0
			});
			y = new THREE.SkinnedMesh(e, objTextMaterial);
			e.faceVertexUvs[1] = e.faceVertexUvs[0];
			y.rotation.set(0, 0, 0);
			y.position.set(0, 1, 0);
			we();
			l.add(y);
			y.skeleton.bones[0].rotation.x = 0;
			typeMovement = 10;
			var a = [y.skeleton.bones[1].position, y.skeleton.bones[2].position, y.skeleton.bones[3].position, y.skeleton.bones[4].position, y.skeleton.bones[5].position, y.skeleton.bones[6].position, y.skeleton.bones[7].position, y.skeleton.bones[8].position, y.skeleton.bones[9].position, y.skeleton.bones[10].position, y.skeleton.bones[11].position];
			Q.fromTo(y.position, h.aniTimeLoop * .25, {
				y: -3
			}, {
				y: 4.5,
				ease: Power1.easeOut
			}, "start -= 0").fromTo(y.material, h.aniTimeLoop * .1, {
				opacity: 0
			}, {
				opacity: 1,
				ease: Power1.easeOut,
				onComplete: function () {
					U.play();
					N.play()
				}
			}, "start -= 0").staggerTo(a, h.aniTimeLoop, {
				y: .18,
				ease: Power1.easeInOut,
				repeat: -1,
				yoyo: true
			}, .5, "loop -= 6")
		});
		ie.load(e, function (e, t) {
			t = new THREE.MeshStandardMaterial({
				color: 27900,
				envMap: je,
				envMapIntensity: 1,
				emissive: 27900,
				emissiveIntensity: .83,
				roughness: .54,
				metalness: 1,
				transparent: true,
				depthWrite: false,
				depthTest: false,
				opacity: 0,
				morphTargets: true
			});
			e.faceVertexUvs[1] = e.faceVertexUvs[0];
			m = new THREE.Mesh(e, t);
			m.rotation.set(0, 0, 0);
			m.position.set(0, 0, 0);
			we();
			w = new THREE.AnimationMixer(m);
			b = THREE.AnimationClip.CreateFromMorphTargetSequence("letterMorph", m.geometry.morphTargets, 1, true);
			var a = w.clipAction(b);
			a.loop = THREE.LoopOnce;
			a.setDuration(h.aniTime).play();
			a.paused = true;
			x.add(m)
		});
		ie.load(a, function (e, t) {
			objExtra1 = new THREE.MeshBasicMaterial({
				skinning: true,
				color: 16777215,
				map: i,
				alphaMap: n,
				lightMap: o,
				lightMapIntensity: 1,
				depthWrite: false,
				depthTest: false,
				transparent: true
			});
			objExtra1.opacity = 0;
			objExtra1 = new THREE.SkinnedMesh(e, objExtra1);
			e.faceVertexUvs[1] = e.faceVertexUvs[0];
			objExtra1.rotation.set(0, 0, 0);
			objExtra1.position.set(-6, 0, 37);
			we();
			x.add(objExtra1)
		});
		we();
		l.add(x)
	} */

	function Xe() {
		var e = 16777215,
			t = 1.3;
		r = new THREE.SpotLight(e, t);
		r.position.set(0, 80 * 5, 40 * 5);
		r.penumbra = 1;
		r.decay = 30;
		r.distance = 4e3;
		r.castShadow = true;
		r.shadow.mapSize.width = 1024;
		r.shadow.mapSize.height = 1024;
		r.shadow.camera.near = 10;
		r.shadow.camera.far = 200;
		r.lookAt(l.position);
		l.add(r);
		s = new THREE.HemisphereLight(e, 10077183);
		s.intensity = .75;
		l.add(s);
		n.render(l, i)
	}

	function qe() {
		var e = 16777215,
			t = 4;
		r = new THREE.SpotLight(e, t);
		r.position.set(20, 80 * 5, 50 * 5);
		r.penumbra = 1;
		r.decay = 100;
		r.distance = 4e3;
		r.castShadow = true;
		r.shadow.mapSize.width = 1024;
		r.shadow.mapSize.height = 1024;
		r.shadow.camera.near = 10;
		r.shadow.camera.far = 200;
		r.lookAt(l.position);
		l.add(r);
		spotLight_3 = new THREE.SpotLight(e, t);
		spotLight_3.position.set(-20, 20, 70 * 2);
		spotLight_3.penumbra = 1;
		spotLight_3.decay = 100;
		spotLight_3.distance = 4e3;
		spotLight_3.castShadow = false;
		spotLight_3.shadow.camera.near = 10;
		spotLight_3.shadow.camera.far = 200;
		spotLight_3.lookAt(l.position);
		l.add(spotLight_3);
		s = new THREE.HemisphereLight(e, 1245497);
		s.intensity = 1;
		l.add(s)
	}

	function Ne() {
		var e = 16777215,
			t = 3;
		r = new THREE.SpotLight(e, t);
		r.position.set(30, 40, 60 * 2);
		r.penumbra = 1;
		r.decay = 100;
		r.distance = 4e3;
		r.castShadow = false;
		r.shadow.camera.near = 10;
		r.shadow.camera.far = 200;
		r.lookAt(l.position);
		l.add(r);
		s = new THREE.HemisphereLight(e, 16215662);
		s.intensity = 1;
		l.add(s)
	}

	function Ue() {
		var e = 16777215,
			t = 2.8;
		r = new THREE.SpotLight(e, t);
		r.position.set(30, 40, 60 * 2);
		r.penumbra = 1;
		r.decay = 100;
		r.distance = 4e3;
		r.castShadow = false;
		r.shadow.camera.near = 10;
		r.shadow.camera.far = 200;
		r.lookAt(l.position);
		l.add(r);
		s = new THREE.HemisphereLight(e, 2082717);
		s.intensity = 3;
		l.add(s)
	}

	function Ze() {
		var e = 16777215,
			t = 4;
		r = new THREE.SpotLight(e, t);
		r.position.set(30, 40, 70 * 2);
		r.penumbra = 1;
		r.decay = 100;
		r.distance = 4e3;
		r.castShadow = false;
		r.shadow.camera.near = 10;
		r.shadow.camera.far = 200;
		r.lookAt(l.position);
		l.add(r);
		s = new THREE.HemisphereLight(e, 15135231);
		s.intensity = 1;
		l.add(s)
	}

	function Je() {
		var e = 16777215,
			t = 1.5;
		r = new THREE.SpotLight(e, t);
		r.position.set(35, 25, 100 * 2);
		r.penumbra = 1;
		r.decay = 100;
		r.distance = 4e3;
		r.castShadow = false;
		r.shadow.camera.near = 10;
		r.shadow.camera.far = 200;
		r.lookAt(l.position);
		l.add(r);
		s = new THREE.HemisphereLight(e, 15135231);
		s.intensity = .95;
		l.add(s)
	}

	function Qe() {
		var e = 16777215,
			t = 5;
		r = new THREE.SpotLight(e, t);
		r.position.set(60, 30, 90 * 2);
		r.penumbra = 1;
		r.decay = 100;
		r.distance = 4e3;
		r.castShadow = false;
		r.shadow.camera.near = 10;
		r.shadow.camera.far = 200;
		r.lookAt(l.position);
		l.add(r);
		s = new THREE.HemisphereLight(e, e);
		s.intensity = 1.2;
		l.add(s)
	}

	function Ke() {
		var e = 16777215,
			t = 6;
		r = new THREE.SpotLight(e, t);
		r.position.set(60, 30, 90 * 2);
		r.penumbra = 1;
		r.decay = 100;
		r.distance = 4e3;
		r.castShadow = false;
		r.shadow.camera.near = 10;
		r.shadow.camera.far = 200;
		r.lookAt(l.position);
		l.add(r);
		s = new THREE.HemisphereLight(e, e);
		s.intensity = 1.5;
		l.add(s)
	}

	function et() {
		A = window.innerHeight * 1.15;
		D = window.innerWidth;
		we();
		n.setSize(D, A);
		i.aspect = D / A;
		i.updateProjectionMatrix()
	}

	function tt(e) {
		a = e.clientX - L;
		o = I - e.clientY;
		S = (e.clientX - L) * (ce / 100);
		P = (e.clientY - I * .5) * (ce / 100)
	}

	function at() {
		TweenMax.to(i.position, 10, {
			x: S - i.position.x,
			y: -P - i.position.y,
			ease: Expo.easeOut
		});
		if ($(".webGL-main").hasClass("owleyes")) {
			TweenMax.to(objExtra1.skeleton.bones[11].position, .08, {
				x: xe(a, -.2585, -.2285, -L, L),
				y: xe(o, .145, .165, -I, I),
				ease: Power4.easeOut
			});
			TweenMax.to(objExtra1.skeleton.bones[12].position, .08, {
				x: xe(a, .2285, .2585, -L, L),
				y: xe(o, .145, .165, -I, I),
				ease: Power4.easeOut
			})
		}
	}
	var ot = function () {
		var e = w.clipAction(b);
		e.paused = false;
		X.pause();
		N.to(m.rotation, h.aniTime, {
			y: ye(95),
			ease: Power2.easeInOut
		}).to(m.rotation, h.aniTime * 1.15, {
			y: ye(85),
			ease: Power1.easeInOut
		}).to(m.rotation, h.aniTime * .9, {
			y: ye(90),
			ease: Power2.easeInOut,
			onComplete: function () {
				X.resume()
			}
		});
		N.play()
	};
	var it = function () {
		var e = w.clipAction(b);
		e.paused = false;
		X.pause();
		N.to(m.rotation, h.aniTime, {
			y: ye(120),
			ease: Power1.easeOut
		}).to(m.rotation, h.aniTime * .7, {
			y: ye(75),
			ease: Power1.easeOut
		}).to(m.rotation, h.aniTime * .9, {
			y: ye(90),
			ease: Power2.easeOut,
			onComplete: function () {
				X.resume()
			}
		});
		N.play()
	};
	var nt = function () {
		var e = w.clipAction(b);
		e.paused = false;
		TweenMax.to(m.material, h.aniTime, {
			envMapIntensity: .7
		});
		N.to(m.rotation, h.aniTime, {
			y: ye(120),
			ease: Power1.easeInOut
		}).to(m.rotation, h.aniTime * .7, {
			y: ye(75),
			ease: Power1.easeInOut
		}).to(m.rotation, h.aniTime * .9, {
			y: ye(90),
			ease: Power2.easeOInut,
			onComplete: function () {
				X.resume()
			}
		});
		N.play()
	};
	var st = function () {
		var e = w.clipAction(b);
		e.paused = false;
		X.pause();
		N.to(m.rotation, h.aniTime, {
			x: ye(10),
			y: ye(5),
			ease: Power1.easeInOut
		}).to(m.rotation, h.aniTime * .6, {
			x: ye(-10),
			y: ye(-5),
			ease: Power1.easeInOut
		}).to(m.rotation, h.aniTime * .7, {
			x: ye(0),
			y: ye(0),
			ease: Power2.easeInOut,
			onComplete: function () {
				X.resume()
			}
		});
		N.play()
	};
	var rt = function () {
		De();
		X.fromTo(objNew.position, h.aniTimeLoop, {
			x: -.7,
			y: 0
		}, {
			x: .7,
			y: 3,
			ease: Power1.easeInOut,
			repeat: -1,
			yoyo: true
		});
		TweenMax.fromTo(objNew.material, .4, {
			opacity: 0
		}, {
			opacity: .95,
			ease: Power1.easeInOut
		}, 0);
		TweenMax.fromTo(objNew.position, .4, {
			z: 0
		}, {
			z: 35,
			ease: Power1.easeInOut
		}, 0)
	};
	var lt = function () {
		var e = w.clipAction(b);
		e.paused = false;
		N.to(objNew.rotation, h.aniTime * .5, {
			x: ye(6),
			y: ye(5),
			ease: Power1.easeInOut
		}).to(objNew.rotation, h.aniTime * .5, {
			x: ye(-4),
			y: ye(-5),
			ease: Power1.easeInOut
		}).to(objNew.rotation, h.aniTime * .6, {
			x: ye(0),
			y: ye(0),
			ease: Power1.easeInOut
		});
		N.play();
		setTimeout(function () {
			e.paused = true;
			objNew.material.needsUpdate = true
		}, h.aniTime / 2 * 1e3)
	};
	var ct = function () {
		R += .002;
		var e = 5;
		objExtra1.skeleton.bones[0].rotation.x = 0;
		wingAngle = 10;
		var t = [objExtra1.skeleton.bones[1].rotation, objExtra1.skeleton.bones[2].rotation, objExtra1.skeleton.bones[3].rotation, objExtra1.skeleton.bones[4].rotation];
		var a = [objExtra1.skeleton.bones[5].rotation, objExtra1.skeleton.bones[6].rotation, objExtra1.skeleton.bones[7].rotation, objExtra1.skeleton.bones[8].rotation];
		var o = w.clipAction(b);
		X.fromTo(m.position, h.aniTimeLoop, {
			y: 2
		}, {
			y: 3,
			ease: Power1.easeInOut,
			repeat: -1,
			yoyo: true
		});
		N.fromTo(m.material, h.aniTimeFadeIn * .2, {
			opacity: 0
		}, {
			opacity: .95,
			ease: Power1.easeOut
		}, "nStart -= 0").fromTo(m.position, h.aniTimeFadeIn, {
			z: 0
		}, {
			z: 33,
			ease: Power1.easeOut,
			onComplete: function () {
				o.paused = false
			}
		}, "nStart -= 0").to(m.rotation, h.aniTime, {
			y: ye(95),
			ease: Power1.easeInOut
		}).to(m.rotation, h.aniTime * .8, {
			y: ye(85),
			ease: Power1.easeInOut
		}).to(m.rotation, h.aniTime * .9, {
			y: ye(90),
			ease: Power2.easeInOut,
			onComplete: function () {
				X.play()
			}
		}).to(objExtra2.material, h.aniTime * .9, {
			opacity: 1,
			ease: Power2.easeOut
		}, "owl-=2.5").to(objExtra1.material, h.aniTime * .9, {
			opacity: 1,
			ease: Power2.easeOut
		}, "owl-=2.5").to(objExtra1.position, h.aniTime * 1.5, {
			y: 0,
			z: 65,
			ease: Power2.easeOut
		}, "owl-=2.5").staggerFromTo(a, 1, {
			z: -ye(15),
			ease: Power1.easeOut
		}, {
			z: ye(15),
			ease: Power1.easeOut
		}, .2, "wing-=2.5").staggerFromTo(t, 1, {
			z: ye(15),
			ease: Power1.easeOut
		}, {
			z: -ye(15),
			ease: Power1.easeOut
		}, .2, "wing-=2.5").to(objExtra1.position, h.owlAniTime, {
			y: -1.5,
			ease: Power1.easeInOut,
			repeat: -1,
			yoyo: true
		}, "wingloop-=1.3").staggerTo(a, h.owlAniTime, {
			y: ye(5),
			z: ye(0),
			ease: Power2.easeInOut,
			repeat: -1,
			yoyo: true
		}, .2, "wingloop-=1.5").staggerTo(t, h.owlAniTime, {
			y: -ye(5),
			z: -ye(0),
			ease: Power2.easeInOut,
			repeat: -1,
			yoyo: true
		}, .2, "wingloop-=1.5").fromTo(objExtra1.skeleton.bones[9].rotation, h.owlAniTime, {
			x: -ye(20),
			z: ye(5)
		}, {
			x: -ye(35),
			z: -ye(5),
			ease: Power1.easeInOut,
			repeat: -1,
			yoyo: true
		}, .2, "wingloop-=1.5").fromTo(objExtra1.skeleton.bones[10].rotation, h.owlAniTime, {
			x: -ye(20),
			z: ye(5)
		}, {
			x: -ye(35),
			z: -ye(5),
			ease: Power1.easeInOut,
			repeat: -1,
			yoyo: true
		}, .2, "wingloop-=1.5");
		Q.play()
	};
	var ft = function () {
		R += .002;
		var e = 5;
		objLeftHand.skeleton.bones[0].rotation.x = 0;
		objRightHand.skeleton.bones[0].rotation.x = 0;
		wingAngle = 10;
		var t = [objLeftHand.skeleton.bones[4].rotation, objLeftHand.skeleton.bones[5].rotation];
		var a = [objLeftHand.skeleton.bones[6].rotation, objLeftHand.skeleton.bones[7].rotation, objLeftHand.skeleton.bones[8].rotation];
		var o = [objLeftHand.skeleton.bones[9].rotation, objLeftHand.skeleton.bones[10].rotation, objLeftHand.skeleton.bones[11].rotation];
		var i = [objLeftHand.skeleton.bones[12].rotation, objLeftHand.skeleton.bones[13].rotation, objLeftHand.skeleton.bones[14].rotation];
		var n = [objLeftHand.skeleton.bones[15].rotation, objLeftHand.skeleton.bones[16].rotation, objLeftHand.skeleton.bones[17].rotation];
		var s = [objLeftHand.skeleton.bones[1].rotation, objLeftHand.skeleton.bones[2].rotation, objLeftHand.skeleton.bones[3].rotation];
		var r = [objRightHand.skeleton.bones[4].rotation, objRightHand.skeleton.bones[5].rotation];
		var l = [objRightHand.skeleton.bones[6].rotation, objRightHand.skeleton.bones[7].rotation, objRightHand.skeleton.bones[8].rotation];
		var c = [objRightHand.skeleton.bones[9].rotation, objRightHand.skeleton.bones[10].rotation, objRightHand.skeleton.bones[11].rotation];
		var f = [objRightHand.skeleton.bones[12].rotation, objRightHand.skeleton.bones[13].rotation, objRightHand.skeleton.bones[14].rotation];
		var d = [objRightHand.skeleton.bones[15].rotation, objRightHand.skeleton.bones[16].rotation, objRightHand.skeleton.bones[17].rotation];
		var p = [objRightHand.skeleton.bones[1].rotation, objRightHand.skeleton.bones[2].rotation, objRightHand.skeleton.bones[3].rotation];
		var u = w.clipAction(b);
		X.fromTo(m.position, h.aniTimeLoop, {
			y: 2
		}, {
			y: 3,
			ease: Power1.easeInOut,
			repeat: -1,
			yoyo: true
		});
		N.fromTo(m.material, h.aniTimeFadeIn * .2, {
			opacity: 0
		}, {
			opacity: .95,
			ease: Power1.easeOut
		}, "nStart -= 0").fromTo(m.position, h.aniTimeFadeIn, {
			z: 0
		}, {
			z: 33,
			ease: Power1.easeOut,
			onComplete: function () {
				var e = w.clipAction(b);
				e.paused = false
			}
		}, "nStart -= 0").to(m.rotation, h.aniTime, {
			y: ye(95),
			ease: Power1.easeInOut
		}).to(m.rotation, h.aniTime * .8, {
			y: ye(85),
			ease: Power1.easeInOut
		}).to(m.rotation, h.aniTime * .9, {
			y: ye(90),
			ease: Power2.easeInOut,
			onComplete: function () {
				X.play()
			}
		}).to(objLeftHand.material, h.aniTime * .9, {
			opacity: 1,
			ease: Power2.easeOut
		}, "owl-=2.5").to(objRightHand.material, h.aniTime * .9, {
			opacity: 1,
			ease: Power2.easeOut
		}, "owl-=2.5").to(objLeftHand.position, h.aniTime * 1.5, {
			y: 0,
			x: 12,
			z: 45,
			ease: Power2.easeOut
		}, "owl-=2.5").to(objRightHand.position, h.aniTime * 1.5, {
			y: 0,
			x: -12,
			z: 45,
			ease: Power2.easeOut
		}, "owl-=2.5").to(objLeftHand.position, h.extra1Animation, {
			y: 2,
			ease: Power1.easeInOut,
			repeat: -1,
			yoyo: true
		}, "wingloop-=1.3").to(objRightHand.position, h.extra1Animation, {
			y: 2,
			ease: Power1.easeInOut,
			repeat: -1,
			yoyo: true
		}, "wingloop-=1.3").staggerTo(t, h.extra1Animation, {
			x: ye(2),
			z: -ye(2),
			ease: Power1.easeInOut,
			repeat: -1,
			yoyo: true
		}, .2, "wingloop-=1.5").staggerTo(r, h.extra1Animation, {
			x: ye(2),
			z: -ye(2),
			ease: Power1.easeInOut,
			repeat: -1,
			yoyo: true
		}, .2, "wingloop-=1.5").staggerTo(a, h.extra1Animation, {
			x: ye(6.5),
			z: -ye(3),
			ease: Power1.easeInOut,
			repeat: -1,
			yoyo: true
		}, .2, "wingloop-=1.5").staggerTo(l, h.extra1Animation, {
			x: ye(6.5),
			z: -ye(3),
			ease: Power1.easeInOut,
			repeat: -1,
			yoyo: true
		}, .2, "wingloop-=1.5").staggerTo(o, h.extra1Animation, {
			x: ye(6.5),
			z: -ye(4),
			ease: Power1.easeInOut,
			repeat: -1,
			yoyo: true
		}, .2, "wingloop-=1.5").staggerTo(c, h.extra1Animation, {
			x: ye(6.5),
			z: -ye(4),
			ease: Power1.easeInOut,
			repeat: -1,
			yoyo: true
		}, .2, "wingloop-=1.5").staggerTo(i, h.extra1Animation, {
			x: ye(6.5),
			z: -ye(4),
			ease: Power1.easeInOut,
			repeat: -1,
			yoyo: true
		}, .2, "wingloop-=1.5").staggerTo(f, h.extra1Animation, {
			x: ye(6.5),
			z: -ye(4),
			ease: Power1.easeInOut,
			repeat: -1,
			yoyo: true
		}, .2, "wingloop-=1.5").staggerTo(n, h.extra1Animation, {
			x: ye(6),
			z: -ye(3),
			ease: Power1.easeInOut,
			repeat: -1,
			yoyo: true
		}, .2, "wingloop-=1.5").staggerTo(d, h.extra1Animation, {
			x: ye(6),
			z: -ye(3),
			ease: Power1.easeInOut,
			repeat: -1,
			yoyo: true
		}, .2, "wingloop-=1.5").staggerTo(s, h.extra1Animation, {
			x: -ye(5),
			z: ye(2),
			ease: Power1.easeInOut,
			repeat: -1,
			yoyo: true
		}, .2, "wingloop-=1.5").staggerTo(p, h.extra1Animation, {
			x: -ye(5),
			z: ye(2),
			ease: Power1.easeInOut,
			repeat: -1,
			yoyo: true
		}, .2, "wingloop-=1.5");
		Q.play()
	};
	var dt = function () {
		R += .002;
		var e = 5;
		objExtra1.skeleton.bones[0].rotation.x = 0;
		wingAngle = 10;
		var a = [objExtra1.skeleton.bones[1].rotation, objExtra1.skeleton.bones[2].rotation, objExtra1.skeleton.bones[3].rotation];
		var o = [objExtra1.skeleton.bones[5].rotation];
		var t = [objExtra1.skeleton.bones[6].position];
		var i = [objExtra1.skeleton.bones[4].scale];
		var n = [objExtra1.skeleton.bones[7].rotation, objExtra1.skeleton.bones[8].rotation];
		var s = [objExtra1.skeleton.bones[10].rotation];

		function r() {
			var e = $e(-2.5, 2.5);
			var t = $e(2, 4);
			TweenMax.staggerTo(a, t, {
				z: ye(e),
				y: ye(e * 1.2),
				ease: Power4.easeInOut
			}, .01, function () {
				r()
			});
			TweenMax.to(o, t, {
				z: ye(e),
				y: ye(e * 1.2),
				ease: Power4.easeInOut
			});
			TweenMax.to(objExtra1.skeleton.bones[6].position, t, {
				x: e * .1,
				ease: Power4.easeInOut
			})
		}

		function l() {
			var e = $e(-2, 2);
			var t = $e(1, 2);
			TweenMax.staggerTo(n, t, {
				z: ye(e),
				y: ye(e * 1.2),
				ease: Power4.easeInOut
			}, .01, function () {
				l()
			});
			TweenMax.to(objExtra1.skeleton.bones[10].position, t, {
				x: e * .05,
				ease: Power4.easeInOut
			})
		}
		X.fromTo(m.position, h.aniTimeLoop, {
			y: 2
		}, {
			y: 3,
			ease: Power1.easeInOut,
			repeat: -1,
			yoyo: true
		});
		U.fromTo(m.material, h.aniTimeFadeIn * .2, {
			opacity: 0
		}, {
			opacity: .95,
			ease: Power1.easeOut
		}, "nStart -= 0").fromTo(m.position, h.aniTimeFadeIn, {
			z: 0
		}, {
			z: 33,
			ease: Power1.easeOut,
			onComplete: function () {
				var e = w.clipAction(b);
				e.paused = false
			}
		}, "nStart -= 0").to(m.rotation, h.aniTime, {
			y: ye(95),
			ease: Power1.easeOut
		}).to(m.rotation, h.aniTime * .8, {
			y: ye(85),
			ease: Power1.easeOut
		}).to(m.rotation, h.aniTime * .9, {
			y: ye(90),
			ease: Power2.easeOut,
			onComplete: function () {
				X.play()
			}
		}).to(objExtra1.position, h.aniTime * 1.1, {
			z: 40,
			x: 4,
			ease: Power2.easeOut
		}, "ani1-=2.5").to(objExtra1.material, h.aniTime * .8, {
			opacity: 1,
			ease: Power2.easeOut
		}, "ani1-=2.5");
		N.staggerFromTo(a, h.extra1Animation * 1.5, {
			z: ye(0),
			y: ye(0),
			ease: Power3.easeOut
		}, {
			z: ye(-.7),
			y: ye(-4),
			ease: Power3.easeOut
		}, .1, "start-=0", r).staggerFromTo(o, h.extra1Animation * 1.3, {
			z: 0,
			ease: Power3.easeOut
		}, {
			z: -ye(4.5),
			y: -ye(4.5),
			ease: Power3.easeOut
		}, .2, "start-=0").fromTo(t, h.extra1Animation * 1.3, {
			x: 0,
			ease: Power3.easeOut
		}, {
			x: -.3,
			ease: Power3.easeOut
		}, "start-=0").to(i, h.extra1Animation * .85, {
			x: 1.08,
			y: 1.08,
			z: 1.08,
			ease: Power2.easeInOut,
			repeat: -1,
			yoyo: true
		}, "start-=0").staggerFromTo(n, h.extra1Animation * 1.3, {
			z: ye(0),
			y: ye(0),
			ease: Power4.easeOut
		}, {
			z: ye(-.7),
			y: ye(-3),
			ease: Power4.easeOut
		}, .2, "start-=0", l).fromTo(s, h.extra1Animation * 1.3, {
			x: 0,
			ease: Power4.easeOut
		}, {
			x: -.3,
			ease: Power4.easeOut
		}, "start-=0");
		Q.play()
	};
	var pt = function () {
		TweenMax.ticker.removeEventListener("tick", be);
		X.pause();
		N.pause();
		Q.pause()
	};
	var ut = function () {
		TweenMax.ticker.addEventListener("tick", be);
		X.resume();
		N.resume();
		Q.resume()
	};
	var ht = function () {
		$(".webGL-main").removeClass("owleyes");
		X.stop();
		N.stop();
		K.stop();
		//n.context = null;
		//n.domElement = null;
		n = null;
		TweenMax.ticker.removeEventListener("tick", be);
		//while (l.children.length > 0) {
			//l.remove(l.children[0])
		//}
		//container.remove();
		$(window).off("resize.titlesAnimation")
	};
	return {
		init: Ie,
		//playHomeAnimation: ot,
		//playOutsourcingAnimation: dt,
		//playContactAnimation: it,
		//playJobsAnimation: nt,
		//playAboutAnimation: st,
		//playNewsLetterAnimation: lt,
		//playNearshoringAnimation: ct,
		//playSoftwareAnimation: ft,
		handleWindowResize: et,
		//startNewsletterTicker: rt,
		savePrintScreenOnClick: Pe,
		pause: pt,
		resume: ut,
		kill: ht
	}
}

function globalStickyHeader() {
	var e = null;
	if (_customScroll == null) {
		var t = function () {
			e = _rAF_loop(o)
		};
		$_window[0].addEventListener("scroll", t, {
			passive: true
		});
		var a = {
			offset: {
				y: -1
			}
		};
		a.offset.y = window.pageYOffset
	} else {
		_header_scroll_listener = function (e) {
			o(e)
		};
		_customScroll.addListener(_header_scroll_listener)
	}

	function o(e) {
		if (_customScroll == null) {
			_scrollY = window.pageYOffset;
			var e = {
				offset: {
					y: -1
				},
				limit: {
					y: _scrollLimit
				},
				direction: {
					y: _scrollDirection
				}
			};
			e.offset.y = window.pageYOffset
		}
		if (!$_html.hasClass("ie") && !$_html.hasClass("edge") && !$_body.hasClass("mobile")) {
			$_headerMain.css("top", e.offset.y)
		}
		if (e.offset.y <= 0) {
			$_headerMain.removeClass("js-drop");
			TweenMax.to($_headerMain, 1.5, {
				y: 0,
				force3D: true,
				ease: Expo.easeOut
			})
		}
		if (_scrollDirection == "down" && e.offset.y > 10) {
			TweenMax.to($_headerMain, 2, {
				y: -200,
				force3D: true,
				ease: Expo.easeOut
			});
			if (_scrollY > 100 && !$_headerMain.hasClass("js-drop")) {
				$_headerMain.addClass("js-drop")
			}
		} else {
			if (!$(".start-project").find(".project-form").hasClass("js-open-form")) {
				TweenMax.to($_headerMain, 1.5, {
					y: 0,
					force3D: true,
					ease: Expo.easeOut
				})
			}
			if (_scrollY < 100) {
				$_headerMain.removeClass("js-drop")
			}
		}
	}
}

function statisticsAnimation() {
	var e = $(".statistics");
	var t = {
		rootMargin: "0px",
		threshold: [0]
	};
	var a = function (e, t) {
		e.forEach(function (e) {
			if (e.isIntersecting || e.intersectionRatio > 0) {
				t.unobserve(e.target);
				e.target.odometer.update(e.target.initialValue)
			}
		})
	};
	var n = new IntersectionObserver(a, t);
	e.each(function () {
		var e = $(this),
			t = e.text(),
			a = "",
			o = null;
		if (e.length) {
			e[0].odometer = o;
			e[0].initialValue = t;
			for (var i = 0; i < t.length; i++) a += "8";
			e[0].odometer = new Odometer({
				el: e[0],
				value: a,
				format: "d",
				duration: 100
			});
			n.observe(e[0])
		}
	})
}

function randomSentenceTransition(alias) {
	var e = $(".bg-transition-new").find("h2"),
		t = JSON.parse($(".bg-transition-new").attr("data-phrases"));
	for(var i in t){
		if (t != null || t[i][alias]!==undefined) e.html(t[i][alias])
	}
	
	//if (t != null) e.html(t[a].phrase)
}

function hoverLinksMenu() {
	var e = $("#nav-main"),
		t = $("#nav-main li"),
		a = $("#nav-main li a");
	var o = function () {
		a.each(function () {
			var e = $(this);
			if (e.length <= 0) return;
			var t = e.text().split(" "),
				a = e;
			e.text("");
			for (var o = 0; o < t.length; o++) {
				var i = t[o];
				if (o + 1 == t.length) var n = $("<span class='char'>" + i + "</span>");
				else var n = $("<span class='char'>" + i + "&nbsp;</span>");
				n.css({
					"display": "inline-block",
					"font-family": "inherit",
					"font-size": "inherit",
					"line-height": "inherit",
					"letter-spacing": "inherit",
					"color": "inherit"
		});
		e.append(n)
	}
		})
	};
	var i = function (e, t) {
		var a = e.find(".char"),
			o = e.attr("data-color");
		
		var th_cols;
		if( $('body').hasClass('light') ){
			th_cols='#fff';
		}else{
			th_cols='#000';
		}

		if (t == "in") {
			TweenMax.staggerTo(a, 1, {
				color: o,
				ease: Expo.easeOut
			}, .05)
		} else {
			TweenMax.staggerTo(a, 1, {
				color: th_cols,
				ease: Expo.easeOut
			}, .05)
		}
	};
	o();
	t.hover(function () {
		if (!$(this).hasClass("active")) {
			i($(this), "in")
		}
	}, function () {
		if (!$(this).hasClass("active")) {
			i($(this), "out")
		}
	});
	return {
		init: o,
		hover: i
	}
}

function setupDomElements(e) {
	switch (e) {
		
		case "what-we-do":
			$(".humble-brands .block-wrapper").each(function () {
				var e = $(this);
				e.data("real-height-block", e.outerHeight());
				e.find(".gradient-block").data("real-height-gradient", e.find(".gradient-block").height());
				TweenMax.set(e.find(".gradient-block"), {
					height: "250px",
					overflow: "hidden"
				})
			});
			break;
	}
	if (!$_body.hasClass("mobile")) {
		TweenMax.set($_pageHeader.find("h2"), {
			opacity: 0
		});
		TweenMax.set($_pageHeader.find("h3"), {
			opacity: 0
		})
	}
}

function readProgress() {
	var i = $(".scroll-indicator-wrapper"),
		n = i.find(".scroll-indicator"),
		s = $(".full-page-article").eq(0),
		e = $(".nav-sidebar-item").eq(0).offset().top - $(".nav-sidebar").offset().top,
		t = $(".nav-sidebar-item").eq(0).height(),
		a = 0,
		r = 0,
		l = $(".full-page-article").eq(0).outerHeight(),
		o = null,
		c = false,
		f = 0;
	var d = function () {
		TweenMax.set(i, {
			y: e,
			height: t - 4,
			top: "2px"
		});
		TweenMax.set(n, {
			scaleY: 0
		});
		s = $(".full-page-article").eq(0);
		l = $(".full-page-article").eq(0).outerHeight();
		o = _rAF_loop(h);
		h()
	};
	var p = function (e, t, a) {
		c = true;
		var o = $(".nav-sidebar-item").eq(e).offset().top - $(".nav-sidebar").offset().top + a;
		s = $(".full-page-article").eq(e - t);
		l = $(".full-page-article").eq(e - t).outerHeight(), r = e;
		if (_scrollDirection == "down") {
			TweenMax.to(n, 1, {
				scaleY: 0,
				transformOrigin: "top center",
				ease: Expo.easeOut,
				onComplete: function () {
					c = false
				}
			});
			TweenMax.to(i, 1, {
				y: o,
				ease: Expo.easeOut
			})
		} else {
			TweenMax.to(n, 1, {
				scaleY: 1,
				transformOrigin: "bottom center",
				delay: 0,
				ease: Expo.easeOut,
				onComplete: function () {
					c = false
				}
			});
			TweenMax.to(i, 1, {
				y: o,
				ease: Expo.easeOut
			})
		}
	};
	var u = function () {
		window.cancelAnimationFrame(o);
		o = null
	};

	function h() {
		o = _rAF_loop(h);
		if (!c) {
			var e = s.offset().top + s.outerHeight() - _globalViewportH;
			if (e <= 0) e = 0;
			f = normalizedValue(e, l, 0);
			TweenMax.to(n, .2, {
				scaleY: 1 - f,
				transformOrigin: "top center",
				ease: Power2.easeOut
			})
		}
	}
	return {
		init: d,
		changeNew: p,
		kill: u
	}
}

function initForm(e) {
	var n = e,
		t = n.find("input[type='text'], textarea"),
		s = n.find(".file-input"),
		a = n.find("label"),
		i = n.find(".wrapper-input"),
		r = n.find("input.required, textarea.required"),
		l = 0;
	t.on("keyup", o);
	t.on("click", function () {
		var e = $(this);
		e.closest(i).addClass("is-focused")
	});
	t.on("keydown", function (e) {
		var t = e.keyCode || e.which,
			a = $(this);
		if (t == 9) {
			a.closest(".input-area").next(".input-area").find(i).addClass("is-focused");
			if (a.closest(".input-area").next(".input-area").index() == -1) {
				n.find(".mt-negative").find(i).addClass("is-focused")
			}
		}
	});
	s.on("click", function () {
		n.find('input[type="file"]').click()
	});
	n.find('input[type="file"]').change(function (e) {
		var t = n.find('input[type="file"]');
		var a = t[0].files[0];
		if (a)
			if (a.type.indexOf("pdf") !== -1) {
				if (a.size > 1e6) {
					n.find('input[type="file"]').addClass("erro");
					n.find('input[type="file"]').closest(".wrapper-input").addClass("erro").removeClass("with-file");
					s.find(".file-name").html(s.find(".file-name").attr("data-original"))
				} else {
					s.find(".file-name").html(a["name"]);
					s.removeClass("erro").addClass("with-file");
					s.closest(".wrapper-input").removeClass("erro")
				}
			} else {
				n.find('input[type="file"]').addClass("erro").removeClass("with-file");
				n.find('input[type="file"]').closest(".wrapper-input").addClass("erro");
				s.find(".file-name").html(s.find(".file-name").attr("data-original"))
			}
	});
	t.on("focus", function () {
		var e = $(this);
		e.closest(i).removeClass("is-focused")
	});
	t.on("blur", function () {
		var e = $(this);
		if (!$.trim(e.val()).length) {
			e.closest(i).removeClass("is-focused");
			e.addClass("no-value")
		}
		if (e.val() === 0 || e.val() == "") {
			TweenMax.staggerTo(n.find("label"), .5, {
				opacity: 1,
				ease: Expo.easeOut
			})
		}
		validateForm(n);
		if (e.hasClass("phone")) {
			var t = /^[+]?[0-9 ]{9,}$/,
				a = e.val();
			if (t.test(e.val()) && a.length >= 9) {
				e.removeClass("erro");
				e.closest(i).removeClass("erro")
			} else {
				e.addClass("erro");
				e.closest(i).addClass("erro")
			}
			if (e.val().length === 0) {
				e.removeClass("erro");
				e.closest(i).removeClass("erro")
			}
		}
		if (e.hasClass("url")) {
			var t = /^(ftp|http|https):\/\/[^ "]+$/,
				o = e.val();
			if (t.test(o)) {
				e.removeClass("erro");
				e.closest(i).removeClass("erro")
			} else {
				e.addClass("erro");
				e.closest(i).addClass("erro")
			}
		}
		if (e.hasClass("required")) {
			r.each(function () {
				var e = $(this);
				if (e.val().length > 0 && !e.hasClass("erro")) {
					l += 1;
					if (l >= r.length) {
						TweenMax.to(n.find("button"), .5, {
							opacity: 1,
							pointerEvents: "visible"
						})
					} else {
						n.find("button").attr("style", "")
					}
				} else {
					n.find("button").attr("style", "")
				}
			})
		}
		l = 0
	});
	$(".rgpd-wrapper").on("click", function () {
		if (!$("form button.elastic-btn").hasClass("active")) {
			$("form button.elastic-btn").addClass("active");
			$(".rgpd-wrapper").addClass("active");
			$("input.check-policy").val("checked");
			$("input.check-policy").trigger("blur");
		} else {
			$("form button.elastic-btn").removeClass("active");
			$(".rgpd-wrapper").removeClass("active");
			$("input.check-policy").val("");
			$("input.check-policy").trigger("blur");
		}
	});

	function o() {
		var e = $(this);
		if (e.val() != " ") {
			if (e.hasClass("no-value")) {
				e.removeClass("no-value")
			}
		}
	}
	n.on("submit", function (e) {
		var t = $(this);
		e.stopImmediatePropagation();
		e.preventDefault();
		e.stopPropagation();
		if (n.find("input").hasClass("erro")) return;
		var a = n.find('input[type="file"]');
		if (a.length > 0) {
			var o = a[0].files[0];
			if (o)
				if (o.size > 1e6 || o.type.indexOf("pdf") === -1) {
					n.find('input[type="file"]').addClass("erro").removeClass("with-file");
					n.find('input[type="file"]').closest(".wrapper-input").addClass("erro");
					s.find(".file-name").html(s.find(".file-name").attr("data-original"));
					s.wrap("<form>").closest("form").get(0).reset();
					s.unwrap();
					return
				}
		}
		if (validateForm(n) && !n.hasClass("sending")) {
			n.addClass("sending");
			breakTitleLetters(n.find(".elastic-btn .text"));
			TweenMax.staggerTo(n.find(".elastic-btn .text .char"), .5, {
				y: "-50px",
				ease: Expo.easeOut
			}, .05);
			TweenMax.to(n.find(".loader-wrapper"), .2, {
				opacity: 1,
				delay: 1
			});
			
			if ($("body").hasClass("contact")) {



				var data = $('.contact-form').serialize();
	            var object = {
	                'success': function (response){


	                if(response['status']=='ok'){
                        $.doTimeout(2e3, function () {
							n.find(".elastic-btn .text").text("Sent");
							breakTitleLetters(n.find(".elastic-btn .text"));
							n.find(".elastic-btn").css("pointer-events", "none");
							TweenMax.set(n.find(".elastic-btn .text .char"), {
								y: "-50px"
							})
						});
						$.doTimeout(4e3, function () {
							TweenMax.staggerTo(n.find(".elastic-btn .text .char"), 1, {
								y: "0px",
								opacity: 1,
								ease: Expo.easeOut
							}, -.05, function () {});
							TweenMax.to(n.find(".loader-wrapper"), .2, {
								opacity: 0
							})
						});
						
                    }else{
                       alert(response['status']);
                    }
	                  
	                },
	                'data' : data
	            };
	            $.ajax(object);
	             return false;
				

			}
			 

			if ($(".page-toload").hasClass("contactedd-page")) {
				var i = {
					mail_to: n.find("#mail-to").val(),
					_wp_http_referer: n.find("input[name='_wp_http_referer']").val(),
					affinity_nonce: n.find("input[name='affinity_nonce']").val(),
					language: n.find("#language").val(),
					contacto_name: n.find("#name").val(),
					contacto_email: n.find("#email").val(),
					contacto_phone: n.find("#phone").val(),
					contacto_message: n.find("#message").val()
				};
				submitJSON("saveContactoForm", i, function (e) {
					$.doTimeout(2e3, function () {
						n.find(".elastic-btn .text").text("Enviado");
						breakTitleLetters(n.find(".elastic-btn .text"));
						n.find(".elastic-btn").css("pointer-events", "none");
						TweenMax.set(n.find(".elastic-btn .text .char"), {
							y: "-50px"
						})
					});
					$.doTimeout(4e3, function () {
						TweenMax.staggerTo(n.find(".elastic-btn .text .char"), 1, {
							y: "0px",
							opacity: 1,
							ease: Expo.easeOut
						}, -.05, function () {});
						TweenMax.to(n.find(".loader-wrapper"), .2, {
							opacity: 0
						})
					})
				}, function (e) {})
			}
		} else {}
	})
}

function animateRollingLetters() {
	var e = $(".rolling-letters"),
		t = $(".slides"),
		a = 0,
		o = null,
		i = false;
	var n = function () {
		TweenMax.set(e.find(".js-letter-anime"), {
			opacity: 0
		});
		t.bind("contentchanged", function () {
			s(t)
		});
		s(t)
	};

	function s(e) {
		var t = e.find("li").length;
		e.find("li:nth-child(-n+3)").clone().appendTo(e);
		e.css("animation-duration", t * 2 + "s")
	}
	var r = function () {
		i = true;
		t.addClass("animate");
		TweenMax.staggerTo(e.find(".js-letter-anime"), 1, {
			opacity: 1
		}, .5)
	};
	var l = function () {
		i = false;
		t.removeClass("animate")
	};
	var c = function () {
		window.cancelAnimationFrame(o);
		o = null
	};

	function f() {
		o = _rAF_loop(f);
		if (i) {
			TweenMax.to(e, .2, {
				x: "-=10px"
			});
			if (!verge.inViewport(e)) {
				TweenMax.set(e, {
					x: _globalViewportW
				})
			}
		}
	}
	return {
		init: n,
		animate: r,
		pause: l,
		kill: c
	}
}

function animateMorphLetters() {
	var e = $(".morphing-letter");
	e.each(function () {
		var e = $(this),
			t = new TimelineMax({
				paused: true
			});
		e.data("normal", e.find("svg .main-letter").attr("d"));
		e.data("active", e.attr("data-morphPath"));
		t.to([e.find(".letter"), e.find(".mask")], 2, {
			morphSVG: e.data("active"),
			repeat: -1,
			yoyo: true,
			ease: Sine.easeOut
		});
		e.data("animation", t)
	})
}

function elasticButtonsInputs(e, t) {
	var a = $(".elastic-btn"),
		o = $(".elastic-input");
	if (e != null) {
		a = e
	}
	if (t != null) {
		o = t
	}
	a.each(function () {
		var e = $(this);
		e.hover(function () {
			var e = $(this),
				t = e.find(".morph-bg"),
				a = e.find(".morph-element");
			if (e.hasClass("js-btn-clicked")) return;
			TweenMax.to(t, 1, {
				morphSVG: t.attr("data-hover"),
				ease: Elastic.easeOut.config(1, .5)
			})
		}, function () {
			var e = $(this),
				t = e.find(".morph-bg"),
				a = e.find(".morph-element");
			if (e.hasClass("js-btn-clicked")) return;
			TweenMax.to(t, 1, {
				morphSVG: t.attr("data-original"),
				ease: Elastic.easeOut.config(1, .5)
			})
		})
	});
	o.each(function () {
		var e = $(this),
			t = e.find(".morph-bg"),
			a = e.find(".morph-element");
		e.find("input").on("focus", function () {
			TweenMax.to(t, 1, {
				morphSVG: t.attr("data-hover"),
				ease: Elastic.easeOut.config(1, .5)
			})
		}).on("blur", function () {
			TweenMax.to(t, 1, {
				morphSVG: t.attr("data-original"),
				ease: Elastic.easeOut.config(1, .5)
			})
		})
	})
}

function breakTitleWords(e) {
	if (e.length <= 0) return;
	var t = e.text().split(" "),
		a = e;
	e.text("");
	for (var o = 0; o < t.length; o++) {
		var i = t[o];
		if (o + 1 == t.length) var n = $("<span class='word'>" + i + "</span>");
		else var n = $("<span class='word'>" + i + "&nbsp;</span>");
		n.css({
			display: "inline-block",
			"font-family": "inherit",
			"font-size": "inherit",
			"line-height": "inherit",
			"letter-spacing": "inherit",
			color: "inherit"
		});
		e.append(n)
	}
}

function breakTitleLetters(e) {
	if (e.length <= 0) return;
	var t = e.text().split("<br>"),
		a = e;
	e.text("");
	for (var o = 0; o < t.length; o++) {
		var i = t[o];
		for (var n = 0; n < i.length; n++) {
			if (i[n] == " ") {
				var s = "spacer";
				i[n] = "&nbsp;"
			} else var s = "";
			var r = $("<span class='char " + s + "'>" + i[n] + "</span>");
			r.css({
				color: "inherit",
				"font-family": "inherit"
			});
			e.append(r)
		}
		e.append("<br>")
	}
}

function hashtagScroll() {
	$_pageContent.append("<div id='hashtag-scroll'><h2></h2></div>");
	var t = $("#hashtag-scroll"),
		a = $(".js-hashtag-scroll"),
		o = 0,
		i;
	$.doTimeout(1e3, function () {
		i = a.eq(0).offset().top
	});
	o = a.eq(a.length - 1).offset().top
	
	var e = function (e) {
		t.css({
			top: e + _globalViewportH / 2 - t.height() / 2 + "px"
		});
		if (verge.inViewport(a.eq(0), -(_globalViewportH / 2 - t.outerHeight()))) {
			TweenMax.to(t, 2, {
				opacity: 1
			})
		}
		if ($_body.hasClass("home")) {
			if (e < i - _globalViewportH / 2 + t.height() / 2) {
				t.css({
					top: i
				});
				TweenMax.to(t.find("h2"), .5, {
					opacity: 1
				})
			} else if (o - e - t.outerHeight() - t.outerHeight() / 2 <= 0) {
				t.css({
					top: o + t.height() / 2
				});
				TweenMax.to(t.find("h2"), .5, {
					opacity: 1
				})
			}
		} else {
			if (e < i - _globalViewportH / 2 + t.height() / 2) {
				t.css({
					top: i
				});
				TweenMax.to(t.find("h2"), .5, {
					opacity: 1
				})
			} else if (o - e - _globalViewportH / 2 + t.height() / 2 <= 0) {
				t.css({
					top: o
				});
				TweenMax.to(t.find("h2"), .5, {
					opacity: 1
				})
			}
		}
		a.each(function () {
			var e = $(this);
			if (verge.inViewport(e, -(_globalViewportH / 2 - t.outerHeight()))) {
				t.find("h2").html(e.closest("section").find(".js-write-text").text())
			}
		})
	};
	return {
		init: e
	}
}

function initViewportAnimations() {
	var t = $(".js-title-element"),
		a = $(".js-bigText-element"),
		o = $(".js-text-element"),
		e = $(".js-parallax-phrase"),
		i = $(".js-write-text"),
		n = $(".image-blocks .question-wrapper, .recruitment .question-wrapper"),
		s = $(".image-blocks .answer-wrapper-text, .recruitment .answer-wrapper-text"),
		r = null,
		l = null;
	var c = function () {
		var e = {
			rootMargin: "0px",
			threshold: [0, .75]
		};
		l = function (e, a) {
			e.forEach(function (e) {
				if (e.isIntersecting || e.intersectionRatio > 0) {
					a.unobserve(e.target);
					switch (e.target.type) {
						case "titleElement":
							var t = $(e.target);
							TweenMax.to(t, 1, {
								y: "0px",
								ease: Power4.easeOut
							});
							break;
						case "textElement":
							var t = $(e.target);
							TweenMax.to(t, 1, {
								x: "0px",
								opacity: 1,
								ease: Power4.easeOut
							});
							break;
						case "bigTextElement":
							var t = $(e.target);
							TweenMax.to(t, 1, {
								y: "0px",
								opacity: 1,
								ease: Power4.easeOut
							});
							break;
						case "imageBlocksQuestionElement":
							var t = $(e.target);
							TweenMax.staggerTo([t.find("h2"), t.find("h3")], .5, {
								y: "0px",
								opacity: 1,
								delay: .8
							}, .2);
							TweenMax.staggerTo(t, 1, {
								scaleX: 1,
								ease: Power4.easeOut
							});
							if (t.closest(".question-row").find(".image-wrapper")) {
								TweenMax.to(t.closest(".question-row").find(".image-wrapper"), 2, {
									opacity: 1,
									scale: 1,
									delay: 2,
									ease: Elastic.easeOut.config(1, .8)
								})
							}
							break;
						case "imageBlocksAnswerElement":
							var t = $(e.target);
							TweenMax.to([t.find("h3")], .5, {
								y: "0px",
								opacity: 1,
								delay: .8
							});
							TweenMax.to([t.find("h4")], .5, {
								y: "0px",
								opacity: .6,
								delay: 1.5
							});
							TweenMax.staggerTo(t, 1, {
								scaleX: 1,
								ease: Expo.easeOut
							});
							break;
						case "writeTextElement":
							var t = $(e.target);
							TweenMax.staggerTo(t.find(".char"), 0, {
								opacity: 1,
								delay: .5
							}, .05);
							break
					}
				}
			})
		};
		r = new IntersectionObserver(l, e);
		t.each(function (e) {
			var t = $(this);
			t[0].type = "titleElement";
			t.wrap("<div class='overflow-wrap'></div>");
			t.parent(".overflow-wrap").css({
				height: t.height() + 15,
				marginBottom: t.css("marginBottom")
			});
			TweenMax.set(t[0], {
				y: t.height()
			});
			r.observe(t[0])
		});
		o.each(function (e) {
			var t = $(this);
			t[0].type = "textElement";
			TweenMax.set(t[0], {
				x: "-40px",
				opacity: 0
			});
			r.observe(t[0])
		});
		i.each(function () {
			var e = $(this);
			e[0].type = "writeTextElement";
			TweenMax.set(e.find(".char"), {
				opacity: 0
			});
			r.observe(e[0])
		});
		a.each(function (e) {
			var t = $(this);
			t[0].type = "bigTextElement";
			TweenMax.set(t[0], {
				y: "100px",
				opacity: 0
			});
			r.observe(t[0])
		});
		n.each(function () {
			var e = $(this);
			e[0].type = "imageBlocksQuestionElement";
			TweenMax.set([e.find("h2"), e.find("h3")], {
				y: "40px",
				opacity: 0
			});
			TweenMax.set(e, {
				scaleX: .01
			});
			r.observe(e[0])
		});
		s.each(function () {
			var e = $(this);
			e[0].type = "imageBlocksAnswerElement";
			TweenMax.set([e.find("h3"), e.find("h4")], {
				y: "40px",
				opacity: 0
			});
			TweenMax.set(e, {
				scaleX: .01
			});
			r.observe(e[0])
		})
	};
	var f = function () {
		r = null;
		l = null
	};
	return {
		init: c,
		kill: f
	}
}

function initScrollAnimations() {
	var e = $(".js-parallax-phrase"),
		s = [],
		t = $(".js-parallax-image"),
		r = [];
	var a = function () {
		e.each(function () {
			var e = $(this),
				t = {};
			breakTitleWords(e.find("h2"));
			t.element = e;
			t.height = e.height();
			t.offsetY = e.offset().top;
			s.push(t)
		});
		t.each(function () {
			var e = $(this),
				t = {};
			t.element = e;
			t.height = e.height();
			t.offsetY = e.offset().top;
			t.friction = e.attr("data-friction");
			r.push(t)
		})
	};
	var o = function () {
		for (var e = 0, t = s.length; e < t; e++) {
			if (checkInView(s[e], .5)) {
				var a = s[e].element,
					o = s[e].offsetY - _scrollY + _globalViewportH * .5,
					i = (_globalViewportH - o) / _globalViewportH;
				a.find(".word").each(function (e) {
					var t = $(this);
					TweenMax.to(t, .25, {
						y: i * 100 * e
					})
				})
			}
		}
		for (var e = 0, t = r.length; e < t; e++) {
			if (checkInView(r[e], 0)) {
				var a = r[e].element,
					o = r[e].offsetY - _scrollY + _globalViewportH * .5,
					i = (_globalViewportH - o) / _globalViewportH,
					n = r[e].friction;
				if (!$_html.hasClass("ie") || !$_html.hasClass("edge")) {
					TweenMax.to(a, .25, {
						y: -i * 100 * n
					})
				}
			}
		}
	};
	var i = function () {};
	return {
		init: a,
		update: o,
		kill: i
	}
}

function displacementImage(t) {
	PIXI.utils.skipHello();
	var a = window.devicePixelRatio;
	a = 1;
	var o = new PIXI.autoDetectRenderer(_globalViewportW * 1.1 * a, _globalViewportH * 1.3 * a, {
		transparent: true,
		antialias: true,
		forceCanvas: true
	});
	var i = new PIXI.Container;
	var n = new PIXI.Sprite.fromImage(t.image.attr("src"));
	var s = new PIXI.Sprite.fromImage(t.displacementImage);
	var r = new PIXI.filters.DisplacementFilter(s);
	var l = new PIXI.ticker.Ticker;
	var e = null;
	var c = function () {
		var e = cover(3400, 2328, _globalViewportW * 1.1, _globalViewportH * 1.1, false);
		o.view.style.position = "absolute";
		o.view.style.top = "0px";
		o.view.style.left = "0px";
		o.view.style.width = _globalViewportW * 1.1 * a;
		o.view.style.height = _globalViewportH * 1.1 * a;
		n.anchor.set(0);
		n.width = e.width * a;
		n.height = e.height * a;
		n.x = e.left;
		n.y = e.top;
		t.container.append(o.view);
		i.addChild(n);
		i.interactive = true;
		s.texture.baseTexture.wrapMode = PIXI.WRAP_MODES.REPEAT;
		i.filters = [r];
		s.anchor.set(.5);
		s.x = o.width / 2;
		s.y = o.height / 2;
		s.scale.x = 20;
		s.scale.y = 20;
		r.autoFit = true;
		r.scale.x = r.scale.y = 0;
		i.addChild(s);
		TweenMax.to($(".page-header canvas"), 1, {
			opacity: 1,
			delay: 1
		});
		l.autoStart = true;
		n.interactive = true;
		$_window.on("resize.displacementImage", $.debounce(200, f));
		$_window.on("mousewheel.displacementImage", function (e) {
			if (_scrollY > _globalViewportH / 2) return false;
			var t = normalizedValue(_scrollY, _globalViewportH / 2, _globalViewportH / 4);
			s.x += _scrollY / 5;
			s.y += _scrollY / 5;
			r.scale.x = _scrollY / 20;
			r.scale.y = _scrollY / 20;
			TweenMax.to(n, .5, {
				alpha: 1 - t
			})
		});
		l.add(function (e) {
			o.render(i)
		})
	};

	function f() {
		o.view.style.top = "0px";
		o.view.style.left = "0px";
		o.view.style.width = $_window.width() * 1.1 * a;
		o.view.style.height = $_window.height() * 1.1 * a;
		o.resize($_window.width() * 1.1 * a, $_window.height() * 1.1 * a);
		var e = cover(3400, 2328, $_window.width() * 1.1, $_window.height() * 1.1, false);
		n.width = e.width * a;
		n.height = e.height * a;
		n.x = e.left;
		n.y = e.top;
		s.x = o.width / 2;
		s.y = o.height / 2
	}
	var d = function () {
		$_window.off("mousewheel.displacementImage");
		$_window.off("resize.displacementImage");
		if (s) s.destroy();
		if (n) n.destroy();
		if (o) o.destroy()
	};
	return {
		init: c,
		kill: d
	}
}

function headerParallax(e) {
	var t = e.find(".background-wrapper"),
		i = t.find(".header-image-wrapper .block-bg-cover");
	init = function () {
		if ($_body.hasClass("js-no-ajax")) var e = 0;
		else var e = .5;
		t.on("mousemove.headerParallax", function (e) {
			var t = $(this);
			var a = e.clientX - t[0].getBoundingClientRect().left - t.width() / 2;
			var o = e.clientY - t[0].getBoundingClientRect().top - t.height() / 2;
			TweenMax.to(i, 1, {
				x: a * .01,
				y: o * .01
			})
		})
	};
	kill = function () {};
	return {
		init: init,
		kill: kill
	}
}

function loveAttraction(e) {
	var t = e.target,
		i = e.factor;
	var a = function () {
		t.each(function () {
			var e = $(this);
			e.on("mousemove.loveAttraction", function (e) {
				var t = $(this);
				var a = e.clientX - t[0].getBoundingClientRect().left - t.width() / 2;
				var o = e.clientY - t[0].getBoundingClientRect().top - t.height() / 2;
				if (t.parent().hasClass("js-open")) {
					TweenMax.to(t.parent(), .8, {
						y: "0px",
						x: "0px",
						ease: Elastic.easeOut.config(1, .75)
					});
					if (t.hasClass("brand-item")) {
						TweenMax.to(t.find(".brand-logo"), .5, {
							y: "0px",
							x: "0px"
						})
					}
				} else {
					TweenMax.to(t.parent(), .5, {
						x: a * i,
						y: o * i
					});
					if (t.hasClass("brand-item")) {
						TweenMax.to(t.find(".brand-logo"), .5, {
							x: a * i * .5,
							y: o * i * .5
						})
					}
				}
			});
			e.on("mouseleave.loveAttraction", function () {
				var e = $(this);
				TweenMax.to(e.parent(), .8, {
					y: "0px",
					x: "0px",
					ease: Elastic.easeOut.config(1, .75)
				});
				if (e.hasClass("brand-item")) {
					TweenMax.to(e.find(".brand-logo"), .5, {
						y: "0px",
						x: "0px"
					})
				}
			})
		})
	};
	var o = function () {
		t.each(function () {
			$(this).off("mousemove.loveAttraction");
			$(this).off("mouseleave.loveAttraction")
		})
	};
	return {
		init: a,
		kill: o
	}
}

function textFireworks(i, e) {
	var n = i.element,
		s = i.animationType,
		r = i.delay;
	switch (s) {
		case "jellyType":
			var l = new TimelineMax({
					paused: true
				}),
				t = new SplitText(n, {
					type: "words,chars"
				}),
				c = t.chars;
			break;
		case "smoothEntrance":
			var l = new TimelineMax({
					paused: true
				}),
				t = new SplitText(n, {
					type: "words,chars"
				}),
				c = t.chars;
			break
	}
	var a = function (e) {
		switch (s) {
			case "jellyType":
				TweenMax.set(n, {
					opacity: 1
				});
				TweenMax.set(n, {
					perspective: 1e3
				});
				l.staggerFrom(c, .8, {
					opacity: 0,
					scale: 0,
					y: 10,
					rotationX: 90,
					transformOrigin: "0% 50% -50",
					ease: Back.easeOut.config(1)
				}, .015, "+=" + r);
				if (verge.inViewport(n) && !n.hasClass("js-animated")) {
					$_pageHeader.find("h2").addClass("js-animated");
					l.play()
				}
				break;
			case "smoothEntrance":
				if (i.setupArgs) {
					var t = i.setupArgs.yValue,
						a = i.setupArgs.animationTime
				} else {
					t = 20;
					a = .8
				}
				if (n.closest(".header-text").length > 0) var o = .8;
				else var o = 1;
				TweenMax.set(n, {
					opacity: o
				});
				l.staggerFrom(c, .8, {
					opacity: 0,
					y: t,
					ease: Back.easeOut.config(1)
				}, .015, "+=" + r, function () {
					if ($_body.hasClass("what-we-do") || $_body.hasClass("contact") || $_body.hasClass("queremos") || $_body.hasClass("home")) {
						if (typeof e == "function") e()
					}
				});
				if (verge.inViewport(n) && !n.hasClass("js-animated")) {
					$_pageHeader.find("h2").addClass("js-animated");
					l.play()
				}
				break
		}
	};
	var o = function () {
		l.play()
	};
	var f = function () {
		TweenMax.killTweensOf(l);
		l = null
	};
	return {
		init: a,
		animate: o,
		kill: f
	}
}

function sequenceDance(e) {
	var t = e,
		a = t.find(".break-line"),
		o = t.find(".columns");
	var i = function () {
		TweenMax.set(a, {
			scaleX: 0,
			transformOrigin: "left center"
		});
		TweenMax.set(o.find("svg"), {
			scale: 0
		});
		TweenMax.set([o.find("h3"), o.find("p")], {
			y: "40px",
			opacity: 0
		})
	};
	var n = function () {
		TweenMax.to(a, 3, {
			scaleX: 1,
			ease: Power4.easeOut
		});
		$.doTimeout(333, function () {
			TweenMax.staggerTo(o.find("svg"), 2, {
				scale: 1,
				ease: Elastic.easeOut.config(1, .7)
			}, .33);
			TweenMax.staggerTo(o.find("h3"), 2, {
				y: "0px",
				opacity: 1,
				ease: Elastic.easeOut.config(1, .5)
			}, .33);
			TweenMax.staggerTo(o.find("p"), 2, {
				y: "0px",
				opacity: 1,
				delay: .1,
				ease: Elastic.easeOut.config(1, .5)
			}, .33)
		})
	};
	var s = function () {};
	return {
		init: i,
		animate: n,
		kill: s
	}
}

function letterMorphParallax(e) {
	var t = e;
	var a = function () {
		t.data("normal", "M23.145,369.802  c19.022,255.764,173.271,293.386,379.272,293.386c206.003,0,302.946-143.638,302.946-320.822 c0-177.187-96.944-320.518-302.946-320.823C179.634,21.21,2.745,95.514,23.145,369.802z");
		t.data("active", t.find("path").attr("d"));
		t.find("path").attr("d", t.data("normal"));
		TweenMax.set(t, {
			opacity: 0,
			scale: 0
		});
		var e = $("<div class='js-obj-wrapper'></div>");
		e.css({
			position: "absolute",
			top: 0,
			left: 0,
			right: 0,
			width: "100%",
			height: "100%",
			"z-index": 1
		});
		t.wrap(e);
		t.on("mousemove.letterMorphParallax", function (e) {
			var t = $(this);
			var a = e.clientX - t[0].getBoundingClientRect().left - t.width() / 2;
			var o = e.clientY - t[0].getBoundingClientRect().top - t.height() / 2;
			TweenMax.to(t.parent(), .5, {
				x: a * .1,
				y: o * .1
			})
		});
		t.on("mouseleave.letterMorphParallax", function () {
			var e = $(this);
			TweenMax.to(e.parent(), .8, {
				y: "0px",
				x: "0px",
				ease: Elastic.easeOut.config(1, .75)
			})
		})
	};
	var o = function () {
		TweenMax.to(t, 2, {
			opacity: 1,
			scale: 1,
			ease: Elastic.easeOut.config(1, .75)
		});
		TweenMax.to(t.find("path"), 1, {
			morphSVG: t.data("active"),
			ease: Elastic.easeOut.config(1, .5)
		})
	};
	var i = function () {
		t.off("mousemove.letterMorphParallax");
		t.off("mouseleave.letterMorphParallax")
	};
	return {
		init: a,
		animateIn: o,
		kill: i
	}
}

function initMouseAnimations() {
	var e = function () {
		$_pageHeader.on("mousemove", function (e) {
			var t = $(this);
			var a = e.clientX - _globalViewportW / 2;
			var o = e.clientY - _globalViewportH / 2;
			TweenMax.to(t.find(".letter-wrapper"), .5, {
				x: a * .01,
				y: o * .01
			});
			TweenMax.to(t.find(".title h2"), .75, {
				x: a * .05,
				y: o * .05
			});
			TweenMax.to(t.find(".image-wrapper"), .75, {
				x: a * .02,
				y: o * .02
			})
		});
		$_pageHeader.on("mouseleave", function () {
			var e = $(this);
			TweenMax.to(e.find(".letter-wrapper"), .5, {
				x: 0,
				y: 0,
				ease: Expo.easeOut
			});
			TweenMax.to(e.find(".title h2"), .75, {
				x: 0,
				y: 0,
				ease: Expo.easeOut
			});
			TweenMax.to(e.find(".image-wrapper"), .75, {
				x: 0,
				y: 0,
				ease: Expo.easeOut
			})
		})
	};
	var t = function () {
		$_pageFooter.off("mousemove");
		$_pageHeader.off("mousemove")
	};
	return {
		init: e,
		kill: t
	}
}

function loadingAnimation() {
	var t = $(".loading-blob"),
		e = t.find("svg").clone();
	var a = function () {
		t.css("display", "block");
		TweenMax.set(t, {
			x: _mouseX,
			y: _mouseY
		});
		TweenMax.to(t, 1, {
			scale: 1,
			ease: Elastic.easeOut.config(1, .5)
		});
		TweenMax.to(t.find("svg path"), 1, {
			morphSVG: t.find("svg path").attr("data-morph"),
			ease: Power2.easeOut,
			repeat: -1,
			yoyo: true
		});
		$_window.on("mousemove.loadingAnimation", i)
	};
	var o = function () {
		TweenMax.to(t, 1, {
			scale: 0,
			ease: Elastic.easeOut.config(1, .5),
			onComplete: function () {
				t.css("display", "none");
				TweenMax.set(t, {
					clearProps: "all"
				});
				TweenMax.killTweensOf(t.find("svg path"));
				TweenMax.set(t.find("svg path"), {
					clearProps: "all"
				});
				t.find("svg").remove();
				t.append(e.clone());
				$_window.off("mousemove.loadingAnimation")
			}
		})
	};

	function i(e) {
		_mouseX = e.clientX;
		_mouseY = e.clientY;
		TweenMax.to(t, .5, {
			x: _mouseX + 20,
			y: _mouseY + 20,
			ease: Power2.easeOut
		})
	}
	return {
		init: a,
		kill: o
	}
}

function checkInView(e, t) {
	if (t = undefined) t = 0;
	if (e.offsetY >= _scrollY + _globalViewportH || e.offsetY + e.height <= _scrollY) return false;
	else return true
}
jQuery.fn.reverse = [].reverse;

function linear(e) {
	return e
}

function easeInQuad(e) {
	return e * e
}

function easeOutQuad(e) {
	return e * (2 - e)
}

function easeOutQuad(e) {
	return e < .5 ? 2 * e * e : -1 + (4 - 2 * e) * e
}

function easeInCubic(e) {
	return e * e * e
}

function easeOutCubic(e) {
	return --e * e * e + 1
}

function easeOutCubic(e) {
	return e < .5 ? 4 * e * e * e : (e - 1) * (2 * e - 2) * (2 * e - 2) + 1
}

function easeInQuart(e) {
	return e * e * e * e
}

function easeOutQuart(e) {
	return 1 - --e * e * e * e
}

function easeOutQuart(e) {
	return e < .5 ? 8 * e * e * e * e : 1 - 8 * --e * e * e * e
}

function easeInQuint(e) {
	return e * e * e * e * e
}

function easeOutQuint(e) {
	return 1 + --e * e * e * e * e
}

function easeOutQuint(e) {
	return e < .5 ? 16 * e * e * e * e * e : 1 + 16 * --e * e * e * e * e
}

function exponentialOut(e) {
	return e == 1 ? e : 1 - Math.pow(2, -10 * e)
}
norm = function (e, t, a) {
	return (e - t) / (a - t)
};
lerp = function (e, t, a) {
	return (a - t) * e + t
};
map = function (e, t, a, o, i) {
	return lerp(norm(e, t, a), o, i)
};
lerpEase = function (e, t, a) {
	return (1 - a) * e + a * t
};

function cover(e, t, a, o, i) {
	var n = {};
	if (i) {
		var s = Math.min(a / e, o / t);
		n.width = e * s;
		n.height = t * s
	} else {
		n.width = a;
		n.height = t * a / e;
		if (n.height < o) {
			n.width = e * o / t;
			n.height = o
		}
	}
	n.left = Math.round((a - n.width) / 2);
	n.top = Math.round((o - n.height) / 2);
	return n
}
$.fn.popupWindow = function (o) {
	return this.each(function () {
		$(this).click(function () {
			$.fn.popupWindow.defaultSettings = {
				centerBrowser: 0,
				centerScreen: 0,
				height: 500,
				left: 0,
				location: 0,
				menubar: 0,
				resizable: 0,
				scrollbars: 0,
				status: 0,
				width: 500,
				windowName: null,
				windowURL: null,
				top: 0,
				toolbar: 0
			};
			settings = $.extend({}, $.fn.popupWindow.defaultSettings, o || {});
			var e = "height=" + settings.height + ",width=" + settings.width + ",toolbar=" + settings.toolbar + ",scrollbars=" + settings.scrollbars + ",status=" + settings.status + ",resizable=" + settings.resizable + ",location=" + settings.location + ",menuBar=" + settings.menubar;
			settings.windowName = this.name || settings.windowName;
			settings.windowURL = this.href || settings.windowURL;
			var t, a;
			if (settings.centerBrowser) {
				if ($.browser.msie) {
					t = window.screenTop - 120 + ((document.documentElement.clientHeight + 120) / 2 - settings.height / 2);
					a = window.screenLeft + ((document.body.offsetWidth + 20) / 2 - settings.width / 2)
				} else {
					t = window.screenY + (window.outerHeight / 2 - settings.height / 2);
					a = window.screenX + (window.outerWidth / 2 - settings.width / 2)
				}
				window.open(settings.windowURL, settings.windowName, e + ",left=" + a + ",top=" + t).focus()
			} else if (settings.centerScreen) {
				t = (screen.height - settings.height) / 2;
				a = (screen.width - settings.width) / 2;
				window.open(settings.windowURL, settings.windowName, e + ",left=" + a + ",top=" + t).focus()
			} else {
				window.open(settings.windowURL, settings.windowName, e + ",left=" + settings.left + ",top=" + settings.top).focus()
			}
			return false
		})
	})
};

function callPlayer(a, e, t) {
	if (window.jQuery && a instanceof jQuery) a = a.get(0).id;
	var o = document.getElementById(a);
	if (o && o.tagName.toUpperCase() != "IFRAME") {
		o = o.getElementsByTagName("iframe")[0]
	}
	if (!callPlayer.queue) callPlayer.queue = {};
	var i = callPlayer.queue[a],
		n = document.readyState == "complete";
	if (n && !o) {
		window.console && console.log("callPlayer: Frame not found; id=" + a);
		if (i) clearInterval(i.poller)
	} else if (e === "listening") {
		if (o && o.contentWindow) {
			e = '{"event":"listening","id":' + JSON.stringify("" + a) + "}";
			o.contentWindow.postMessage(e, "*")
		}
	} else if (!n || o && (!o.contentWindow || i && !i.ready) || (!i || !i.ready) && typeof e === "function") {
		if (!i) i = callPlayer.queue[a] = [];
		i.push([e, t]);
		if (!("poller" in i)) {
			i.poller = setInterval(function () {
				callPlayer(a, "listening")
			}, 250);
			s(1, function e(t) {
				if (!o) {
					o = document.getElementById(a);
					if (!o) return;
					if (o.tagName.toUpperCase() != "IFRAME") {
						o = o.getElementsByTagName("iframe")[0];
						if (!o) return
					}
				}
				if (t.source === o.contentWindow) {
					clearInterval(i.poller);
					i.ready = true;
					s(0, e);
					while (tmp = i.shift()) {
						callPlayer(a, tmp[0], tmp[1])
					}
				}
			}, false)
		}
	} else if (o && o.contentWindow) {
		if (e.call) return e();
		o.contentWindow.postMessage(JSON.stringify({
			event: "command",
			func: e,
			args: t || [],
			id: a
		}), "*")
	}

	function s(e, t) {
		var a = e ? window.addEventListener : window.removeEventListener;
		a ? a("message", t, !1) : (e ? window.attachEvent : window.detachEvent)("onmessage", t)
	}
}

function normalizedValue(e, t, a) {
	return Number(((e - a) / (t - a)).toFixed(2))
}

function lockBodySafari() {
	var e = $("html, body"),
		t = $(".scroll-content-wrapper");
	if (window.pageYOffset) {
		_scrollTopMobileSafariFix = window.pageYOffset;
		t.css({
			top: -_scrollTopMobileSafariFix
		})
	}
	e.css({
		height: "100%",
		overflow: "hidden"
	})
}

function unlockBodySafari() {
	var e = $("html, body"),
		t = $(".scroll-content-wrapper");
	e.css({
		height: "",
		overflow: ""
	});
	t.css({
		top: ""
	});
	window.scrollTo(0, _scrollTopMobileSafariFix);
	window.setTimeout(function () {
		_scrollTopMobileSafariFix = null
	}, 0)
}

function disableScroll() {
	if (window.addEventListener) window.addEventListener("DOMMouseScroll", preventDefault, false);
	window.onwheel = preventDefault;
	window.onmousewheel = document.onmousewheel = preventDefault;
	window.ontouchmove = preventDefault;
	document.onkeydown = preventDefaultForScrollKeys
}

function enableScroll() {
	if (window.removeEventListener) window.removeEventListener("DOMMouseScroll", preventDefault, false);
	window.onmousewheel = document.onmousewheel = null;
	window.onwheel = null;
	window.ontouchmove = null;
	document.onkeydown = null
}

function getBrowserInfo() {
	var e = {
		isMobile: false,
		isTablet: false,
		isPhone: false,
		isDesktop: false,
		isPortrait: false,
		isLandscape: false,
		isSafari: false,
		isIE: false,
		isEdge: false,
		isChrome: false,
		isFirefox: false,
		isRetina: false,
		pixelRatio: 1,
		type: "",
		browser: ""
	};
	if ($("body").hasClass("mobile")) {
		e.isMobile = true;
		if ($("body").hasClass("phone")) {
			e.isPhone = true;
			e.type = "phone"
		} else {
			e.type = "tablet";
			e.isTablet = true
		}
		if ($("html").hasClass("firefox")) {
			e.browser = "firefox";
			e.isFirefox = true
		} else if ($("html").hasClass("chrome")) {
			e.browser = "chrome";
			e.isChrome = true
		} else if ($("html").hasClass("safari")) {
			e.browser = "safari";
			e.isSafari = true
		} else e.browser = "unknown"
	} else {
		e.type = "desktop";
		e.isDesktop = true;
		if ($("html").hasClass("firefox")) {
			e.browser = "firefox";
			e.isFirefox = true
		} else if ($("html").hasClass("chrome")) {
			e.browser = "chrome";
			e.isChrome = true
		} else if ($("html").hasClass("safari")) {
			e.browser = "safari";
			e.isSafari = true
		} else if ($("html").hasClass("ie")) {
			e.browser = "ie";
			e.isIE = true
		} else if ($("html").hasClass("edge")) {
			e.browser = "edge";
			e.isEdge = true
		} else e.browser = "unknown"
	}
	if ($("html").hasClass("orientation_landscape")) e.isLandscape = true;
	if ($("html").hasClass("orientation_portrait")) e.isPortrait = true;
	e.pixelRatio = window.devicePixelRatio;
	if (e.pixelRatio >= 2) e.isRetina = true;
	return e
}
$(document).ready(function () {
	outdatedBrowser({
		bgColor: "#f25648",
		color: "#fff",
		lowerThan: "boxShadow",
		languagePath: ""
	});
	window.fbAsyncInit = function () {
		FB.init({
			appId: "241114143086285",
			autoLogAppEvents: true,
			cookie: true,
			xfbml: true,
			version: "v2.10"
		});
		FB.AppEvents.logPageView()
	};
	(function (e, t, a) {
		var o, i = e.getElementsByTagName(t)[0];
		if (e.getElementById(a)) {
			return
		}
		o = e.createElement(t);
		o.id = a;
		o.src = "//connect.facebook.net/en_US/sdk.js";
		i.parentNode.insertBefore(o, i)
	})(document, "script", "facebook-jssdk");
	Modernizr.addTest("backgroundcliptext", function () {
		var e = document.createElement("div");
		e.style.cssText = Modernizr._prefixes.join("background-clip:text;");
		return !!e.style.cssText.replace(/\s/g, "").length
	});
	Modernizr.addTest("object-fit", !!Modernizr.prefixed("objectFit"));
	
});

function loadPages(s, e, t) {
	var a = $(".page-main.page-current"),
		r = $(".page-main.page-next");
	$("html,body").addClass("fixed-all");
	$_body.removeClass("js-no-ajax");
	$_body.addClass("js-loading-page");
	randomSentenceTransition(t);
	var l = Date.now();
	var o = $(".main-transition"),
		i = Math.floor(Math.random() * $(".bg-transition-new svg").not(".main-transition").length - 1) + 1;
	$randomSvg = $(".bg-transition-new svg:not(.main-transition)").eq(i), new_body_class = t, d = $randomSvg.find("path").attr("d"), data_morph = $randomSvg.find("path").attr("data-morph"), data_final_morph = $randomSvg.find("path").attr("data-final-morph");
	o.find("path").attr("d", d);
	o.find("path").attr("data-morph", data_morph);
	o.find("path").attr("data-final-morph", data_final_morph);
	o.attr("data-timing", $randomSvg.attr("data-timing"));
	$(".bg-transition-new").addClass(new_body_class);
	if (verge.inViewport($_pageFooter) && (new_body_class == "fazemos" || new_body_class == "who-we-are" || new_body_class == "noticias" || new_body_class == "home" || new_body_class == "single-service")) $(".bg-transition-new").addClass("alternative-color");
	$(".bg-transition-new").css("display", "block");
	var c = Number($(".bg-transition-new svg").attr("data-timing"));
	TweenMax.killAll();
	if ($_body.hasClass("mobile")) {
		TweenMax.to($(".loading-mobile .loading-bg"), 2, {
			x: "0%",
			ease: Expo.easeOut,
			onComplete: function () {
				$(".loading-mobile").remove()
			}
		})
	}
	TweenMax.to($(".bg-transition-new .main-transition path"), c, {
		morphSVG: $(".bg-transition-new .main-transition path").attr("data-morph"),
		ease: Expo.easeOut,
		onComplete: function () {}
	});
	TweenMax.fromTo($(".bg-transition-new h2"), 1, {
		y: "100px",
		opacity: 0
	}, {
		y: "0px",
		opacity: 1,
		delay: .5,
		ease: Power4.easeOut
	});
	r.load(s + " .page-toload", function (e, t, a) {
		var o = $(this);
		if (!o.html()) {
			window.location = s;
			return false
		}
		var i = Date.now();
		var n = c * 500 - (i - l);
		if (n < 0) n = 0;
		$.doTimeout(n, function () {
			_loadingBlob.kill();
			if (_currentView) {
				_currentView.kill();
				_currentView = null
			}
			$_headerMain.find(".desktop-language .other-languages").html($(e).find(".desktop-language .other-languages li"));
			$_headerMain.find("#nav-main-mobile .other-languages").html($(e).find("#nav-main-mobile .other-languages li"));
			$(".page-next .preload").imagesLoaded(function (e, t, a) {
				var o = $(this).imagesLoaded();
				o.always(function () {
					if (r.find(".page-toload").attr("data-bodyClass").split(" ")[0] == "single-service") var e = 1;
					else var e = .5 + 1;
					$.doTimeout(1e3 + e * 1e3, function () {
						$(".bg-transition-new").css("display", "none");
						$(".bg-transition-new").removeClass(new_body_class).removeClass("alternative-color").removeClass("what-we-do").removeClass("contact").removeClass("who-we-are").removeClass("single-queremos").removeClass("single-service").removeClass("home").removeClass("noticias").removeClass("single-noticias")
					});
					TweenMax.to($(".bg-transition-new .main-transition path"), c, {
						morphSVG: $(".bg-transition-new .main-transition path").attr("data-final-morph"),
						ease: Expo.easeOut,
						delay: e,
						onComplete: function () {
							TweenMax.set($(".bg-transition-new .main-transition path"), {
								morphSVG: $(".bg-transition-new .main-transition path").attr("data-original")
							})
						}
					});
					$_headerMain.css({
						top: "0px"
					});
					TweenMax.set($_headerMain, {
						y: "0px"
					});
					$_headerMain.removeClass("js-drop");
					manageBodyClasses();
					clearPagesAfterloading(0);
					$_pageHeader = $(".page-next .page-header");
					$_pageFooter = $(".page-next .page-footer");
					$_pageContent = $(".page-next .page-content");
					$_pageToLoad = $(".page-next .page-toload");
					$("#nav-main").find("li").removeClass("active");
					$("#nav-main").find("li." + $_pageToLoad.attr("data-bodyClass") + "").addClass("active");
					if ($_headerMain.hasClass("js-drop")) {
						$_headerMain.removeClass("js-drop")
					}
					TweenMax.to($_headerMain, 3, {
						y: 0,
						force3D: true,
						ease: Expo.easeOut
					});
					setupDomElements(r.find(".page-toload").attr("data-bodyClass").split(" ")[0]);
					TweenMax.to($(".bg-transition-new h2"), c / 4, {
						y: "-100px",
						opacity: 0,
						delay: e,
						ease: Power4.easeOut
					})
				})
			})
		})
	});
	return
}

function clearPagesAfterloading(e) {
	var t = $(".page-main.page-current"),
		a = $(".page-main.page-next");
	$.doTimeout(e, function () {
		t.remove();
		a.removeClass("page-next");
		a.addClass("page-current").removeAttr("aria-hidden");
		var e = $(".page-main.page-current");
		e.after('<div class="page-main page-next" aria-hidden="true"></div>');
		onStartPageWhenRefresh(false);
		e.attr("style", "");
		window.scrollTo(0, 0);
		$("html,body").scrollTop(0)
	})
}

function onStartPageWhenRefresh(e) {
	if (e) {
		
		window.scrollTo(0, 0); 
		$("html,body").scrollTop(0);
		$("html,body").addClass("fixed-all");
		$_loadingPage.addClass("js-loading-page");
		$_body.removeClass("js-byrefresh");
		manageBodyClasses();
		$_toPreload.imagesLoaded(function (e, t, a) {
			var o = $(this).imagesLoaded();
			o.always(function () {
				$("html,body").animate({
					scrollTop: 0
				}, 100);
				if ($_html.hasClass("no-object-fit")) {
					var e = $(".page-current .element-cover");
					resizeLikeCover(e)
				}
				var cl = $('.bg-transition-new').attr("data-alias");
				randomSentenceTransition(cl);
				setupDomElements($_pageToLoad.attr("data-bodyClass").split(" ")[0]);
				TweenMax.to($_loadingPage, .6, {
					opacity: 0,
					ease: Power2.easeInOut,
					onComplete: function () {
						$("html,body").removeClass("fixed-all");
						$_loadingPage.removeClass("js-loading-page").hide();
						onStartPage()
					}
				})
			})
		})
	} else {
		onStartPage()
	}
}

function onStartPage() {
	var e, t, a, o, i, n, s, r;
	_global_allowNavigate = true;
	$("html,body").removeClass("fixed-all");
	$_body.removeClass("js-loading-page");
	window.cancelAnimationFrame(_raf_loop_id);
	window.cancelAnimationFrame(_raf_main_id);
	if (_customScroll != null) {
		_customScroll.update();
		_customScroll.registerEvents(/scroll/, /wheel/, /touchstart/)
	}
	if ($_scrollContentWrapper.attr("data-scrollbar") != undefined && !$_scrollContentWrapper.hasClass("js-scroll")) {
		_customScroll = Scrollbar.init($_scrollContentWrapper[0], {
			speed: 1,
			syncCallbacks: true
		});
		$_scrollContentWrapper.addClass("js-scroll")
	}
	if (_customScroll != null) {
		$(".loading-container").css({
			top: 0
		});
		_customScroll.setPosition(0, 0);
		_customScroll.removeListener(_page_scroll_listener)
	}
	$_pageHeader = $(".page-current .page-header");
	$_pageFooter = $(".page-current .page-footer");
	$_pageContent = $(".page-current .page-content");
	$_pageToLoad = $(".page-current .page-toload");
	$("html,body").removeClass("fixed-all");
	$_body.removeClass("js-loading-page");
	callAsyncFunctions();
	if (_currentView) _currentView.kill();
	switch ($_pageToLoad.attr("data-bodyClass").split(" ")[0]) {
		case "home":
			_currentView = homePage();
			_currentView.init();
			break;
		case "what-we-do":
			_currentView = singleServicePage();
			_currentView.init();
			break;
		
		case "contact":
			_currentView = contactPage();
			_currentView.init();
			break;
		case "who-we-are":
			_currentView = singleServicePage();
			_currentView.init();
			break;
		case "our-philosophy":
			_currentView = what_we_doPage();
			_currentView.init();
		break;
		case "single-service":
			_currentView = singleServicePage();
			_currentView.init();
		break;
		case "noticias":
			_currentView = noticiasPage();
			_currentView.init();
			break;
		case "four04":
			_currentView = four04Page();
			_currentView.init();
			break
	}
	initGlobalAnimations();
	var l = window.location.hash;
	if (l != "") {
		var c = $("" + l + "");
		$.doTimeout(1e3, function () {
			if (_customScroll) _customScroll.scrollTo(0, $(l).offset().top, 2e3);
			else goTo(c, 1500, [.7, 0, .3, 1], 0)
		})
	}
	if ($_html.hasClass("no-object-fit")) {
		var f = $(".page-current .element-cover");
		resizeLikeCover(f)
	}
}

function startModal() {
	var o = $("#modal-bg"),
		i = $("#modal-wrapper"),
		n = $("#modal-content"),
		e = $(".btn-open-modal"),
		s = "",
		r = "";
	$(document).on("click", ".btn-open-modal", function (e) {});
	$(document).on("click", "#lightbox .btn-close-modal", function (e) {
		if ($_body.hasClass("js-no-ajax")) return;
		e.stopImmediatePropagation();
		e.preventDefault();
		e.stopPropagation();
		t(s, r)
	});
	$(document).on("contextmenu", ".btn-brand-guides", function (e) {
		e.preventDefault();
		e.stopPropagation();
		var t = $(this),
			a = " | Affinity";
		s = window.location.pathname;
		r = $("head title").text();
		var o = t.attr("data-url-brandAjax"),
			i = t.attr("data-url-brand"),
			n = t.attr("data-title-brand") + a;
		$("head title").html(n);
		if (_global_allowNavigate) {
			l(o, s, r)
		}
		return false
	});

	function l(a, e, t) {
		_global_allowNavigate = false;
		$_body.addClass("js-loading-page");
		i.addClass("js-on");
		_customScroll.unregisterEvents(/scroll/, /wheel/, /touchstart/);
		_customScrollModal = Scrollbar.init($("#modal-wrapper")[0], {
			syncCallbacks: true
		});
		$("#modal-wrapper").css({
			top: _customScroll.offset.y + "px"
		});
		_customScrollModal.addListener(function (e) {
			$(".btn-close-modal").css({
				top: e.offset.y + "px"
			})
		});
		TweenMax.set(n, {
			scale: .7,
			opacity: 0
		});
		TweenMax.set(o, {
			opacity: 0
		});
		n.load(a + " .toload", function () {
			var e = $(this);
			$_body.removeClass("js-no-ajax ");
			if (!e.html()) {
				window.location = a;
				return false
			}
			$("html,body").addClass("js-modal-open");
			$_body.removeClass("js-loading-page");
			ga("send", "pageview", window.location.pathname);
			var t = $("#modal-content .toload img:eq(0)").imagesLoaded();
			t.always(function (e) {
				TweenMax.to(n, .3, {
					scale: 1,
					opacity: 1,
					delay: .05,
					ease: Expo.easeOut
				});
				TweenMax.to(o, .8, {
					opacity: .8,
					ease: Expo.easeOut
				})
			});
			$("#lightbox").on("clickoutside", function (e) {
				e.preventDefault();
				e.stopPropagation();
				e.stopImmediatePropagation();
				$("#lightbox .btn-close-modal").click()
			});
			_global_allowNavigate = true
		})
	}

	function t(e, t) {
		history.replaceState({}, t, e);
		i.removeClass("js-on");
		$("#modal-wrapper").scrollTop(0);
		$("head title").html(t);
		$("html,body").removeClass("js-modal-open");
		$("#lightbox").remove();
		_customScroll.registerEvents(/scroll/, /wheel/, /touchstart/);
		_customScrollModal.removeListener();
		Scrollbar.destroy($("#modal-wrapper")[0]);
		return false
	}
}

function mainNavigation() {
	var e = $(".language-wrapper"),
		t = e.find(".active-language"),
		a = $(".hamb-menu"),
		o = $(".close-hamb-menu"),
		i = $(".outside-overlay"),
		n = e.find(".other-languages");
	$_headerMain.find(".main-logo a").on("click", function () {
		$_headerMain.find("#nav-main li").removeClass("active");
		$_headerMain.find("#nav-main-mobile li").removeClass("active")
	});
	$_headerMain.find("#nav-main li a").on("click", function () {
		var e = $(this);
		if (e.closest("li").hasClass("active") && (!$_body.hasClass("single-service") && !$_body.hasClass("single-queremos"))) return false
	});
	$_headerMain.find("#nav-main-mobile li a").on("click", function () {
		var e = $(this);
		$.doTimeout(800, function () {
			if (e.closest("li").hasClass("active") && (!$_body.hasClass("single-service") && !$_body.hasClass("single-queremos"))) return false;
			$_headerMain.find("#nav-main-mobile li").removeClass("active");
			e.closest("li").addClass("active")
		})
	});
	a.on("click", function () {
		$_body.addClass("js-nav-mobile-open");
		$("#header-main").css("overflow-x", "inherit");
		window.viewportUnitsBuggyfill.refresh();
		if (!$_html.hasClass("safari")) {
			$("body, html").css("overflow", "hidden")
		}
		disableScroll()
	});
	o.on("click", function () {
		$_body.removeClass("js-nav-mobile-open");
		$.doTimeout(800, function () {
			$("#header-main").css("overflow-x", "")
		});
		if (!$_html.hasClass("safari")) {
			$("body, html").css("overflow", "")
		}
		enableScroll()
	});
	i.on("click", function () {
		o.click()
	});
	if (!$_body.hasClass("mobile")) {
		$(document).on("mouseenter", ".language-wrapper", function () {
			$_body.addClass("js-language-open");
			if ($_headerMain.hasClass("js-drop")) {
				$_headerMain.addClass("increaseHeight")
			}
			TweenMax.fromTo(e.find(".arrow"), .25, {
				y: "0px"
			}, {
				y: "2px",
				onComplete: function () {
					TweenMax.fromTo(e.find(".arrow"), .25, {
						y: "2px"
					}, {
						y: "0px",
						clearProps: "all"
					})
				}
			});
			TweenMax.set(e, {
				overflow: "visible"
			});
			TweenMax.to(n, .5, {
				autoAlpha: 1
			})
		}).on("mouseleave", ".language-wrapper", function () {
			$_body.removeClass("js-language-open");
			if ($_headerMain.hasClass("js-drop")) {
				$_headerMain.removeClass("increaseHeight")
			}
			TweenMax.to(n, .25, {
				autoAlpha: 0,
				onComplete: function () {
					TweenMax.set(e, {
						clearProps: "all"
					})
				}
			})
		})
	} else {
		t.on("click", function () {
			$_body.addClass("js-language-open");
			TweenMax.fromTo(e.find(".arrow"), .25, {
				y: "0px"
			}, {
				y: "2px",
				onComplete: function () {
					TweenMax.fromTo(e.find(".arrow"), .25, {
						y: "2px"
					}, {
						y: "0px",
						clearProps: "all"
					})
				}
			});
			TweenMax.to(n, .5, {
				autoAlpha: 1
			})
		});
		$_pageHeader.on("click", function () {
			if ($_body.hasClass("js-language-open")) {
				$_body.removeClass("js-language-open");
				TweenMax.to(n, .25, {
					autoAlpha: 0,
					onComplete: function () {
						TweenMax.set(e, {
							clearProps: "all"
						})
					}
				})
			}
		})
	}
	globalStickyHeader()
}

function initGlobalAnimations() {
	if (!$_body.hasClass("mobile")) {
		if (_viewportAnimations) _viewportAnimations.kill();
		if (_scrollAnimations) _scrollAnimations.kill();
		if (_mouseAnimations) _mouseAnimations.kill();
		_viewportAnimations = initViewportAnimations();
		_viewportAnimations.init();
		_scrollAnimations = initScrollAnimations();
		_scrollAnimations.init();
		_mouseAnimations = initMouseAnimations();
		_mouseAnimations.init()
	}
	if (_customScroll == null) _scrollLimit = $_html.height();
	else _scrollLimit = _customScroll.limit.y;
	_raf_main_id = _rAF_loop(t);
	t();

	function t() {
		_raf_main_id = _rAF_loop(t);
		if (_customScroll == null) {
			_scrollY = window.pageYOffset;
			var e = {
				offset: {
					y: -1
				}
			};
			e.offset.y = window.pageYOffset
		} else {
			_scrollY = _customScroll.offset.y
		}
		if (_lastScroll != _scrollY && _scrollY != 0 && !_blockAll) {
			if (_lastScroll < _scrollY) _scrollDirection = "down";
			else _scrollDirection = "up";
			_lastScroll = _scrollY;
			if (!$_body.hasClass("mobile")) _scrollAnimations.update()
		}
	}
}
$(document).ready(function () {

	
	
	if ($(window).width() < 1024) $("body").addClass("mobile");
	$_window = $(window), $_body = $("body"), $_html = $("html"), $_mainPage = $(".page-main"), $_btn_nav_main = $(".btn-nav-main");
	$_scrollContentWrapper = $(".scroll-content-wrapper"), $_headerMain = $("#header-main");
	$_currentPage = $(".page-main.page-current"), $_toPreload = $(".preload"), $_loadingPage = $("#loading-page");
	$_pageHeader = $(".page-current .page-header");
	$_pageFooter = $(".page-current .page-footer");
	$_pageContent = $(".page-current .page-content");
	$_pageToLoad = $(".page-current .page-toload");
	_currentView = null;
	PIXI.utils.skipHello();
	calculateGlobalValues();
	_global_allowNavigate = false;
	_server_hostname = document.location.origin;
	_page_scroll_listener = null;
	_scrollTopMobileSafariFix = null;
	_customScroll = null, _customScrollBlockBrands = null, _lastScroll = -1;
	_scrollY = 0;
	_scrollLimit = 1;
	_scrollDirection = "down";
	_blockAll = false;
	_pageTransition = null;
	_viewportAnimations = null;
	_scrollAnimations = null;
	_mouseAnimations = null;
	_mouseX = 0;
	_mouseY = 0;
	_loadingBlob = loadingAnimation();
	_browserObj = getBrowserInfo();




	 var iex = msieversion();

	 if(iex!=='undefined' && iex ===true ){
	 	$_body.addClass('internet-explorer');
	 }


	if ($_body.hasClass("mobile") || $_html.hasClass("ie") || $_html.hasClass("edge")) {
		$(".scroll-content-wrapper").removeAttr("data-scrollbar")
	}
	if ($_scrollContentWrapper.attr("data-scrollbar") != undefined && !$_scrollContentWrapper.hasClass("js-scroll")) {
		$_body.css({
			overflow: "hidden"
		});
		$_html.css({
			overflow: "hidden"
		});
		_customScroll = Scrollbar.init($_scrollContentWrapper[0], {
			speed: 1,
			syncCallbacks: true
		});
		$_scrollContentWrapper.addClass("js-scroll")
	}
	_rAF_loop = window.requestAnimationFrame || window.webkitRequestAnimationFrame || window.mozRequestAnimationFrame || window.msRequestAnimationFrame || window.oRequestAnimationFrame || function (e) {
		window.setTimeout(e, 1e3 / 60)
	};
	_cancel_rAF_loop = window.cancelAnimationFrame || window.mozCancelAnimationFrame;
	_raf_loop_id = null;
	_raf_main_id = null;
	onStartPageWhenRefresh(true);
	mainNavigation();
	callAsyncFunctions();
	startModal();
	window.viewportUnitsBuggyfill.init();
	FastClick.attach(document.body);
	var e = window.location.host;
	var l = " | Humble Holdings",
		c = $("a").attr("data-title-home");
	_forPopstate = true;
	$_linkInternal = $('a[href*="' + e + '"]');
	$(document).on("click", 'a[data-remote="true"]', function () {
		var e = $(this),
			t = e.attr("data-title");
		if (!$_html.hasClass("edge") && !$_html.hasClass("ie") && !$_body.hasClass("mobile")) {
			_customScroll.unregisterEvents(/scroll/, /wheel/, /touchstart/)
		}
		if (!_global_allowNavigate) return false;
		_global_allowNavigate = false;
		if (e.hasClass("modal") || e.hasClass("js-no-transPage")) return;
		_loadingBlob.init();
		var a = e.attr("href"),
			o = e.attr("data-title"),
			i = a,
			n = i,
			s = o + l,
			seo = e.attr("data-seo"),
			r = window.location.search;
		if (!o) {
			s = c
		}
		seo = JSON.parse(seo);

	  $("head title").remove();
	  $('meta[property="og:title"]').remove();
	  $('meta[property="og:description"]').remove();
	  $('meta[property="og:image"]').remove();
	  $('meta[property="og:url"]').remove();
	  $('meta[name="title"]').remove();
	  $('meta[name="description"]').remove();
	  $('meta[name="keywords"]').remove();
	
	  $("head").append('<title>'+s+'</title>');
	  $("head").append('<meta property="og:title" content="'+s+'">');
	  $("head").append('<meta property="og:description" content="'+seo.share_description+'">');
	   $("head").append('<meta property="og:image" content="'+seo.og_image+'">');
	  $("head").append('<meta property="og:url" content="'+seo.url+'">');
	   $("head").append('<meta name="title" content="'+s+'">');
	   $("head").append('<meta name="description" content="'+seo.share_description+'">');
	   $("head").append('<meta name="keywords" content="'+seo.seo_keywords+'">');

		var theme = e.attr("data-theme");
	   if($('body').hasClass('dark') || $('body').hasClass('light')){
	      $('body').removeClass('dark');
	      $('body').removeClass('light');
	    }
       $('body').addClass(theme);


	   recaptchaOnload();


       $('#nav-main li a').removeClass('active');
    	e.addClass('active');

		if (_forPopstate) {
			history.pushState({}, s, n)
		}
		_forPopstate = true;
		
		if ($_body.hasClass("mobile")) {
			$_body.prepend("<span class='loading-mobile'><span class='loading-bg'></span></span>");
			TweenMax.to($(".loading-mobile .loading-bg"), 2, {
				x: "-30%",
				ease: Expo.easeInOut
			})
		}
		if ($_body.hasClass("js-nav-mobile-open")) {
			$(".close-hamb-menu").click();
			$.doTimeout(800, function () {
				loadPages(i, "default", e.attr("data-targetClass"))
			})
		} else {
			loadPages(i, "default", e.attr("data-targetClass"))
		}
		return false
	});
	if (window.addEventListener) {
		window.addEventListener("popstate", function (e) {
			if ($_html.hasClass("mobile")) return false;
			if (!e.state) {
				_forPopstate = true;
				return false
			}
			_forPopstate = false;
			window.location = window.location;
			return false
		})
	}
	$(document).on("click", "a[rel=external]", function (e) {
		e.stopImmediatePropagation();
		e.preventDefault();
		e.stopPropagation();
		var t = window.open($(this).attr("href"));
		return t.closed
	});
	$("a[rel=print]").click(function () {
		var e = window.print();
		return false
	});
	$("a.email").each(function () {
		var e = $(this).text().replace("[-at-]", "@");
		$(this).text(e);
		$(this).attr("href", "mailto:" + e)
	});
	$("input[type=text], input[type=email], input.text, input.email, textarea").each(function () {
		if (!$(this).hasClass("txtClear")) return;
		var e = this.defaultValue;
		$(this).focus(function () {
			if ($(this).val() == e) {
				$(this).val("")
			}
		});
		$(this).blur(function () {
			if ($(this).val() == "") {
				$(this).val(e)
			}
		})
	});
	$(document).on("click", ".newWindow", function () {
		event.stopImmediatePropagation();
		event.preventDefault();
		event.stopPropagation();
		var e = window.open($(this).attr("href"), "", "height=480,width=560");
		if (window.focus) {
			e.focus()
		}
		return false
	});
	$(document).on("keydown", function (e) {
		switch (e.which) {
			case 40:
				break;
			case 38:
				break;
			case 39:
				break;
			case 37:
				break;
			case 27:
				if ($_body.hasClass("js-modal-open")) $("#lightbox .btn-close-modal").click();
			default:
				break
		}
	});
	$_window.on("resize", $.debounce(500, function () {
		calculateGlobalValues();
		_browserObj = getBrowserInfo();
		$(".js-title-element").each(function (e) {
			var t = $(this);
			t.parent(".overflow-wrap").css({
				height: t.height() + 15,
				marginBottom: t.css("marginBottom")
			})
		});
		if ($(window).width() < 1024) {
			if (!$("body").hasClass("mobile") && !$("body").hasClass("tablet") && !$("body").hasClass("phone")) {
				$("body").addClass("mobile");
				location.reload()
			}
		} else {
			if ($("body").hasClass("mobile") && !$("body").hasClass("tablet") && !$("body").hasClass("phone")) {
				$("body").removeClass("mobile");
				location.reload()
			}
		}
		if ($("body").hasClass("fazemos") && !$("body").hasClass("mobile")) location.reload();
		if ($_html.hasClass("no-object-fit")) {
			var e = $(".page-current .element-cover");
			resizeLikeCover(e)
		}
	}));
	$_window.on("mousemove", $.debounce(50, function (e) {
		_mouseX = e.clientX;
		_mouseY = e.clientY
	}));
	window.addEventListener("orientationchange", function () {
		$.doTimeout(500, function () {
			_browserObj = getBrowserInfo()
		})
	})
});

function submitJSON(e, t, a, o) {
	$.ajax({
		data: {
			action: e,
			value: t
		},
		type: "post",
		dataType: "json",
		url: _server_hostname + "/wp-admin/admin-ajax.php",
		success: function (e) {
			a(e)
		},
		error: function (e) {
			o(e)
		}
	})
}

function submitForm(t) {
	if (validateForm(t) && !t.hasClass("sending")) {
		t.addClass("sending");
		$.ajax({
			data: t.serialize(),
			type: "post",
			dataType: "json",
			url: _server_hostname + "/wp-admin/admin-ajax.php",
			success: function (e) {
				t.removeClass("sending")
			},
			error: function (e) {
				t.removeClass("sending")
			}
		})
	}
}

function submitFormData(e, t, a) {
	$.ajax({
		data: e,
		type: "post",
		contentType: false,
		processData: false,
		url: _server_hostname + "/wp-admin/admin-ajax.php",
		success: function (e) {
			t(e)
		},
		error: function (e) {
			a(e)
		}
	})
}

function calculateGlobalValues() {
	_globalViewportW = verge.viewportW();
	_globalViewportH = verge.viewportH();
	_globalHalfViewportH = (_globalViewportH / 2).toFixed(0)
}

function callAsyncFunctions() {
	if (window.respimage) respimage()
}

function getRandomInt(e, t) {
	return Math.floor(Math.random() * (t - e)) + e
}

function manageBodyClasses() {
	if ($_body.hasClass("js-no-ajax")) {
		$_body.addClass($(".page-main.page-current .page-toload").attr("data-bodyClass"))
	} else {
		$_body.removeClass($(".page-main.page-current .page-toload").attr("data-bodyClass"));
		$_body.addClass($(".page-main.page-next .page-toload").attr("data-bodyClass"))
	}
}

function contactPage(e) {
	if ($_body.hasClass("js-no-ajax")) {
		var t = 0,
			a = 1
	} else {
		var t = .5,
			a = 1.5
	}
	var o = animateRollingLetters(),
		i ,
		n ,
		/*s = textFireworks({
			element: $(".page-footer h2"),
			animationType: "smoothEntrance",
			delay: 0,
			setupArgs: {
				yValue: 100,
				animationTime: .4
			}
		}),
		r = textFireworks({
			element: $(".page-footer h3"),
			animationType: "smoothEntrance",
			delay: .3,
			setupArgs: {
				yValue: 40,
				animationTime: .8
			}
		}),*/
		l = headerParallax($(".page-header")),
		c = new GlAnimation({
			title: "contact",
			desktopMaxWidth: 1440,
			objsGroup_ScaleDesktop: 1,
			objLetter_ScaleDesktop: .9,
			objGroupX_desktop: -10.5,
			objGroupY_desktop: .5,
			objGroupZ_desktop: 0,
			desktopMaxMaxWidth: 1800,
			objsGroup_ScaleMaxDesktop: 1,
			objLetter_ScaleMaxDesktop: .9,
			objGroupX_MaxDesktop: -4.5,
			objGroupY_MaxDesktop: .5,
			objGroupZ_MaxDesktop: 0,
			objWidthBigDesktop: 1,
			objLetter_ScaleBigDesktop: .9,
			objGroupX_bigDesktop: -8.5,
			objGroupY_bigDesktop: .5,
			objGroupZ_bigDesktop: 0,
			aniTime: 1.15,
			aniTimeLoop: 5.5,
			aniTimeFadeIn: 1,
			mouseInfluence: .65
		});


	if($(".page-header h2").length>0){
		i = textFireworks({
			element: $(".page-header h2"),
			animationType: "jellyType",
			delay: t
		});
	}
	if($(".page-header h3").length>0){
		n = textFireworks({
			element: $(".page-header h3"),
			animationType: "smoothEntrance",
			delay: a
		});
	}
	var f = $(".rotate-title h2"),
		//d = $(".map-wrapper .map-svg"),
		p = $(".morphing-letter"),
		//u = $(".page-footer"),
		//h = $(".map-wrapper"),
		m = $(".svg-scale");
	var g = function () {
		if (_customScroll == null) {
			_scrollRef = function () {
				_raf_loop_id = _rAF_loop(y)
			};
			$_window.on("scroll.contactPage", _scrollRef)
		} else {
			_page_scroll_listener = function (e) {
				y(e)
			};
			_customScroll.addListener(_page_scroll_listener)
		}
		$_body.detach(".rotate-title-fixed");
		f.each(function () {
			var e = $(this);
			e.css("height", e.closest(".container").outerHeight() + "px")
		});
		//_();
		x();
		elasticButtonsInputs();
		initForm($(".contact-form"));
		b();
		//v();
		//statisticsAnimation();
		if (!_browserObj.isMobile) {
			//s.init();
			l.init();
			c.init(function () {
				$.doTimeout(1e3, function () {
					if($(".page-header h2").length>0){
						i.init();
					}
					if($(".page-header h3").length>0){
						n.init();
					}
					
				})
			});
			o.init();
			if (!$_html.hasClass("safari")) {
				animateMorphLetters()
			}
			TweenMax.to($_body.find(".main-logo"), .5, {
				opacity: 1
			});
			TweenMax.to($_body.find(".language-wrapper"), .5, {
				opacity: 1
			});
			TweenMax.staggerTo($_pageHeader.find("h2 .char"), 1.8, {
				y: "0px",
				opacity: 1,
				ease: Power2.easeInOut,
				delay: .5
			}, .05, function () {
				TweenMax.staggerTo($_pageHeader.find("h2 .char"), 3, {
					y: "5px",
					yoyo: true,
					repeat: -1,
					ease: Sine.easeInOut
				}, .25)
			});
			hoverLinksMenu();
			/*TweenMax.set(h, {
				opacity: 0
			})*/
		}
	};
	var w = function () {
		if (!$_body.hasClass("mobile")) {
			if (l) l.kill();
			if (o) o.kill();
			if (i) i.kill();
			//n.kill();
			//s.kill();
			//r.kill();
			if (c) c.kill()
		}
		_scrollRef = null;
		cancelAnimationFrame(_raf_main_id);
		if (_customScroll) {
			_customScroll.removeListener(_page_scroll_listener)
		} else {
			$_window.off("scroll.contactPage");
			$_window[0].cancelAnimationFrame(_raf_loop_id)
		}
	};

	function b() {
		if (!$_body.hasClass("mobile")) {
			var e = tippy(".js-tooltip", {
				arrow: true,
				trigger: "mouseenter",
				theme: "map-tooltip"
			})
		} else {
			var e = tippy(".js-tooltip", {
				arrow: true,
				trigger: "click",
				theme: "map-tooltip"
			});
			return
		}
	}

	function v() {
		var e = JSON.parse(d.attr("data-activecountries"));
		for (var t = 0, a = e.length; t < a; t++) {
			d.find("#" + e[t]).show()
		}
	}

	function x() {
		var e = $(".team-person-container");
		e.on("mousemove", function (e) {
			var t = $(this).find(".name-letter");
			var a = e.clientX - t.parent()[0].getBoundingClientRect().left - t.width() / 2;
			var o = e.clientY - t.parent()[0].getBoundingClientRect().top - t.height() / 2;
			TweenMax.to(t, .5, {
				x: a * .1,
				y: o * .1
			})
		});
		e.on("mouseleave", function () {
			var e = $(this).find(".name-letter");
			TweenMax.to(e, .5, {
				y: "0px",
				x: "0px",
				ease: Elastic.easeOut.config(1, .75)
			})
		})
	}
	

	function y(e) {
		if (!_browserObj.isMobile) {
			if (_scrollY > 10 && !$_pageHeader.hasClass("js-paused")) {
				$_pageHeader.addClass("js-paused");
				c.pause()
			}
			if (_scrollY < _globalViewportH && $_pageHeader.hasClass("js-paused")) {
				$_pageHeader.removeClass("js-paused");
				c.resume()
			}
		}
		var ie_check = msieversion();
		if (!_browserObj.isMobile || ie_check===false) {
			if (!_browserObj.isSafari) {
				p.each(function () {
					var e = $(this);
					if (verge.inViewport(e) && !e.hasClass("js-animated")) {
						e.addClass("js-animated");
						//e.data("animation").play()
					} else if (!verge.inViewport(e) && e.hasClass("js-animated")) {
						e.removeClass("js-animated");
						e.data("animation").stop()
					}
				})
			}
		}
		if (!_browserObj.isPhone) {
			/*if (verge.inViewport($_pageFooter, -_globalViewportH / 3) && !$_pageFooter.hasClass("js-animated-2")) {
				//$_pageFooter.addClass("js-animated-2");
			//	$_pageFooter.addClass("add-bg-color");
				$_pageFooter.prev("section").addClass("add-bg-color");
				$.doTimeout(250, function () {
					s.animate();
					r.animate();
					$.doTimeout(250, function () {
						if (!$_body.hasClass("mobile")) {
							o.animate()
						}
					})
				})
			} else if (!verge.inViewport($_pageFooter, -_globalViewportH / 3) && $_pageFooter.hasClass("js-animated-2")) {
				$_pageFooter.removeClass("js-animated-2");
				$_pageFooter.removeClass("add-bg-color");
				$_pageFooter.prev("section").removeClass("add-bg-color");
				o.pause()
			}
			if (verge.inViewport(h, -150) && !h.hasClass("js-animated") && !$_body.hasClass("mobile")) {
				m.find("path").each(function (e) {
					var t = $(this);
					TweenMax.from(t, 1, {
						scale: 0,
						delay: e * .03,
						transformOrigin: "center center",
						ease: Elastic.easeOut.config(1, 1.2)
					})
				});
				h.addClass("js-animated");
				TweenMax.to(h, 0, {
					opacity: 1,
					ease: Expo.easeOut,
					onComplete: function () {}
				});
				TweenMax.staggerFrom(".point", .1, {
					opacity: 0,
					delay: .2,
					ease: Elastic.easeOut.config(1, .5)
				}, .1)
			}*/
		}
	}
	return {
		init: g,
		kill: w
	}
}

function four04Page() {
	var e = $(".page-header .background-wrapper"),
		i = $(".page-header").find(".header-pictogram"),
		n = $(".page-header").find(".header-image-wrapper .block-bg-cover"),
		t = textFireworks({
			element: $(".page-header h3"),
			animationType: "smoothEntrance",
			delay: .5
		}),
		a = textFireworks({
			element: $(".page-header a"),
			animationType: "smoothEntrance",
			delay: .75
		}),
		o = null;
	var s = function () {
		if (!$_body.hasClass("mobile")) {
			o = new Pizzicato.Sound({
				source: "file",
				loop: true,
				options: {
					path: "public/audio/404_owl.mp3"
				}
			}, function () {
				o.play()
			});
			o.release = .4;
			o.volume = 2;
			t.init();
			a.init();
			$_window.on("blur.audios", function () {
				o.pause()
			});
			$_window.on("focus.audios", function () {
				o.play()
			});
			$.doTimeout(1200, function () {
				$(".page-header a").addClass("js-active")
			});
			TweenMax.set(i, {
				scale: .8,
				skewX: 10,
				rotationX: -90,
				transformOrigin: "center center",
				perspective: 300
			});
			TweenMax.staggerTo(i, 2, {
				opacity: 1,
				scale: 1,
				skewX: 0,
				rotationX: 0,
				ease: Elastic.easeOut.config(1, .7)
			}, .15);
			e.on("mousemove.headerParallax", function (e) {
				var t = $(this);
				var a = e.clientX - t[0].getBoundingClientRect().left - t.width() / 2;
				var o = e.clientY - t[0].getBoundingClientRect().top - t.height() / 2;
				TweenMax.to(n, 1, {
					x: a * .01,
					y: o * .01
				});
				TweenMax.to(i.eq(0), 1, {
					x: a * .025,
					y: o * .025
				});
				TweenMax.to(i.eq(1), 1, {
					x: a * .035,
					y: o * .035
				});
				TweenMax.to(i.eq(2), 1, {
					x: a * .025,
					y: o * .025
				})
			})
		}
	};
	var r = function () {
		$_window.off("blur.audios");
		$_window.off("focus.audios");
		o.stop();
		o = null
	};

	function l() {
		var e, a, o, t, i, n, s;
		var r = ["AliceBlue", "AntiqueWhite", "Aqua", "Aquamarine", "Azure", "Beige", "Bisque", "Black", "BlanchedAlmond", "Blue", "BlueViolet", "Brown", "BurlyWood", "CadetBlue", "Chartreuse", "Chocolate", "Coral", "CornflowerBlue", "Cornsilk", "Crimson", "Cyan", "DarkBlue", "DarkCyan", "DarkGoldenRod", "DarkGray", "DarkGrey", "DarkGreen", "DarkKhaki", "DarkMagenta", "DarkOliveGreen", "Darkorange", "DarkOrchid", "DarkRed", "DarkSalmon", "DarkSeaGreen", "DarkSlateBlue", "DarkSlateGray", "DarkSlateGrey", "DarkTurquoise", "DarkViolet", "DeepPink", "DeepSkyBlue", "DimGray", "DimGrey", "DodgerBlue", "FireBrick", "FloralWhite", "ForestGreen", "Fuchsia", "Gainsboro", "GhostWhite", "Gold", "GoldenRod", "Gray", "Grey", "Green", "GreenYellow", "HoneyDew", "HotPink", "IndianRed", "Indigo", "Ivory", "Khaki", "Lavender", "LavenderBlush", "LawnGreen", "LemonChiffon", "LightBlue", "LightCoral", "LightCyan", "LightGoldenRodYellow", "LightGray", "LightGrey", "LightGreen", "LightPink", "LightSalmon", "LightSeaGreen", "LightSkyBlue", "LightSlateGray", "LightSlateGrey", "LightSteelBlue", "LightYellow", "Lime", "LimeGreen", "Linen", "Magenta", "Maroon", "MediumAquaMarine", "MediumBlue", "MediumOrchid", "MediumPurple", "MediumSeaGreen", "MediumSlateBlue", "MediumSpringGreen", "MediumTurquoise", "MediumVioletRed", "MidnightBlue", "MintCream", "MistyRose", "Moccasin", "NavajoWhite", "Navy", "OldLace", "Olive", "OliveDrab", "Orange", "OrangeRed", "Orchid", "PaleGoldenRod", "PaleGreen", "PaleTurquoise", "PaleVioletRed", "PapayaWhip", "PeachPuff", "Peru", "Pink", "Plum", "PowderBlue", "Purple", "Red", "RosyBrown", "RoyalBlue", "SaddleBrown", "Salmon", "SandyBrown", "SeaGreen", "SeaShell", "Sienna", "Silver", "SkyBlue", "SlateBlue", "SlateGray", "SlateGrey", "Snow", "SpringGreen", "SteelBlue", "Tan", "Teal", "Thistle", "Tomato", "Turquoise", "Violet", "Wheat", "White", "WhiteSmoke", "Yellow", "YellowGreen"];
		t = 1e3;
		0;
		s = .02;
		l();

		function l() {
			a = document.getElementById("myCanvas");
			a.width = window.innerWidth;
			a.height = window.innerHeight;
			o = a.getContext("2d");
			c();
			d()
		}

		function c() {
			n = [];
			n.push(new m)
		}

		function f(e) {
			o.clearRect(0, 0, a.width, a.height);
			a.width = window.innerWidth;
			a.height = window.innerHeight;
			o = a.getContext("2d");
			i = g();
			u();
			id = requestAnimationFrame(f)
		}

		function d() {
			e = requestAnimationFrame(f)
		}

		function p() {
			if (e) {
				cancelAnimationFrame(e)
			}
		}

		function u() {
			h()
		}

		function h() {
			if (n.length < t - 1) {
				n.push(new m)
			}
			for (var e = 0; e <= n.length - 1; e++) {
				n[e].draw();
				n[e].update()
			}
		}

		function m() {
			var e = this;
			(function () {
				t()
			})();
			e.update = function () {
				if (e.opacity < 0) {
					t()
				} else {
					e.opacity -= e.speedOpacity
				}
				e.heigthv = e.heigth < e.width && e.heigth > 0 ? e.heigthv : -e.heigthv;
				e.heigth += e.heigthv;
				e.rotation += e.rotationv;
				e.x = e.x + e.vx;
				e.y = e.y + e.vy;
				e.vy += s
			};
			e.draw = function () {
				o.save();
				o.beginPath();
				o.globalAlpha = e.opacity;
				o.fillStyle = e.color;
				o.translate(e.x, e.y);
				o.rotate(e.rotation * Math.PI / 180);
				o.ellipse(0 - e.width / 2, 0 - e.heigth / 2, Math.abs(e.width), Math.abs(e.heigth), 0, 0, 2 * Math.PI);
				o.fill();
				o.closePath();
				o.restore()
			};

			function t() {
				e.x = a.width / 2;
				e.y = a.height / 2;
				e.vx = -2 + Math.random() * 4;
				e.vy = Math.random() * -5;
				e.width = Math.random() * i / 20;
				e.heigth = Math.random() * e.width;
				e.heigthv = Math.random() * 2;
				e.rotation = Math.random() * 360;
				e.rotationv = Math.random() * 3;
				e.color = r[Math.floor(Math.random() * r.length)];
				e.opacity = 1;
				e.speedOpacity = Math.random() * .3
			}
		}

		function g() {
			var e = Math.min(a.width, a.height);
			return e / 2.5
		}
	}
	return {
		init: s,
		kill: r
	}
}

function homePage() {
	if ($_body.hasClass("js-no-ajax")) {
		var e = 0,
			t = 1
	} else {
		var e = .5,
			t = 1.5
	}
	var o = animateRollingLetters();
	var a = hashtagScroll(),
		n ,
		s ,
		f = headerParallax($(".page-header")),
		d = new GlAnimation({
			title: "home",
			desktopMaxWidth: 1440,
			objsGroup_ScaleDesktop: 1,
			objLetter_ScaleDesktop: .95,
			objGroupX_desktop: 0,
			objGroupY_desktop: .5,
			objGroupZ_desktop: 0,
			desktopMaxMaxWidth: 1800,
			objsGroup_ScaleMaxDesktop: 1,
			objLetter_ScaleMaxDesktop: .95,
			objGroupX_MaxDesktop: 0,
			objGroupY_MaxDesktop: .5,
			objGroupZ_MaxDesktop: 0,
			objWidthBigDesktop: 1,
			objLetter_ScaleBigDesktop: .95,
			objGroupX_bigDesktop: 0,
			objGroupY_bigDesktop: .5,
			objGroupZ_bigDesktop: 0,
			aniTime: 1.2,
			aniTimeLoop: 5.5,
			aniTimeFadeIn: 1,
			mouseInfluence: .8
		});
	var u = $(".gallery"),
		h = $(".rotate-title h2"),
		m = $(".morphing-letter"),
		pf = $(".page-footer"),
		//g = $(".news-events"),
		//w = $(".news-container"),
		b = $(".plus-button"),
		x = $(".statistics"),
		_ = $(".popup-link .rgpd-wrapper"),
		T = $(".deloitte-background svg path");
		if($(".page-header h2").length>0){
			n = textFireworks({
				element: $(".page-header h2"),
				animationType: "jellyType",
				delay: e
			});
		}
		if($(".page-header h3").length>0){
			s = textFireworks({
				element: $(".page-header h3"),
				animationType: "smoothEntrance",
				delay: t
			});
		}
	var M = function () {
		if (_customScroll == null) {
			_scrollRef = function () {
				_raf_loop_id = _rAF_loop(H)
			};
			$_window.on("scroll.homePage", _scrollRef)
		} else {
			_page_scroll_listener = function (e) {
				H(e)
			};
			_customScroll.addListener(_page_scroll_listener)
		}
		$_body.detach(".rotate-title-fixed");
		h.each(function () {
			var e = $(this);
			e.css("height", e.closest(".container").outerHeight() + "px")
		});
		P();
		S();
		elasticButtonsInputs();
		O();
		E();
		statisticsAnimation();
		TweenMax.to(T, 2, {
			morphSVG: "M396,1S42.25-.17,6,254c-34,260,389,75.06,389,431Z",
			repeat: -1,
			yoyo: true,
			ease: Sine.easeOut
		});
		if (!$_body.hasClass("mobile")) {
			if(f) f.init();
			d.init(function () {
				$.doTimeout(1e3, function () {

					if (n){
						n.init();
					}
					if (s){
						s.init();
					}
					
					
					o.init();
				})
			});
			
			TweenMax.to($_body.find(".main-logo"), .5, {
				opacity: 1
			});
			TweenMax.to($_body.find(".language-wrapper"), .5, {
				opacity: 1
			});
			hoverLinksMenu()
		}
		j()
	};
	var k = function () {
		if (!$_body.hasClass("mobile")) {

			if (f)	f.kill();
			if (n)	n.kill();
		
			
			if (s) s.kill();
			
			o.kill();
			if (d) d.kill()
		}
		_scrollRef = null;
		cancelAnimationFrame(_raf_main_id);
		if (_customScroll) {
			_customScroll.removeListener(_page_scroll_listener)
		} else {
			$_window.off("scroll.homePage");
			$_window[0].cancelAnimationFrame(_raf_loop_id)
		}
	};


	function j() {
		_.on("click", function () {
			if (!$(".popup-link form").hasClass("active")) {
				$(".popup-link form").addClass("active");
				$(".popup-link form .rgpd-wrapper svg").addClass("active");
				$(".popup-link form input.check-policy").val("checked");
				$(".popup-link form input.check-policy").trigger("blur");
				$(".newsletter-popup").find("button").addClass("enable")
			} else {
				$(".popup-link form").removeClass("active");
				$(".popup-link form .rgpd-wrapper svg").removeClass("active");
				$(".popup-link form input.check-policy").val("");
				$(".popup-link form input.check-policy").trigger("blur");
				$(".newsletter-popup").find("button").removeClass("enable")
			}
		});
		$(".popup-link .elastic-input").on("focus", function () {
			TweenMax.to($(".submit-btn").find("path"), 1, {
				morphSVG: $(".submit-btn").attr("data-hover"),
				ease: Elastic.easeOut.config(1, .5)
			})
		}).on("blur", function () {
			TweenMax.to($(".submit-btn").find("path"), 1, {
				morphSVG: $(".submit-btn").attr("data-original"),
				ease: Elastic.easeOut.config(1, .5)
			})
		});
		
		b.on("click", function () {
			var n = $(this),
				s = Number(n.attr("data-offset")),
				r = Number(n.attr("data-limit"));
			TweenMax.to(n.parent().find("span"), .4, {
				opacity: 0,
				ease: Expo.easeOut
			});
			TweenMax.to(n, 1, {
				rotation: 180,
				ease: Elastic.easeIn.config(1, .7),
				repeat: -1,
				yoyo: true,
				force3D: true
			});
			$.get("/wp-json/affinity/v1/news/more-news?offset=" + s + "&limit=" + r, function (e) {
				var i = $(e["news"]);
				i.imagesLoaded(function (e, t, a) {
					var o = $(this).imagesLoaded();
					o.always(function () {
						TweenMax.set(i, {
							opacity: 0,
							y: "50px"
						});
						$(".news-list-wrapper").append(i);
						TweenMax.to(n, 1, {
							scale: 0,
							ease: Elastic.easeIn.config(1, .7),
							onComplete: function () {
								n.parent().remove();
								n.attr("data-offset", Number(s + r));
								TweenMax.staggerTo(i, 3, {
									opacity: 1,
									y: "0px",
									ease: Elastic.easeOut.config(1, .7)
								}, .2)
							}
						})
					})
				})
			})
		})
	}

	function E() {
		var e = $("#social-activity"),
			n = e.find(".twitter-container, .news-container, .vertical-block, .gallery:not(.gallery-facebook-titles), .insta-container"),
			t = e.find(".gallery:not(.gallery-facebook-titles)");
		t.each(function () {
			var e = $(this);
			s("next", e)
		});
		$_window.on("resize.Mosaic", $.debounce(200, a));

		function a() {
			t.each(function () {
				var e = $(this);
				TweenMax.set(e.find("li"), {
					clearProps: "all"
				});
				TweenMax.killTweensOf(e.find("li"));
				s("next", e)
			})
		}

		function o() {
			var e = $(".clients"),
				t = $(".contacts");
			var a = {
				rootMargin: "0px",
				threshold: [0]
			};
			var o = function (e, t) {
				e.forEach(function (e) {
					if (e.isIntersecting || e.intersectionRatio > 0) {
						TweenMax.to(e.target, 2, {
							y: "0px",
							ease: Elastic.easeOut.config(1, .7)
						})
					}
				})
			};
			var i = new IntersectionObserver(o, a);
			n.each(function () {
				var e = $(this);
				TweenMax.set(e, {
					y: "100px"
				});
				i.observe(e[0])
			})
		}

		function s(e, t) {
			var a = t,
				o = a.find("li"),
				i = Math.floor(Math.random() * 10) + 5;
			if (e == "next") {
				if (a.hasClass("gallery-facebook")) {
					TweenMax.to(a.parent(".facebook-container").find(".gallery-facebook-titles"), 2, {
						x: "-=" + a.parent(".facebook-container").find(".gallery-facebook-titles").outerWidth(),
						delay: i + .1,
						ease: Expo.easeOut
					})
				}
				TweenMax.to(o, 2, {
					x: "-=" + o.outerWidth(),
					delay: i,
					ease: Expo.easeOut,
					onComplete: function () {
						s("previous", a)
					}
				})
			} else {
				if (a.hasClass("gallery-facebook")) {
					TweenMax.to(a.parent(".facebook-container").find(".gallery-facebook-titles"), 2, {
						x: "+=" + a.parent(".facebook-container").find(".gallery-facebook-titles").outerWidth(),
						delay: i + .1,
						ease: Expo.easeOut
					})
				}
				TweenMax.to(o, 2, {
					x: "+=" + o.outerWidth(),
					delay: i,
					ease: Expo.easeOut,
					onComplete: function () {
						s("next", a)
					}
				})
			}
		}
	}

	function O() {
		u.each(function () {
			var e = $(this),
				t = e.find("li").length;
			e.css("width", $(this).find("li").outerWidth() * t + "px");
			e.find("li").css("width", u.outerWidth() / t + "px")
		})
	}

	function S() {
		var a = $("form");
		breakTitleLetters(a.find(".elastic-btn .text"));
		a.find("input").on("focus", function () {
			TweenMax.staggerTo(a.find("label"), .5, {
				opacity: 0,
				ease: Expo.easeOut
			})
		});
		a.find("input").on("blur", function () {
			var e = $(this);
			if (e.val() === 0 || e.val() == "") {
				TweenMax.staggerTo(a.find("label"), .5, {
					opacity: 1,
					ease: Expo.easeOut
				})
			}
			if ($_html.hasClass("safari")) {
				if (_customScroll) {
					_customScroll.update()
				}
			}
		});
		a.on("submit", function (e) {
			var t = $(this);
			e.stopImmediatePropagation();
			e.preventDefault();
			e.stopPropagation();
			if (validateForm(a) && !a.hasClass("js-sending")) {
				t.addClass("js-sending");
				a.find(".elastic-input").removeClass("erro");
				t.find(".elastic-btn").css({
					"pointer-events": "none"
				});
				TweenMax.staggerTo(a.find(".elastic-btn .text .char"), 1, {
					y: "-50px",
					opacity: 0,
					ease: Expo.easeOut
				}, .05);
				TweenMax.to(a.find(".loader-wrapper"), .2, {
					opacity: 1,
					delay: 1
				});
				$.ajax({
					type: "post",
					url: "/mailchimp.php",
					data: a.serialize(),
					success: function (e) {
						$.doTimeout(2e3, function () {
							a.find(".elastic-btn .text").text("success");
							breakTitleLetters(a.find(".elastic-btn .text"));
							TweenMax.set(a.find(".elastic-btn .text .char"), {
								y: "-50px",
								opacity: 0
							})
						});
						$.doTimeout(4e3, function () {
							TweenMax.staggerTo(a.find(".elastic-btn .text .char"), 1, {
								y: "0px",
								opacity: 1,
								ease: Expo.easeOut
							}, -.05, function () {
								a.find("input").trigger("blur")
							});
							TweenMax.to(a.find(".loader-wrapper"), .2, {
								opacity: 0
							})
						})
					},
					error: function (e) {
						$.doTimeout(2e3, function () {
							a.find(".elastic-btn .text").text("try again!");
							breakTitleLetters(a.find(".elastic-btn .text"));
							TweenMax.set(a.find(".elastic-btn .text .char"), {
								y: "-50px",
								opacity: 0
							})
						});
						$.doTimeout(4e3, function () {
							TweenMax.staggerTo(a.find(".elastic-btn .text .char"), 1, {
								y: "0px",
								opacity: 1,
								ease: Expo.easeOut
							}, -.05, function () {
								a[0].reset();
								a.find("input").trigger("blur")
							});
							TweenMax.to(a.find(".loader-wrapper"), .2, {
								opacity: 0
							})
						})
					}
				})
			} else if (!validateForm(a)) {
				a.find(".elastic-input").addClass("erro")
			}
		})
	}

	function P() {
		var e = $(".we-do-list .column");
		e.on("mousemove", function (e) {
			var t = $(this).find("a");
			var a = e.clientX - t.parent()[0].getBoundingClientRect().left - t.width() / 2;
			var o = e.clientY - t.parent()[0].getBoundingClientRect().top - t.height() / 2;
			TweenMax.to(t, .5, {
				x: a * .1,
				y: o * .1
			});
			TweenMax.to(t.find("h3"), .75, {
				x: a * .05,
				y: o * .05
			})
		});
		e.on("mouseleave", function () {
			var e = $(this).find("a");
			TweenMax.to(e, .5, {
				y: "0px",
				x: "0px",
				ease: Elastic.easeOut.config(1, .75)
			});
			TweenMax.to(e.find("h3"), .75, {
				y: "0px",
				x: "0px",
				ease: Elastic.easeOut.config(1, .75)
			})
		})
	}


	function H(e) {
		if (!_browserObj.isMobile) {
			if (!_browserObj.isIE && !_browserObj.isEdge) {
				a.init(_scrollY)
			}
			if (!_browserObj.isSafari) {
				m.each(function () {
					var e = $(this);
					if (verge.inViewport(e) && !e.hasClass("js-animated")) {
						e.addClass("js-animated");
						e.data("animation").play()
					} else if (!verge.inViewport(e) && e.hasClass("js-animated")) {
						e.removeClass("js-animated");
						e.data("animation").stop()
					}
				})
			}
			if (_scrollY > _globalViewportH / 10 && !$_pageHeader.hasClass("js-paused")) {
				$_pageHeader.addClass("js-paused");
				d.pause()
			}
			if (_scrollY < _globalViewportH / 10 && $_pageHeader.hasClass("js-paused")) {
				$_pageHeader.removeClass("js-paused");
				d.resume()
			}
			
		}

		
		if (!_browserObj.isPhone) {
			if (verge.inViewport(_, -_globalViewportH)) {
				i.animateIn()
			}
			if (verge.inViewport($_pageFooter, -100) && !$_pageFooter.hasClass("js-animated-2")) {
				$_pageFooter.addClass("js-animated-2");
				$_pageFooter.addClass("add-bg-color");
				$_pageFooter.prev("section").addClass("add-bg-color");
				$.doTimeout(250, function () {
					if (!$_body.hasClass("mobile")) {
						o.animate()
					}
				})
			} else if (!verge.inViewport($_pageFooter, -100) && $_pageFooter.hasClass("js-animated-2")) {
				$_pageFooter.removeClass("js-animated-2");
				$_pageFooter.removeClass("add-bg-color");
				$_pageFooter.prev("section").removeClass("add-bg-color");
				
			}
		}
	}
	return {
		init: M,
		kill: k
	}
}

function singleServicePage() {

	if ($_body.hasClass("js-no-ajax")) {
		var se = 0,
			st = 1
	} else {
		var se = .5,
			st = 1.5
	}


	var t = xe(),
		a = new be,
		o = new we,
		e = new ve,
		b = "";
	var os= animateRollingLetters();
	var n = $(".reasons-list-wrapper"),
		s = $(".cases-studies"),
		v = $(".navbar-services-wrapper").detach(),
		r = $(".svg-scale"),
		l = $(".client-logo"),
		c = $(".image-blocks"),

		

		f = $(".main-conditions-titles"),
		d = $(".js-height"),
		ss = sequenceDance($(".to-section")),
		gs = $(".to-section"),
		bs = $(".to-section .columns").find("h3"),

		p = $(".morphing-letter"),
		u = $(".technologies"),
		ht=$(".header-text"),
		h = $(".vertical-menu-wrapper"),
		m = v.height();
		
		//var g = hashtagScroll(),
		var ph,
		phs,
		pfp = headerParallax($(".page-header"));

		var w = false,
		x, _ = null,
		y = true,
		T = true,
		M = false,
		k = false,
		j = false,
		C = false,
		E = [],
		O = [],
		S, P = _e($_pageToLoad.attr("data-bodyClass").split(" ")[1]);
	if ($_body.hasClass("what-we-do") ) {
		var H = $(".steps-container"),
			A = $(".recruitment .image-wrapper"),
			D = $(".our-model"),
			L = $(".model-line-container"),
			I = $(".start-project"),
			V = $(".close-filter"),
			F = $(".cta-start-project"),
			R = F.find(".morph-bg"),
			G = I.find("input"),
			B = $(".morph-enter-button"),
			z = $(".option-wrapper"),
			Y = $(".start-project-container"),
			W = $(".input-area"),
			X = $(".input-area.active"),
			q = $(".testimonials-section"),
			N = $(".feedback-form"),
			U = F.find(".cta-bg"),
			Z = $(".reminder"),
			J = $(".wrapper-input"),
			Q = $(".enter-button"),
			K = $(".loader-wrapper"),
			ee = $(".map-wrapper"),
			u = $(".technologies"),
			te = new TimelineMax({
				paused: true
			}),
			ae = F.outerHeight(),
			oe = W.outerHeight(true),
			ie = D.outerHeight(),
			ne = N.outerHeight(true),
			se = null,
			re, le = false,
			ce = I.find("form").outerHeight(),
			fe = I.find("form").outerHeight(),
			de = D.offset().top,
			pe = L.find("svg path")[0].getTotalLength(),
			ue = L.outerHeight(),
			he = L.offset().top

	}
	if($(".page-header h2").length>0){
		ph = textFireworks({
			element: $(".page-header h2"),
			animationType: "jellyType",
			delay: se
		});
	}
	if($(".page-header h3").length>0){
		phs = textFireworks({
			element: $(".page-header h3"),
			animationType: "smoothEntrance",
			delay: st
		});
	}
	var S = new GlAnimation({
			title: "Who we are",
			desktopMaxWidth: 1440,
			objsGroup_ScaleDesktop: .95,
			objText_ScaleDesktop: 16,
			objLetter_ScaleDesktop: .94,
			objExtra1_ScaleDesktop: 2.35,
			objExtra2_ScaleDesktop: .035,
			objExtra2_Y_ScaleDesktop: -26,
			objGroupX_desktop: 0,
			objGroupY_desktop: 1.2,
			objGroupZ_desktop: 0,
			desktopMaxMaxWidth: 1800,
			objsGroup_ScaleMaxDesktop: .95,
			objText_ScaleMaxDesktop: 16,
			objLetter_ScaleMaxDesktop: .94,
			objExtra1_ScaleMaxDesktop: 2.35,
			objExtra2_ScaleMaxDesktop: .035,
			objExtra2_Y_ScaleMaxDesktop: -26,
			objGroupX_MaxDesktop: 0,
			objGroupY_MaxDesktop: 1.2,
			objGroupZ_MaxDesktop: 0,
			objWidthBigDesktop: .95,
			objText_ScaleBigDesktop: 16,
			objLetter_ScaleBigDesktop: .94,
			objExtra1_ScaleBigDesktop: 2.35,
			objExtra2_ScaleBigDesktop: .035,
			objExtra2_Y_ScaleBigDesktop: -26,
			objGroupX_bigDesktop: 0,
			objGroupY_bigDesktop: 1.2,
			objGroupZ_bigDesktop: 0,
			aniTime: 1.1,
			owlAniTime: 2,
			aniTimeLoop: 5.5,
			aniTimeFadeIn: 1,
			mouseInfluence: .8
		})
	var me = function () {
		if (_customScroll == null) {
			_scrollRef = function () {
				_raf_loop_id = _rAF_loop(Me)
			};
			$_window.on("scroll.single-service", _scrollRef)
		} else {
			_page_scroll_listener = function (e) {
				Me(e)
			};
			_customScroll.addListener(_page_scroll_listener)
		}
		b = window.location.search;
		b = b.replace("?", "").split("=")[1];
		$e();
		$_body.detach(".rotate-title-fixed");
		if (!$_body.hasClass("mobile")) {
			hoverLinksMenu()
		}
		$_body.append(v);
		if ( $_body.hasClass("what-we-do") ) {
			e.init()
		}
		$.doTimeout(1e3, function () {
			if (!_browserObj.isMobile) {
				a.init();
				ss.init();
				if($(".page-header h2").length>0){
					ph.init();
				}
				if($(".page-header h3").length>0){
					phs.init();
				}
				pfp.init();
				if (  $_body.hasClass("what-we-do")) {
					o.init();
					ue = L.outerHeight();
					he = L.offset().top
				}
			}
		});



				TweenMax.to($_body.find(".main-logo"), .5, {
					opacity: 1
				});
				TweenMax.to($_body.find(".language-wrapper"), .5, {
					opacity: 1
				});
	

		if (!$_body.hasClass("mobile")) {
			TweenMax.to($_body.find(".main-logo"), .5, {
				opacity: 1
			});
			TweenMax.to($_body.find(".language-wrapper"), .5, {
				opacity: 1
			});
			TweenMax.to($_pageHeader.find(".background-image"), .5, {
				opacity: 1
			});
			if ($_body.hasClass("js-no-ajax")) {
				S.init()
			} else {
				$.doTimeout(0, function () {
					S.init()
				})
			}
			TweenMax.to($_pageHeader.find(".js-pet"), 2.5, {
				opacity: 1,
				scale: 1,
				ease: Expo.easeOut,
				delay: .2
			})
		}
		u.find("li").hover(function () {
			var e = $(this),
				t = e.attr("data-hover");
			x = e.find("img").attr("src");
			e.find("img").attr("src", t)
		}, function () {
			var e = $(this);
			e.find("img").attr("src", x)
		});
		l.hover(function () {
			var e = $(this),
				t = e.attr("data-hover");
			_ = e.find("img").attr("src");
			e.find("img").attr("src", t)
		}, function () {
			var e = $(this);
			e.find("img").attr("src", _)
		});
		f.find("li").on("click", function () {
			var e = $(this),
				t = e.attr("data-target");
			if (y) {
				y = false;
				f.find("li").removeClass("active");
				e.addClass("active");
				TweenMax.staggerTo($(".conditions-wrapper").filter(".active").find(".condition"), .6, {
					y: 50,
					opacity: 0,
					ease: Elastic.easeInOut.config(1, .8),
					onComplete: function () {
						$(".conditions-wrapper").filter(".active").removeClass("active")
					}
				}, .1);
				TweenMax.staggerTo($("." + t + "").find(".condition"), .6, {
					y: 0,
					opacity: 1,
					ease: Elastic.easeInOut.config(1, .8),
					onComplete: function () {
						$("." + t + "").addClass("active");
						y = true
					}
				}, .1)
			}
		});
		$.doTimeout(1e3, function () {
			Te();
			//ye();
			elasticButtonsInputs();
			t.init()
		});
		if (!$_body.hasClass("mobile")) {
			$.doTimeout(1e3, function () {
				animateMorphLetters()
			});
			n.find("li").each(function () {
				var e = $(this);
				TweenMax.set(e.find("svg path"), {
					"stroke-dashoffset": e.find("svg path")[0].getTotalLength(),
					"stroke-dasharray": e.find("svg path")[0].getTotalLength()
				});
				TweenMax.set(e.find("p"), {
					y: "40px",
					opacity: 0
				})
			})
		}
		v.find("a").on("click", function () {
			TweenMax.to(v, .6, {
				y: -v.height() - 50,
				ease: Power4.easeInOut,
				onComplete: function () {
					v.remove()
				}
			})
		});
	
	};
	var ge = function () {
		if (!$_body.hasClass("mobile")) {
			P.kill();
			if (S) S.kill()
		}
		_scrollRef = null;
		cancelAnimationFrame(_raf_main_id);
		if (_customScroll) {
			_customScroll.removeListener(_page_scroll_listener)
		} else {
			$_window.off("scroll.singleServicePage");
			$_window[0].cancelAnimationFrame(_raf_loop_id)
		}
		v.remove();
		a.kill();
		if ($_body.hasClass("what-we-do")) {
			o.kill();
			e.kill()
		}
	};

	function we() {
		this.init = function () {
			TweenMax.set(L.find("svg path"), {
				strokeDashoffset: pe
			});
			TweenMax.set(L.find("svg path"), {
				strokeDasharray: pe
			});
			H.each(function (e) {
				var t = $(this),
					a = {};
				a.element = t;
				a.offset = t.offset().top + 50;
				a.height = t.outerHeight(true), a.index = e;
				O.push(a)
			});
			if (!_browserObj.isMobile) {
				$_window.on("resize.affinitySteps", $.debounce(500, e))
			}
			O[3].offset += 100
		};
		this.animate = function (e) {
			if (e > he -100 && e < he + ue - 100) {
				var t = pe - normalizedValue(_scrollY - he + 100, ue, 0) * pe;
				TweenMax.set(L.find("svg path"), {
					strokeDashoffset: t
				});
				if (t <= 0) {
					L.addClass("done");
					return false
				}
				for (var a = 0; a < O.length; a++) {
					if (_scrollY > O[a].offset - 30 - O[a].height) {
						H.find(".step").filter(".background-blue").removeClass("background-blue");
						H.eq(a).find(".step").addClass("background-blue");
						TweenMax.to(H.eq(a).find(".step"), 1, {
							scale: 1,
							opacity: 1,
							ease: Elastic.easeOut.config(1, .8)
						});
						TweenMax.to(H.eq(a).find(".step h2"), 2, {
							y: 0,
							opacity: 1,
							delay: .2,
							ease: Elastic.easeOut.config(1, .8)
						});
						TweenMax.to(H.eq(a).find(".step h3"), 2, {
							y: 0,
							opacity: 1,
							delay: .4,
							ease: Elastic.easeOut.config(1, .8)
						});
						TweenMax.to(H.eq(a).find(".step p"), 2, {
							y: 0,
							opacity: 1,
							delay: .6,
							ease: Elastic.easeOut.config(1, .8)
						})
					}
				}
			}
		};

		function e() {
			pe = L.find("svg path")[0].getTotalLength();
			H.each(function (e) {
				var t = $(this),
					a = t.offset().top;
				O[e] = a
			})
		}
		this.kill = function () {
			$_window.off("resize.affinitySteps")
		}
	}

	function be() {
		this.init = function () {
			d.each(function (e) {
				var t = $(this),
					a = {};
				a.element = t;
				a.offset = t.offset().top;
				a.height = t.outerHeight();
				a.lineWidth = h.find("li").eq(d.length - 1 - e).find(".container-height").outerWidth();
				E.push(a)
			});
			h.find("li").click(function () {
				var e = $(this),
					t = e.index();
				e.prevAll("li").addClass("active");
				e.addClass("active");
				if (_customScroll) {
					_customScroll.scrollTo(0, E[E.length - 1 - t].offset, 2e3, function () {
						_customScroll.registerEvents(/scroll/, /wheel/, /touchstart/)
					})
				} else {
					$("html, body").animate({
						scrollTop: E[E.length - 1 - t].offset
					}, 1e3)
				}
			});
			$_window.on("resize.verticalMenu", $.debounce(500, e))
		};
		this.animateSection = function (e) {
			for (var t = 0; t < E.length; t++) {
				if (e > E[t].offset && e < E[t].offset + E[t].height) {
					var a = normalizedValue(e - E[t].offset, E[t].height, 0);
					h.find("li").eq(E.length - 1 - t).addClass("active");
					TweenMax.set(h.find("li").eq(E.length - 1 - t).find(".container-height"), {
						scaleX: a,
						force3D: true
					})
				}
				if (e < E[t].offset) {
					h.find("li").eq(E.length - 1 - t).removeClass("active");
					TweenMax.set(h.find("li").eq(E.length - 1 - t).find(".container-height"), {
						scaleX: 0,
						force3D: true
					})
				}
			}
		};
		this.show = function () {
			TweenMax.staggerTo(h.find("li"), 1, {
				y: 0,
				ease: Elastic.easeOut.config(1, .8)
			}, .1);
			h.addClass("enable")
		};
		this.hide = function () {
			TweenMax.staggerTo(h.find("li"), 1, {
				y: -200,
				ease: Elastic.easeOut.config(1, .8)
			}, .1);
			h.removeClass("enable")
		};
		this.kill = function () {
			$_window.off("resize.verticalMenu")
		};

		function e() {
			E = [];
			d.each(function (e) {
				var t = $(this),
					a = {};
				a.element = t;
				a.offset = t.offset().top;
				a.height = t.outerHeight();
				a.lineWidth = h.find("li").eq(d.length - 1 - e).find(".container-height").outerWidth();
				E.push(a)
			})
		}
	}

	function ve() {
		this.init = function () {
			if (_browserObj.isPhone) {
				for (var i = 0; i < 3; i++) {
					W.eq(i).removeClass("hidden")
				}
			}
			$.doTimeout(2e3, function () {
				te.to(U.find("path"), 2, {
					morphSVG: "M384.7,365.3C276.2,451.3,63.3,270.5,245,127c114.9-90.8,392,14,328.5,145.7C513.5,397.1,472,296,384.7,365.3z",
					repeat: -1,
					yoyo: true,
					ease: Sine.easeInOut
				});
				te.play()
			});
			if (!_browserObj.isMobile) {
				U.find("path").hover(function () {
					te.pause();
					TweenMax.to(U.find("path"), 2, {
						morphSVG: "M194.5,272.7C127,149,337.9-26.9,508.9,135.5C639,259,435,253,383.3,365.3C335.6,469,260.7,393.9,194.5,272.7z",
						ease: Elastic.easeOut.config(1, .5),
						onComplete: function () {
							te.stop()
						}
					});
					TweenMax.to(R, 1, {
						morphSVG: R.attr("data-hover"),
						ease: Elastic.easeOut.config(1, .5)
					})
				}, function () {
					TweenMax.to(U.find("path"), 2, {
						morphSVG: "M384.7,365.3C289.8,466.1,77.4,279,259.1,135.5C374,44.8,623.8,155.2,573.5,272.7C519.2,399.7,462.9,282.2,384.7,365.3z",
						ease: Elastic.easeOut.config(1, .5),
						onComplete: function () {
							TweenMax.to(U.find("path"), 2, {
								morphSVG: "M384.7,365.3C276.2,451.3,63.3,270.5,245,127c114.9-90.8,392,14,328.5,145.7C513.5,397.1,472,296,384.7,365.3z",
								repeat: -1,
								yoyo: true,
								ease: Sine.easeInOut
							})
						}
					});
					TweenMax.to(R, 1, {
						morphSVG: "M23 0h112a23 23 0 1 1 0 46H23a23 23 0 1 1 0-46z",
						ease: Elastic.easeOut.config(1, .5)
					})
				})
			}
			TweenMax.to(F.find(".cta-bg path"), 1, {
				morphSVG: F.find(".cta-bg path").attr("data-init"),
				ease: Elastic.easeOut.config(1, .7),
				autoAlpha: 1,
				delay: 1.5
			});
			TweenMax.to(F.find("h3"), 1, {
				scale: 1,
				ease: Elastic.easeOut.config(1, .7),
				autoAlpha: 1,
				delay: 1.8,
				force3D: true
			});
			TweenMax.to(F.find(".elastic-btn svg"), 1, {
				ease: Elastic.easeOut.config(1, .7),
				scale: 1,
				autoAlpha: 1,
				delay: 1.9,
				force3D: true
			});
			TweenMax.to(F.find(".elastic-btn span"), 1, {
				scale: 1,
				ease: Elastic.easeOut.config(1, .7),
				autoAlpha: 1,
				delay: 2,
				force3D: true
			});
			if (!_browserObj.isMobile) {
				I.css("top", _scrollY + _globalViewportH - I.outerHeight() - 125);
				$_window.on("resize.startProject", $.debounce(500, e))
			}

			function e() {
				I.css("top", _scrollY + _globalViewportH - I.outerHeight() - 125)
			}
			z.on("click", function () {
				var e = $(this),
					t = e.attr("data-city");
				z.removeClass("done");
				TweenMax.to(z.find(".stroke"), 1, {
					fill: "#0074AE",
					ease: Elastic.easeOut.config(1, .7)
				});
				TweenMax.to(z.find(".full"), 1, {
					opacity: 0,
					ease: Elastic.easeOut.config(1, .7)
				});
				TweenMax.to(z.find(".dash"), 1, {
					strokeDashoffset: 23,
					ease: Elastic.easeOut.config(1, .7)
				});
				TweenMax.to(e.find(".full"), 1, {
					opacity: 1,
					ease: Elastic.easeOut.config(1, .7)
				});
				TweenMax.to(e.find(".dash"), 1, {
					strokeDashoffset: 0,
					ease: Elastic.easeOut.config(1, .7)
				});
				e.addClass("done");
				C = true;
				$("#location").attr("data-final-city", t);
				TweenMax.to(B, 1, {
					opacity: 1,
					pointerEvents: "all",
					ease: Elastic.easeOut.config(1, .7)
				})
			});
			G.on("keydown", function () {
				var e = $(this),
					t = e.attr("data-text");
				if ($.trim($(this).val()) != t) {
					TweenMax.to(e.closest(W).find(Q), 1, {
						autoAlpha: 1,
						ease: Elastic.easeOut.config(1, .7),
						onComplete: function () {
							k = false
						}
					})
				}
				if (_browserObj.isPhone) {
					TweenMax.set(I, {
						y: _scrollY + _globalViewportH - I.outerHeight()
					})
				}
			});
			G.filter(".number").on("keyup", function (e) {
				if (/\D/g.test(this.value)) {
					this.value = this.value.replace(/\D/g, "")
				}
				if (this.value.length > 0) {
					M = true
				} else {
					M = false
				}
			});
			$(document).on("keydown", function (e) {
				switch (e.which) {
					case 27:
						if (I.find(".project-form").hasClass("js-open-form")) V.click();
						TweenMax.to(U.find("path"), 2, {
							morphSVG: "M384.7,365.3C276.2,451.3,63.3,270.5,245,127c114.9-90.8,392,14,328.5,145.7C513.5,397.1,472,296,384.7,365.3z",
							repeat: -1,
							yoyo: true,
							ease: Sine.easeInOut
						});
					case 13:
						I.find(".input-area.active").find(Q).click();
					default:
						break
				}
			});
			Q.on("click", function () {
				var e = $(this),
					t = W.index(e.closest(W));
				if (e.closest(W).find("input").hasClass("email")) {
					var a = e.closest(W).find("input.email").val();
					if (/(.+)@(.+){2,}\.(.+){2,}/.test(a)) {
						k = true;
						e.closest(J).removeClass("error")
					} else {
						k = false;
						e.removeClass("disable");
						e.closest(J).addClass("error")
					}
				} else {
					k = true
				}
				if (e.closest(W).find("input").hasClass("number")) {
					if (M) {
						e.closest(J).removeClass("error");
						TweenMax.to(e, 1, {
							autoAlpha: 0,
							ease: Elastic.easeOut.config(1, .7),
							onComplete: function () {
								k = true
							}
						})
					}
				}
				if ($.trim(e.closest(W).find("input").val()).length == 0) {
					e.closest(W).find(J).addClass("error");
					k = false
				} else {
					if (k) {
						e.closest(W).removeClass("active");
						e.closest(W).attr("data-text", $.trim(e.closest(W).find("input").val()));
						e.closest(W).find(J).removeClass("error");
						TweenMax.to(e, 1, {
							autoAlpha: 0,
							ease: Elastic.easeOut.config(1, .7),
							onComplete: function () {
								k = true
							}
						});
						if (e.closest(W).next() && e.closest(W).next().hasClass("hidden")) {
							e.closest(W).next().removeClass("hidden");
							e.closest(W).next().addClass("active");
							ce += oe;
							if (ce <= _globalViewportH * .7) {
								TweenMax.to(I.find("form"), 1, {
									height: ce,
									ease: Elastic.easeOut.config(1, .7)
								});
								if (!_browserObj.isMobile) {
									if (!$_html.hasClass("edge") || !$_html.hasClass("ie")) {
										TweenMax.to(I, 1, {
											top: _scrollY + _globalViewportH - I.outerHeight() - 125 - oe,
											ease: Elastic.easeOut.config(1, .7)
										})
									} else {
										TweenMax.to(I, 1, {
											top: -oe,
											ease: Elastic.easeOut.config(1, .7)
										})
									}
								} else {
									if ($_window.width() > 768 && $_window.width() < 1025) {
										TweenMax.to(I, 1, {
											top: -oe,
											ease: Elastic.easeOut.config(1, .7)
										})
									}
								}
							} else {
								se = Scrollbar.init(I.find("form")[0], {
									speed: .8,
									syncCallbacks: true,
									alwaysShowTracks: true
								});
								I.find("form").addClass("js-scroll")
							}
							$.doTimeout(100, function () {
								e.closest(W).next().find("input").focus();
								if (se) {
									se.update();
									se.scrollTo(0, 1e3, 800)
								}
							})
						}
					}
				}
			});
			V.on("click", function () {
				if (!T) {
					T = true;
					TweenMax.to(I, 1, {
						autoAlpha: 0,
						ease: Elastic.easeOut.config(1, .7),
						y: 0,
						pointerEvents: "none"
					});
					TweenMax.to(F.find(".cta-bg path"), 1, {
						morphSVG: F.find(".cta-bg path").attr("data-init"),
						ease: Elastic.easeOut.config(1, .7),
						autoAlpha: 1,
						delay: .2,
						onComplete: function () {
							TweenMax.to(U.find("path"), 2, {
								morphSVG: "M384.7,365.3C276.2,451.3,63.3,270.5,245,127c114.9-90.8,392,14,328.5,145.7C513.5,397.1,472,296,384.7,365.3z",
								repeat: -1,
								yoyo: true,
								ease: Sine.easeInOut
							})
						}
					});
					TweenMax.to(F.find("h3"), 1, {
						scale: 1,
						ease: Elastic.easeOut.config(1, .7),
						autoAlpha: 1,
						delay: .4,
						force3D: true
					});
					TweenMax.to(F.find(".elastic-btn svg"), 1, {
						ease: Elastic.easeOut.config(1, .7),
						scale: 1,
						autoAlpha: 1,
						delay: .5,
						force3D: true
					});
					TweenMax.to(F.find(".elastic-btn span"), 1, {
						scale: 1,
						ease: Elastic.easeOut.config(1, .7),
						autoAlpha: 1,
						delay: .6,
						force3D: true
					});
					TweenMax.to(V, .1, {
						scale: .85,
						rotation: 180,
						opacity: 0,
						ease: Elastic.easeOut.config(1, .7),
						pointerEvents: "none",
						force3D: true
					});
					TweenMax.to(I.find(".form-container"), .1, {
						ease: Elastic.easeOut.config(1, .7),
						autoAlpha: 0,
						scale: .9,
						force3D: true
					});
					I.find(".project-form").removeClass("js-open-form");
					if (_browserObj.isPhone && !$_headerMain.hasClass("js-drop")) {
						TweenMax.to($_headerMain, 1, {
							y: 0,
							ease: Elastic.easeOut.config(1, .7)
						})
					}
					if (_browserObj.isPhone) {
						$_window.scrollTop(0)
					}
					if (_globalViewportW <= 640) {
						TweenMax.set(I, {
							y: 0
						});
						if (_browserObj.isPhone) {
							disableBodyScroll(false)
						}
					}
				}
			});
			U.find("path").on("click", function () {
				te.stop();
				if (T) {
					if (Z.hasClass("show")) {
						TweenMax.to(Z, 1, {
							autoAlpha: 0,
							scale: .9,
							className: "-=show",
							ease: Elastic.easeOut.config(1, .07)
						})
					}
					T = false;
					le = true;
					I.find(".project-form").addClass("js-open-form");
					TweenMax.to(F.find(".cta-bg path"), 1, {
						morphSVG: F.find(".cta-bg path").attr("data-close"),
						ease: Elastic.easeOut.config(1, .7),
						autoAlpha: 0
					});
					TweenMax.to(F.find("h3"), 1, {
						scale: .9,
						ease: Elastic.easeOut.config(1, .7),
						autoAlpha: 0,
						force3D: true
					});
					TweenMax.to(F.find("h3"), 1, {
						scale: .9,
						ease: Elastic.easeOut.config(1, .7),
						autoAlpha: 0,
						force3D: true
					});
					TweenMax.to(F.find(".elastic-btn span"), 1, {
						scale: .9,
						ease: Elastic.easeOut.config(1, .7),
						autoAlpha: 0,
						force3D: true
					});
					TweenMax.to(F.find(".elastic-btn svg"), 1, {
						ease: Elastic.easeOut.config(1, .7),
						scale: .9,
						autoAlpha: 0,
						force3D: true
					});
					TweenMax.to(I, 1, {
						autoAlpha: 1,
						ease: Elastic.easeOut.config(1, .7),
						pointerEvents: "all"
					});
					TweenMax.to(V, 1, {
						scale: 1,
						rotation: 0,
						opacity: 1,
						ease: Elastic.easeOut.config(1, .7),
						pointerEvents: "all",
						force3D: true
					});
					TweenMax.to(I.find(".form-container"), 1, {
						ease: Elastic.easeOut.config(1, .7),
						autoAlpha: 1,
						scale: 1,
						delay: .1,
						force3D: true
					});
					if (_browserObj.isPhone) {
						TweenMax.to($_headerMain, 1, {
							y: -$_headerMain.outerHeight(),
							ease: Elastic.easeOut.config(1, .7)
						})
					}
					$.doTimeout(400, function () {
						W.eq(0).find("input").focus();
						if (_browserObj.isPhone) {
							disableBodyScroll(true)
						}
					});
					if (_browserObj.isPhone) {
						if (_scrollY > 0) {
							TweenMax.set(I, {
								y: _scrollY + _globalViewportH - I.find("form").outerHeight() - $_headerMain.outerHeight()
							})
						}
					}
				}
			});
			I.find(".project-form").on("submit", function (e) {
				var t = $(this);
				e.stopImmediatePropagation();
				e.preventDefault();
				e.stopPropagation();
				if (validateForm(I.find(".project-form")) && !I.find(".project-form").hasClass("sending") && C) {
					I.find(".project-form").addClass("sending");
					W.find("input").css("pointer-events", "none");
					z.css("pointer-events", "none");
					TweenMax.to(B.find(".morph-bg"), .4, {
						opacity: 0,
						ease: Elastic.easeOut.config(1, .7)
					});
					TweenMax.to(B.find(".arrow"), .4, {
						opacity: 0,
						ease: Elastic.easeOut.config(1, .7)
					});
					TweenMax.to(B.find(".text"), .4, {
						opacity: 0,
						ease: Elastic.easeOut.config(1, .7)
					});
					TweenMax.to(K, 1, {
						opacity: 1,
						ease: Elastic.easeOut.config(1, .7)
					});
					z.each(function () {
						var e = $(this);
						if (!e.hasClass("done") && !C) {
							TweenMax.to(e.find(".stroke"), 1, {
								fill: "red",
								ease: Elastic.easeOut.config(1, .7)
							})
						}
					});
					var a = {
						_wp_http_referer: I.find(".project-form").find("input[name='_wp_http_referer']").val(),
						affinity_nonce: I.find(".project-form").find("input[name='affinity_nonce']").val(),
						language: I.find(".project-form").find("#language").val(),
						project_name: I.find(".project-form").find("#name").val(),
						project_email: I.find(".project-form").find("#email").val(),
						project_company: I.find(".project-form").find("#company").val(),
						project_size: I.find(".project-form").find("#company-size").val(),
						project_city: I.find(".project-form").find("#location").attr("data-final-city")
					};

					function o() {
						I.find(".project-form").removeClass("sending");
						I.find(".project-form")[0].reset();
						if (I.find(".project-form").hasClass("js-open-form")) V.click();
						TweenMax.to(Q, 1, {
							autoAlpha: 1,
							ease: Elastic.easeOut.config(1, .7)
						});
						J.find("input").css("pointer-events", "all");
						TweenMax.to(I.find("form"), 1, {
							height: fe,
							ease: Elastic.easeOut.config(1, .7)
						});
						for (i = 1; i <= 5; i++) {
							W.eq(i).addClass("hidden")
						}
						if (se) {
							se.setPosition(0, 0);
							Scrollbar.destroy(I.find("form")[0])
						}
						I.find("form").removeClass("js-scroll");
						ce = fe;
						z.removeClass("done");
						J.removeClass("erro, error");
						TweenMax.to(z.find(".full"), 1, {
							opacity: 0,
							ease: Elastic.easeOut.config(1, .7)
						});
						TweenMax.to(z.find(".dash"), 1, {
							strokeDashoffset: 23,
							ease: Elastic.easeOut.config(1, .7)
						});
						C = false;
						$("#location").attr("data-final-city", null);
						TweenMax.to(B, 1, {
							opacity: .5,
							pointerEvents: "none",
							ease: Elastic.easeOut.config(1, .7)
						});
						N.css("padding-bottom", 0);
						TweenMax.to(N, 1, {
							autoAlpha: 0,
							ease: Elastic.easeOut.config(1, .7)
						});
						TweenMax.to(B.find(".morph-bg"), .4, {
							opacity: 1,
							ease: Elastic.easeOut.config(1, .7)
						});
						TweenMax.to(B.find(".arrow"), .4, {
							opacity: 1,
							ease: Elastic.easeOut.config(1, .7)
						});
						TweenMax.to(B.find(".text"), .4, {
							opacity: 1,
							ease: Elastic.easeOut.config(1, .7)
						});
						W.find("input").css("pointer-events", "all");
						z.css("pointer-events", "all");
						$.doTimeout(1e3, function () {
							if (!_browserObj.isMobile) {
								I.css("top", _scrollY + _globalViewportH - I.outerHeight() - 125)
							} else {
								TweenMax.to(I, 1, {
									y: 0,
									ease: Elastic.easeOut.config(1, .7)
								})
							}
						})
					}
					submitJSON("startProject", a, function (e) {
						N.css("padding-bottom", "150px");
						TweenMax.to(N, 1, {
							autoAlpha: 1,
							ease: Elastic.easeOut.config(1, .7)
						});
						TweenMax.to(K, 1, {
							opacity: 0,
							ease: Elastic.easeOut.config(1, .7)
						});
						if (se != null) {
							se.update();
							se.scrollTo(0, 1e3, 800, function () {
								$.doTimeout(1500, function () {
									o()
								})
							})
						} else {
							TweenMax.to(I.find("form"), 1, {
								height: ce + ne + 40,
								ease: Elastic.easeOut.config(1, .7)
							});
							if (!_browserObj.isMobile) {
								TweenMax.to(I, 1, {
									top: _scrollY + _globalViewportH - I.outerHeight() - 125 - ne - 40,
									ease: Elastic.easeOut.config(1, .7)
								})
							}
							$.doTimeout(1500, function () {
								o()
							})
						}
					}, function () {})
				}
			})
		};
		this.kill = function () {
			$_window.off("resize.startProject")
		}
	}

	function xe() {
		var c = $(".cases-studies"),
			e = $(".cases-studies ul li"),
			r = e.filter(".active"),
			t = r.find(".case-letter").clone(),
			f = $(".fazemos-lightbox"),
			a = $(".cases-studies ul li.active .case-letter"),
			o = $(".fazemos-lightbox .elastic-btn"),
			d = $(".case-studies-lightbox-content"),
			l = $(".next-prev-slider-helper .slider-helper"),
			p = null,
			u = d.find(".case-item").eq(0),
			h = {
				active: {},
				next: {},
				prev: {},
				all: [],
				total: {}
			};
		var i = function () {
			if (d.length <= 0) return;
			d.find(".case-item").each(function (e) {
				var t = $(this),
					a = {};
				a.element = t;
				a.index = e;
				a.image = t.find("img").attr("src");
				a.case_subject = t.find(".case-subject").text();
				a.case_title = t.find(".case-title").text();
				a.case_text = t.find(".case-text").text();
				a.morph_path = t.find(".morph-letter svg path").attr("d");
				h.all.push(a)
			});
			h.active = 0;
			h.prev = null;
			h.next = 1;
			h.total = Number(d.find(".case-item").length);
			if (d.find(".case-item").length > 1) {
				TweenMax.to(l.filter(".next-slide").find("svg path"), .5, {
					morphSVG: h.all[h.next].morph_path,
					ease: Elastic.easeOut.config(1, .8)
				});
				l.filter(".next-slide").find("svg path").attr("data-current", h.all[h.next].morph_path);
				l.filter(".next-slide").find("h3").text(h.all[h.next].case_title);
				TweenMax.set(t, {
					top: "65%",
					right: "initial",
					left: "0px",
					rotation: 120,
					scale: 0
				})
			} else {
				TweenMax.set(l.filter(".next-slide"), {
					autoAlpha: 0
				})
			}
			TweenMax.set(r.find(".case-letter"), {
				scale: 0
			});
			r.find(".case-study-image").prepend(t);
			TweenMax.set(l.filter(".prev-slide"), {
				x: "-120%"
			});
			if (b) {
				m($(".case-studies-lightbox-content li[data-slug='" + b + "']").attr("data-id"))
			}
			r.find(".case-study-info button").on("click", function () {
				var e = r.attr("data-id");
				history.pushState({}, {}, "?case-study=" + r.attr("data-slug"));
				m(e)
			});
			o.on("click", function () {
				g()
			});
			l.on("mouseenter", function () {
				var e = $(this);
				TweenMax.to(e.find("svg path"), 1, {
					morphSVG: "M7.738,102.134c4.597,61.825,41.883,70.919,91.681,70.919c49.797,0,73.231-34.722,73.231-77.552 c0-42.833-23.435-77.479-73.231-77.553C45.564,17.868,2.805,35.829,7.738,102.134z",
					ease: Elastic.easeOut.config(1, .8)
				})
			});
			l.on("mouseleave", function () {
				var e = $(this);
				TweenMax.to(e.find("svg path"), 1, {
					morphSVG: e.find("svg path").attr("data-current"),
					ease: Elastic.easeOut.config(1, .8)
				})
			});
			l.on("click", function () {
				var e = $(this),
					t = Number(e.attr("data-id"));
				if (e.hasClass("next-slide")) s(t, "next");
				else s(t, "prev")
			});
			$(document).on("keydown.casesSlider", function (e) {
				switch (e.which) {
					case 39:
						if (h.next != null) s(h.next, "next");
						break;
					case 37:
						if (h.prev != null) s(h.prev, "prev");
						break;
					default:
						break
				}
			})
		};
		var n = function () {
			TweenMax.staggerTo([a, t], 4, {
				scale: 1,
				ease: Elastic.easeOut.config(1, .7)
			}, .4)
		};
		var s = function (e, t) {
			var a = d.find(".case-item").eq(e),
				o = a.find(".slider li").eq(0).find("img").clone(),
				i = a.find(".case-subject").clone(),
				n = a.find(".case-title").clone(),
				s = a.find(".case-text").clone();
			u = d.find(".case-item").eq(r.attr("data-id"));
			r.attr("data-id", e);
			r.attr("data-slug", a.attr("data-slug"));
			r.find(".block-bg-cover picture").append(o);
			TweenMax.to([r.find(".case-study-info .case-subject"), r.find(".case-study-info .case-title"), r.find(".case-study-info .case-text")], 1, {
				y: "-20px",
				opacity: 0,
				ease: Power4.easeOut,
				onComplete: function () {
					r.find(".case-study-info .case-subject").remove();
					r.find(".case-study-info .case-title").remove();
					r.find(".case-study-info .case-text").remove();
					TweenMax.set([i, n, s], {
						opacity: 0,
						y: "20px"
					});
					r.find(".case-study-info").prepend(s).prepend(n).prepend(i);
					TweenMax.to([r.find(".case-study-info .case-subject"), r.find(".case-study-info .case-title"), r.find(".case-study-info .case-text")], 1, {
						opacity: 1,
						y: "0px",
						ease: Power4.easeOut
					})
				}
			});
			if (t == "next") {
				if (h.next != null) {
					h.prev = h.active;
					h.active = h.next;
					if (h.total - 1 == e) {
						h.next = null;
						TweenMax.to(l.filter(".next-slide"), 1, {
							x: "120%",
							ease: Power4.easeOut
						});
						l.filter(".next-slide").attr("data-id", h.next)
					} else {
						h.next = h.next + 1;
						l.filter(".next-slide").attr("data-id", h.next);
						TweenMax.to(l.filter(".next-slide").find("svg path"), 1, {
							morphSVG: h.all[h.next].morph_path,
							ease: Elastic.easeOut.config(1, .8)
						});
						l.filter(".next-slide").find("svg path").attr("data-current", h.all[h.next].morph_path);
						TweenMax.to(l.filter(".next-slide").find("h3"), .5, {
							y: "-10px",
							opacity: 0,
							ease: Power4.easeOut,
							onComplete: function () {
								l.filter(".next-slide").find("h3").text(h.all[h.next].case_title);
								TweenMax.fromTo(l.filter(".next-slide").find("h3"), .5, {
									y: "5px",
									opacity: 0
								}, {
									y: "0px",
									opacity: 1,
									ease: Power4.easeOut
								})
							}
						})
					}
					TweenMax.to(l.filter(".prev-slide").find("svg path"), 1, {
						morphSVG: h.all[h.prev].morph_path,
						ease: Elastic.easeOut.config(1, .8)
					});
					l.filter(".prev-slide").find("svg path").attr("data-current", h.all[h.prev].morph_path);
					TweenMax.to(l.filter(".prev-slide").find("h3"), .25, {
						y: "-5px",
						opacity: 0,
						ease: Power4.easeOut,
						onComplete: function () {
							l.filter(".prev-slide").find("h3").text(h.all[h.prev].case_title);
							TweenMax.fromTo(l.filter(".prev-slide").find("h3"), .25, {
								y: "5px",
								opacity: 0
							}, {
								y: "0px",
								opacity: 1,
								ease: Power4.easeOut
							})
						}
					});
					l.filter(".prev-slide").attr("data-id", h.prev);
					TweenMax.to(l.filter(".prev-slide"), 1, {
						x: "0%",
						ease: Power4.easeOut
					})
				}
				TweenMax.fromTo($(".case-study-image .case-background-morph"), 1, {
					x: "0%"
				}, {
					x: "-100%",
					ease: Expo.easeOut,
					onComplete: function () {
						r.find(".block-bg-cover picture img").attr("src", h.all[h.active].image);
						TweenMax.to($(".case-study-image .case-background-morph .letter-morph"), 1.5, {
							morphSVG: $(".case-study-image .case-background-morph .letter-morph").attr("data-original"),
							ease: Expo.easeOut
						});
						TweenMax.to($(".case-study-image .case-background-morph"), 1, {
							x: "-200%",
							ease: Expo.easeOut,
							onComplete: function () {
								TweenMax.set($(".case-study-image .case-background-morph"), {
									clearProps: "all"
								})
							}
						})
					}
				})
			} else {
				if (h.prev != null) {
					h.next = h.active;
					h.active = h.prev;
					if (0 == e) {
						h.prev = null;
						TweenMax.to(l.filter(".prev-slide"), 1, {
							x: "-120%",
							ease: Power4.easeOut
						});
						l.filter(".prev-slide").attr("data-id", h.prev)
					} else {
						h.prev = h.prev - 1;
						l.filter(".prev-slide").attr("data-id", h.prev);
						TweenMax.to(l.filter(".prev-slide").find("svg path"), 1, {
							morphSVG: h.all[h.prev].morph_path,
							ease: Elastic.easeOut.config(1, .8)
						});
						l.filter(".prev-slide").find("svg path").attr("data-current", h.all[h.prev].morph_path);
						TweenMax.to(l.filter(".prev-slide").find("h3"), .25, {
							y: "-5px",
							opacity: 0,
							ease: Power4.easeOut,
							onComplete: function () {
								l.filter(".prev-slide").find("h3").text(h.all[h.prev].case_title);
								TweenMax.fromTo(l.filter(".prev-slide").find("h3"), .25, {
									y: "5px",
									opacity: 0
								}, {
									y: "0px",
									opacity: 1,
									ease: Power4.easeOut
								})
							}
						})
					}
					TweenMax.to(l.filter(".next-slide").find("svg path"), 1, {
						morphSVG: h.all[h.next].morph_path,
						ease: Elastic.easeOut.config(1, .8)
					});
					l.filter(".next-slide").find("svg path").attr("data-current", h.all[h.next].morph_path);
					l.filter(".next-slide").attr("data-id", h.next);
					TweenMax.to(l.filter(".next-slide").find("h3"), .25, {
						y: "-5px",
						opacity: 0,
						ease: Power4.easeOut,
						onComplete: function () {
							l.filter(".next-slide").find("h3").text(h.all[h.next].case_title);
							TweenMax.fromTo(l.filter(".next-slide").find("h3"), .25, {
								y: "5px",
								opacity: 0
							}, {
								y: "0px",
								opacity: 1,
								ease: Power4.easeOut
							})
						}
					});
					TweenMax.to(l.filter(".next-slide"), 1, {
						x: "0%",
						ease: Power4.easeOut
					})
				}
				TweenMax.fromTo($(".case-study-image .case-background-morph"), 1, {
					rotation: 180,
					x: "-200%"
				}, {
					x: "-100%",
					rotation: 180,
					ease: Expo.easeOut,
					onComplete: function () {
						r.find(".block-bg-cover picture img").attr("src", h.all[h.active].image);
						TweenMax.to($(".case-study-image .case-background-morph .letter-morph"), 1.5, {
							morphSVG: $(".case-study-image .case-background-morph .letter-morph").attr("data-original"),
							ease: Expo.easeOut
						});
						TweenMax.to($(".case-study-image .case-background-morph"), 1, {
							x: "0%",
							ease: Expo.easeOut,
							onComplete: function () {
								TweenMax.set($(".case-study-image .case-background-morph"), {
									clearProps: "all"
								})
							}
						})
					}
				})
			}
			TweenMax.to($(".case-study-image .case-background-morph .letter-morph"), 1.5, {
				morphSVG: $(".case-study-image .case-background-morph .background-rect"),
				ease: Expo.easeOut
			})
		};
		var m = function (e) {
			var t = $(".cases-studies ul li.active"),
				a = d.find(".case-item").eq(e),
				o = $(".fazemos-lightbox .case-images-container .slider"),
				i = _scrollY + t.find(".case-study-image img").offset().top - (_globalViewportH - t.find(".case-study-image img").height()) / 2,
				n = null;
			$(document).on("keydown.casesLightbox", function (e) {
				switch (e.which) {
					case 27:
						g();
					default:
						break
				}
			});
			$_body.addClass("caseStudies-lightbox-opened");
			f.find(".slider").remove();
			f.find(".case-info-container").remove();
			f.find(".case-images-container").append(a.find(".slider").clone());
			f.append(a.find(".case-info-container").clone());
			f.find(".case-info").append("<div class='gradient'></div>");
			if (!$_html.hasClass("ie") && !$_html.hasClass("edge") && !$_body.hasClass("mobile")) {
				$(".gradient").css("top", _globalViewportH - $(".gradient").outerHeight());
				_customScroll.unregisterEvents(/scroll/, /wheel/, /touchstart/)
			} else {
				TweenMax.set(".gradient", {
					autoAlpha: 1
				})
			}
			if (_customScroll == null) {} else {
				_customScroll.unregisterEvents(/scroll/, /wheel/, /touchstart/);
				p = Scrollbar.init($(".case-info-container")[0], {
					speed: .8,
					syncCallbacks: true
				});
				p.setPosition(0, 0)
			}
			if (p == null) {
				var s = function () {
					n = _rAF_loop(l)
				};
				$_window[0].addEventListener("scroll", s, {
					passive: true
				});
				var r = {
					offset: {
						y: -1
					}
				};
				r.offset.y = window.pageYOffset
			} else {
				caseStudies_scroll_listener = function (e) {
					l(e)
				};
				p.addListener(caseStudies_scroll_listener)
			}

			function l(e) {
				if (!$_html.hasClass("ie") && !$_html.hasClass("edge") && !$_body.hasClass("mobile")) {
					$(".gradient").css("top", e.offset.y + (_globalViewportH - $(".gradient").outerHeight()))
				}
				if (_scrollY > 10) {
					TweenMax.to(f.find(".scroll-helper, .mouse-icon"), 1, {
						opacity: 0,
						ease: Elastic.easeOut.config(1, .8)
					})
				} else {
					TweenMax.to(f.find(".scroll-helper, .mouse-icon"), 1, {
						opacity: 1,
						ease: Elastic.easeOut.config(1, .8)
					})
				}
			}
			o = $(".fazemos-lightbox .case-images-container .slider");
			o.slick({
				dots: true,
				arrows: true
			});
			$(".slick-arrow.slick-next").html("<svg viewBox='0 0 67.69 62.47'><path d='M33.37 2.12c14-.74 28.83 1.33 32 26.33s-17.17 31.84-28.67 32-31.17-.5-34.33-27 14.93-30.48 31-31.33z' stroke-miterlimit='10' stroke-width='5' fill='none' stroke='#2a6ff3'/><path stroke-linecap='round' stroke-linejoin='round' stroke-width='5' fill='none' stroke='#2a6ff3' d='M27.01 18.39l13.67 12.22-11.8 13.46'/></svg>");
			$(".slick-arrow.slick-prev").html("<svg viewBox='0 0 67.69 62.47'><path d='M34.33 2.12c-14-.74-28.83 1.33-32 26.33S19.5 60.29 31 60.45s31.17-.5 34.33-27-14.93-30.48-31-31.33z' stroke-miterlimit='10' stroke-width='5' fill='none' stroke='#2a6ff3'/><path stroke-linecap='round' stroke-linejoin='round' stroke-width='5' fill='none' stroke='#2a6ff3' d='M40.68 18.39L27.01 30.61l11.81 13.46'/></svg>");
			TweenMax.set(f, {
				x: _globalViewportW,
				autoAlpha: 1
			});
			TweenMax.to(v, 1, {
				y: -v.height() - 50,
				ease: Power4.easeInOut
			});
			TweenMax.to(f, 1.5, {
				x: 0,
				delay: 1,
				ease: Power4.easeOut,
				onComplete: function () {
					if (_customScroll == null) lockBodySafari()
				}
			});
			TweenMax.to(c, 1.7, {
				x: "-100px",
				delay: 1,
				ease: Expo.easeOut
			});
			TweenMax.to($(".fazemos-lightbox .case-background-morph .letter-morph"), 2, {
				morphSVG: $(".fazemos-lightbox .case-background-morph .background-rect"),
				ease: Expo.easeOut,
				delay: 1
			});
			TweenMax.to($(".fazemos-lightbox .case-background-morph .letter-morph"), 1, {
				morphSVG: "M3000,3000H-1V0h3001V3000z",
				ease: Expo.easeOut,
				delay: 1.5,
				onComplete: function () {
					TweenMax.fromTo($(".fazemos-lightbox .slider"), 1, {
						y: 20,
						autoAlpha: 0
					}, {
						y: 0,
						autoAlpha: 1
					})
				}
			})
		};
		var g = function () {
			$_body.removeClass("caseStudies-lightbox-opened");
			if (!$_html.hasClass("ie") && !$_html.hasClass("edge")) {
				if (_customScroll) {
					_customScroll.registerEvents(/scroll/, /wheel/, /touchstart/)
				} else {
					unlockBodySafari()
				}
				if (_scrollY > _globalViewportH) {
					TweenMax.to(v, 1, {
						y: 0,
						ease: Power4.easeInOut
					})
				}
			} else {
				TweenMax.set(".gradient", {
					autoAlpha: 0
				});
				$_body.css({
					overflow: "visible",
					height: "auto"
				});
				$_html.css({
					overflow: "visible",
					height: "auto"
				})
			}
			history.pushState({}, {}, window.location.pathname);
			TweenMax.to(f, 1.5, {
				x: _globalViewportW,
				ease: Expo.easeOut
			});
			TweenMax.to(c, 1.7, {
				x: "0px",
				ease: Expo.easeOut,
				onComplete: function () {
					if (p) {
						p.destroy()
					}
					p = null
				}
			});
			$(".fazemos-lightbox .case-images-container .slider").slick("unslick");
			TweenMax.to($(".case-background-morph .letter-morph"), 2, {
				morphSVG: $(".case-background-morph .letter-morph").attr("data-original"),
				ease: Expo.easeOut
			});
			TweenMax.to($(".fazemos-lightbox .slider"), .2, {
				y: 20,
				autoAlpha: 0
			});
			$(document).off("keydown.casesLightbox")
		};
		var w = function () {
			$(document).off("keydown.casesSlider")
		};
		return {
			init: i,
			kill: w,
			open: m,
			animate: n
		}
	}

	function _e(n) {
		var e = 20;
		var s;
		var r = Date.now();
		var l = 1e3 / e;
		var c;
		var t = window.devicePixelRatio;
		if (t == 2) {
			var f = "intro";
			var a = 1
		} else {
			var f = "intro_low";
			var a = 2
		}
		var d = 0,
			p = 23,
			u = 74,
			h = 700,
			m = 425;
		if (n == "who-we-are") {
			u = 74;
			h = 1400;
			m = 850;
			p = 23
		}
		if (n == "outsourcing") {
			u = 72;
			h = 850;
			m = 850;
			p = 11
		}
		if (n == "software") {
			u = 103;
			h = 1400;
			m = 725;
			p = 20
		}
		var g = new PIXI.Application(h, m, {
				transparent: true
			}),
			w = null,
			b = null,
			v = null;
		var o = function () {
			$_pageHeader.find(".background-wrapper .image-wrapper").append(g.view);
			if (t == 1) {
				$(".page-header canvas").css({
					transform: "translate3d(-50%,-50%,0) scale(1.2)",
					top: "50%",
					left: "50%"
				})
			}
			g.stop();
			var e = new PIXI.loaders.Loader;
			e.add(n + "_0", "/public/imgs/" + n + "/" + f + "/" + n + "_0.png");
			e.load(function () {
				var e = [];
				var t = PIXI.Texture.fromFrame(n + "_0");
				e.push(t);
				b = new PIXI.extras.AnimatedSprite(e);
				b.x = h / 2;
				b.y = m / 2;
				b.anchor.set(.5);
				b.width = h;
				b.height = m;
				TweenMax.set(b.scale, {
					x: .98,
					y: .98
				});
				TweenMax.set(b, {
					alpha: 0
				});
				g.stage.addChild(b);
				TweenMax.to(b.scale, .5, {
					x: 1,
					y: 1,
					ease: Expo.easeOut
				});
				TweenMax.to(b, .5, {
					alpha: 1
				});
				g.start();
				var a = new PIXI.loaders.Loader;
				for (i = 0; i < u; i++) {
					a.add(n + "_" + i, "/public/imgs/" + n + "/" + f + "/" + n + "_" + i + ".png")
				}
				a.load(function () {
					o()
				})
			});

			function o() {
				var e = [],
					t;
				for (t = 0; t < u; t++) {
					var a = PIXI.Texture.fromFrame(n + "_" + t);
					e.push(a)
				}
				w = new PIXI.extras.AnimatedSprite(e);
				w.x = h / 2;
				w.y = m / 2;
				w.anchor.set(.5);
				w.width = h;
				w.height = m;
				TweenMax.set(w.scale, {
					x: .98,
					y: .98
				});
				TweenMax.set(w, {
					alpha: 0
				});
				g.stage.addChild(w);
				TweenMax.to(w.scale, .5, {
					x: 1,
					y: 1,
					ease: Expo.easeOut
				});
				TweenMax.to(w, .5, {
					alpha: 1,
					onComplete: function () {
						g.start();
						v = _rAF_loop(o);
						o();
						g.stage.removeChild(b);
						b = null
					}
				});

				function o() {
					v = _rAF_loop(o);
					s = Date.now();
					c = s - r;
					if (c > l) {
						r = s - c % l;
						if (d == u) d = p;
						w.gotoAndStop(d++)
					}
				}
			}
		};
		var x = function () {
			if (w) w.destroy();
			if (b) b.destroy();
			if (g) g.destroy();
			cancelAnimationFrame(v);
			v = null
		};
		return {
			init: o,
			kill: x
		}
	}

	function ye() {
		var a = $(".technologies"),
			o = $(".technologies-list");
		if ($_body.hasClass("mobile")) return;
		var e = {
			rootMargin: "0px",
			threshold: [.25]
		};
		var t = function (e, t) {
			e.forEach(function (e) {
				if (e.isIntersecting || e.intersectionRatio > 0) {
					t.unobserve(e.target);
					switch (e.target.type) {
						case "technologiesElement":
							TweenMax.staggerTo(a.find(".column"), 1, {
								y: "0px",
								opacity: 1
							}, .1);
							break;
						case "technologiesListElement":
							TweenMax.to(o.find(".hashtag-title"), 1, {
								y: 0,
								ease: Expo.easeOut,
								delay: .1
							});
							break
					}
				}
			})
		};
		var i = new IntersectionObserver(t, e);
		a[0].type = "technologiesElement";
		TweenMax.set(a.find(".column"), {
			y: "150px",
			opacity: 0
		});
		i.observe(a[0]);
		o[0].type = "technologiesListElement";
		TweenMax.set(o.find(".hashtag-title"), {
			y: o.find(".hashtag-title").height()
		});
		i.observe(o[0])
	}

	function $e() {
		if (!$_body.hasClass("mobile")) {
			var e = tippy(".js-tooltip", {
				arrow: true,
				trigger: "mouseenter",
				theme: "map-tooltip"
			})
		} else {
			var e = tippy(".js-tooltip", {
				arrow: true,
				trigger: "click",
				theme: "map-tooltip"
			});
			return
		}
	}

	function Te() {
		var n = $(".list-titles"),
			e = $(".list-titles-wrapper"),
			s = $(".list-titles li"),
			t = $(".lists-items-wrapper"),
			r = $(".lists-items-wrapper .list"),
			l = $(".technologies-list h2.js-title-element");
		l.html("<span class='js-main-text'>" + l.text() + "</span>");
		t.data("real-width", t.width());
		if (_browserObj.isMobile) {
			l.height(l.height())
		}
		if ($_window.width() <= 768) {
			var a = n.height();
			n.height(s.eq(0).height() + 5);
			e.on("click", function () {
				var e = $(this);
				e.toggleClass("js-open");
				if (e.hasClass("js-open")) {
					TweenMax.to(n, .75, {
						height: a,
						ease: Elastic.easeOut.config(1, 1)
					})
				} else {
					TweenMax.to(n, .5, {
						height: s.filter(".active").height() + 5,
						ease: Power4.easeOut
					})
				}
			})
		}
		s.on("click", function () {
			var e = $(this),
				t = e.attr("data-target"),
				a = r.filter("." + t),
				o = Math.round(100 * a.find(".column").length / 3);
			if (!w) {
				w = true;
				s.removeClass("active");
				e.addClass("active");
				var i = $("<span class='js-aux-text'>" + e.text() + "</span>");
				TweenMax.set(i, {
					top: l.height() + 10,
					position: "absolute",
					width: "100%",
					height: "100%"
				});
				l.append(i);
				TweenMax.set(l.find(".js-main-text"), {
					opacity: 0
				});
				TweenMax.to(l.find(".js-aux-text"), 1, {
					y: -(l.height() + 10),
					ease: Expo.easeOut,
					onComplete: function () {
						l.find(".js-main-text").remove();
						l.find(".js-aux-text").attr("style", "").removeClass("js-aux-text").addClass("js-main-text");
						w = false
					}
				});
				if ($_window.width() <= 768) {
					e.prependTo(n)
				}
				TweenMax.set(e.find("p"), {
					y: "40px",
					opacity: 0
				});
				TweenMax.to(r.filter(".active"), .1, {
					y: "40px",
					opacity: 0,
					onComplete: function () {
						TweenMax.fromTo(a, 1, {
							y: "40px",
							opacity: 0
						}, {
							y: "0px",
							opacity: 1,
							ease: Expo.easeOut
						});
						r.filter(".active").removeClass("active");
						a.addClass("active")
					}
				})
			}
		})
	}

	function Me(e) {

		if (verge.inViewport(gs, -100) && !gs.hasClass("js-animated")) {
			gs.addClass("js-animated");
			ss.animate()
		}

		if (!_browserObj.isMobile) {
			if (_scrollY > _globalViewportH / 2 && !$_pageHeader.hasClass("js-paused")) {
				$_pageHeader.addClass("js-paused");
				S.pause()
			}
			if (_scrollY < _globalViewportH / 2 && $_pageHeader.hasClass("js-paused")) {
				$_pageHeader.removeClass("js-paused");
				S.resume()
			}
		}
		if (!$_html.hasClass("ie") && !$_html.hasClass("edge") && !$_body.hasClass("mobile")) {
			if (_customScroll) {
				h.css("top", e.offset.y)
			}
			if ($_body.hasClass("what-we-do")) {
				if (_customScroll) {
					I.css("top", e.offset.y + _globalViewportH - I.outerHeight() - 125);
					Y.css("top", e.offset.y)
				}
			}
		}
		if (_scrollDirection == "down") {
			if (_scrollY > _globalViewportH && _scrollY < _scrollLimit - _globalViewportH && !v.hasClass("js-animated")) {
				v.addClass("js-animated");
				TweenMax.to(v, 1, {
					y: 0,
					ease: Power4.easeInOut
				})
			} else if ((_scrollY <= _globalViewportH || _scrollY >= _scrollLimit - _globalViewportH) && v.hasClass("js-animated")) {
				v.removeClass("js-animated");
				TweenMax.to(v, 1, {
					y: -v.height() - 50,
					ease: Power4.easeInOut
				})
			}
		} else if (v.hasClass("js-animated")) {
			if (!j) {
				j = true;
				v.removeClass("js-animated");
				TweenMax.to(v, 1, {
					y: -v.height() - 50,
					ease: Power4.easeInOut,
					onComplete: function () {
						j = false
					}
				})
			}
		}
		if (!_browserObj.isPhone) {
			a.animateSection(_scrollY);
			if (_scrollY < _globalViewportH) {
				a.hide()
			}
			if (verge.inViewport($_pageFooter, -100 ) && !$_pageFooter.hasClass("js-animated-2")) {
				$_pageFooter.addClass("js-animated-2");
				$_pageFooter.addClass("add-bg-color");
				$_pageFooter.prev("section").addClass("add-bg-color");
				$.doTimeout(250, function () {
					if (!$_body.hasClass("mobile")) {
						os.animate()
					}
				})
				a.hide();

				if ( $_body.hasClass("what-we-do")) {
					TweenMax.to(F, 1, {
						scale: .85,
						ease: Elastic.easeOut.config(1, .7),
						autoAlpha: 0,
						force3D: true
					})
				}
			} else if (!verge.inViewport($_pageFooter, -100) && $_pageFooter.hasClass("js-animated-2")) {
				$_pageFooter.removeClass("js-animated-2");
				$_pageFooter.removeClass("add-bg-color");
				$_pageFooter.prev("section").removeClass("add-bg-color");
				a.show();
				if ($_body.hasClass("what-we-do")) {
					TweenMax.to(F, 1, {
						scale: 1,
						ease: Elastic.easeOut.config(1, .7),
						autoAlpha: 1,
						force3D: true
					})
				}
			}
			if (verge.inViewport(c, -500) && !c.hasClass("js-animated")) {
				a.show()
			}
			if (verge.inViewport(ee, -150) && !ee.hasClass("js-animated") && !$_body.hasClass("mobile")) {
				r.find("path").each(function (e) {
					var t = $(this);
					TweenMax.from(t, 1, {
						scale: 0,
						delay: e * .03,
						transformOrigin: "center center",
						ease: Elastic.easeOut.config(1, 1.2)
					})
				});
				ee.addClass("js-animated");
				TweenMax.to(ee, 0, {
					opacity: 1,
					ease: Expo.easeOut,
					onComplete: function () {}
				});
				TweenMax.staggerTo(".point", .1, {
					opacity: 1,
					delay: .2,
					ease: Elastic.easeOut.config(1, .5)
				}, .1)
			}
		}
		if (!_browserObj.isMobile) {
			if ($_body.hasClass("what-we-do")) {
				if (!L.hasClass("done")) {
					o.animate(_scrollY)
				}
			}
		}


		if (_browserObj.isPhone) {
			if(v){
				if (v.hasClass("js-animated") && I.find(".project-form").hasClass("js-open-form")) {
					TweenMax.to(v, 1, {
						y: -v.height() - 50,
						ease: Elastic.easeOut.config(1, .7)
					})
				}
			}
			
		}
		if (_browserObj.isMobile) return;
		if (!_browserObj.isIE && !_browserObj.isEdge && !_browserObj.isMobile) {
			//g.init(e.offset.y)
		}
		if (verge.inViewport(s, -600) && !s.hasClass("js-animated")) {
			s.addClass("js-animated");
			t.animate()
		}
		var ie_check = msieversion();
		if(p.length>0 ){
			if (!_browserObj.isMobile || ie_check===false) {
				if (!_browserObj.isSafari) {
					p.each(function () {
						var e = $(this);
						if (verge.inViewport(e) && !e.hasClass("js-animated")) {
							e.addClass("js-animated");
							if( e.data("animation") ){
								e.data("animation").play();
							}
							
						} else if (!verge.inViewport(e) && e.hasClass("js-animated")) {
							e.removeClass("js-animated");
							if( e.data("animation") ){
								e.data("animation").stop();
							}
							
						}
					})
				}
			}
		}

		
		if (verge.inViewport(n) && !n.hasClass("js-animated")) {
			n.addClass("js-animated");
			TweenMax.staggerTo(n.find("svg path"), 2, {
				"stroke-dashoffset": 0,
				ease: Expo.easeInOut
			}, .15);
			TweenMax.staggerTo(n.find("p"), 2, {
				y: "0px",
				opacity: 1,
				delay: .1,
				ease: Expo.easeInOut
			}, .18)
		}
	}
	return {
		init: me,
		kill: ge
	}
}

function what_we_doPage(e) {
	if ($_body.hasClass("js-no-ajax")) {
		var t = 0,
			a = 1
	} else {
		var t = .5,
			a = 1.5
	}
	var o = 0,
		i = animateRollingLetters(),
		n = letterMorphParallax($(".place-images svg.hb_logo")),
		s = sequenceDance($(".to-section")),
		r = new L,l,c,

		
		f = loveAttraction({
			target: $(".humble-brands .brand-item"),
			factor: .1
		}),
		d = textFireworks({
			element: $(".page-footer h2"),
			animationType: "smoothEntrance",
			delay: 0,
			setupArgs: {
				yValue: 100,
				animationTime: .4
			}
		}),
		p = textFireworks({
			element: $(".page-footer h3"),
			animationType: "smoothEntrance",
			delay: .3,
			setupArgs: {
				yValue: 40,
				animationTime: .8
			}
		}),
		u = headerParallax($(".page-header")),
		h = new GlAnimation({
			title: "What we do",
			desktopMaxWidth: 1440,
			objsGroup_ScaleDesktop: 1,
			objLetter_ScaleDesktop: .78,
			objGroupX_desktop: 0,
			objGroupY_desktop: 1.2,
			objGroupZ_desktop: 0,
			desktopMaxMaxWidth: 1800,
			objsGroup_ScaleMaxDesktop: 1,
			objLetter_ScaleMaxDesktop: .78,
			objGroupX_MaxDesktop: 0,
			objGroupY_MaxDesktop: 1.2,
			objGroupZ_MaxDesktop: 0,
			objWidthBigDesktop: 1,
			objLetter_ScaleBigDesktop: .78,
			objGroupX_bigDesktop: 0,
			objGroupY_bigDesktop: 1.2,
			objGroupZ_bigDesktop: 0,
			aniTime: 1.2,
			aniTimeLoop: 5.5,
			aniTimeFadeIn: .5,
			mouseInfluence: .65
		});
	var m = $(".gallery"),
		g = $(".to-section"),
		w = $(".rotate-title h2"),
		b = $(".to-section .columns").find("h3"),
		v = $(".morphing-letter"),
		x = $(".place-images"),
		_ = $(".js-height"),
		y = $(".humble-brands"),
		T = $(".vertical-menu-wrapper"),
		M = $(".image-blocks"),
		k = $(".page-footer"),
		j = [];

	if($(".page-header h2").length>0){
		var l = textFireworks({
		element: $(".page-header h2"),
		animationType: "jellyType",
		delay: t
	});
	}
	if($(".page-header h3").length>0){
		var c = textFireworks({
			element: $(".page-header h3"),
			animationType: "smoothEntrance",
			delay: a
		});
	}
	var C = function () {
		if (_customScroll == null) {
			_scrollRef = function () {
				_raf_loop_id = _rAF_loop(V)
			};
			$_window.on("scroll.what-we-doPage", _scrollRef)
		} else {
			_page_scroll_listener = function (e) {
				V(e)
			};
			_customScroll.addListener(_page_scroll_listener)
		}
		w.each(function () {
			var e = $(this);
			e.css("height", e.closest(".container").outerHeight() + "px")
		});
		S();
		A();
		elasticButtonsInputs();
		P();
		O();
		n.init();
		if (!_browserObj.isMobile) {
			s.init();
			d.init();
			p.init();
			r.init();
			u.init();
			h.init(function () {
				$.doTimeout(1e3, function () {
					if($(".page-header h2").length>0){
						l.init();
					}
					if($(".page-header h3").length>0){
						c.init();
					}
					
				})
			});
			f.init();
			i.init();
			if (!$_html.hasClass("safari")) {
				animateMorphLetters()
			}
			TweenMax.to($_body.find(".main-logo"), .5, {
				opacity: 1
			});
			TweenMax.to($_body.find(".language-wrapper"), .5, {
				opacity: 1
			});
			hoverLinksMenu()
		}
		if ($_body.hasClass("mobile") && $_window.width() <= 1024) {
			var mt = $(".mission").attr("data-mobile-title");
			$(".mission h2").html( mt)
		}
	};
	var E = function () {
		if (!$_body.hasClass("mobile")) {
			if (u) u.kill();
			if (i) i.kill();
			if (l) l.kill();
			if (c) c.kill();
			if (f) f.kill();
			if (d) d.kill();
			if (r) r.kill();
			if (p) p.kill();
			if (h) h.kill()
		}
		_scrollRef = null;
		cancelAnimationFrame(_raf_main_id);
		if (_customScroll) {
			_customScroll.removeListener(_page_scroll_listener)
		} else {
			$_window.off("scroll.what-we-doPage");
			$_window[0].cancelAnimationFrame(_raf_loop_id)
		}
	};

	function O() {
		var e = $(".humble-brands"),
			t = e.find(".block-wrapper"),
			a = e.find(".plus-button"),
			o = e.find(".overlay"),
			i = 0,
			n = null;
		t.each(function () {
			var e = $(this);
			i = e.outerHeight()
		});
		t.on("mouseenter", function () {
			var e = $(this);
			if (e.hasClass("js-open") || e.hasClass("no-button")) return;
			TweenMax.to(e.find(".plus-button"), .4, {
				scale: 1,
				rotation: 360,
				ease: Expo.easeOut
			})
		}).on("mouseleave", function () {
			var e = $(this);
			if (e.hasClass("js-open") || e.hasClass("no-button")) return;
			TweenMax.to(e.find(".plus-button"), .4, {
				scale: 0,
				rotation: 0,
				ease: Expo.easeOut,
				clearProps: "all"
			})
		});
		t.find(".gradient-block").each(function () {
			var e = $(this),
				t = e.find("h2"),
				a = e.find("p"),
				o = $(".brand-item"),
				i = $(".elastic-btn");
			if (t.outerHeight(true) + a.outerHeight(true) < e.outerHeight(true)) {
				e.closest(o).find(i).addClass("btn-disabled")
			} else {
				e.closest(o).find(i).removeClass("btn-disabled")
			}
		});
		t.on("click", function () {
			var e = $(this),
				t = e.find(".gradient-block"),
				a = e.find(".morph-element");
			if (e.hasClass("no-button")) return;
			e.toggleClass("js-open");
			if (e.hasClass("js-open")) {
				if (_customScroll != null) {
					_customScroll.scrollTo(0, e.offset().top + _customScroll.offset.y - 60, 700, function () {
						_customScroll.unregisterEvents(/scroll/, /wheel/, /touchstart/);
						TweenMax.set($(".humble-brands").find(".overlay"), {
							top: _scrollY + "px",
							onComplete: function () {
								TweenMax.to($(".humble-brands").find(".overlay"), .4, {
									autoAlpha: 1
								})
							}
						})
					})
				} else {
					TweenMax.to($_window, 1, {
						scrollTo: {
							y: e.offset().top - 60
						},
						ease: Power4.easeOut
					})
				}
				if (e.data("real-height-block") > _globalViewportH * .8) {
					n = Scrollbar.init(t[0], {
						speed: .8
					});
					var o = _globalViewportH - 120;
					TweenMax.to(e, .4, {
						height: o + "px",
						zIndex: 2,
						ease: Expo.easeOut
					});
					TweenMax.to(e.find(".gradient-block"), .4, {
						height: o - 150,
						ease: Expo.easeOut
					})
				} else {
					TweenMax.to(e, .4, {
						height: e.data("real-height-block"),
						zIndex: 2,
						ease: Expo.easeOut
					});
					TweenMax.to(e.find(".gradient-block"), .4, {
						height: e.find(".gradient-block").data("real-height-gradient"),
						ease: Expo.easeOut
					})
				}
				TweenMax.to(a, 1, {
					morphSVG: a.attr("data-click"),
					ease: Elastic.easeOut.config(1, .7)
				});
				TweenMax.set(e.find(".gradient"), {
					opacity: 0
				})
			} else {
				if (_customScroll != null) _customScroll.registerEvents(/scroll/, /wheel/, /touchstart/);
				if (n != null) {
					n.destroy();
					n = null
				}
				TweenMax.to(e, .4, {
					height: i,
					ease: Expo.easeOut,
					clearProps: "zIndex"
				});
				TweenMax.to(a, 1, {
					morphSVG: a.attr("data-original"),
					ease: Elastic.easeOut.config(1, .7)
				});
				TweenMax.to($(".humble-brands").find(".overlay"), .4, {
					autoAlpha: 0,
					clearProps: "all"
				});
				TweenMax.to(e.find(".gradient-block"), .2, {
					height: 250,
					overflow: "hidden"
				});
				TweenMax.set(e.find(".gradient"), {
					opacity: 1
				});
				TweenMax.to(e.find(".plus-button"), .4, {
					scale: 0,
					rotation: 0,
					ease: Expo.easeOut,
					clearProps: "all"
				})
			}
		});
		o.on("click", function () {
			t.filter(".js-open").find(".plus-button").click()
		})
	}

	function S() {
		
		var a = $(".clients");
		
		if(a.length>0){
			var e = {
			rootMargin: "0px",
			threshold: [.25]
			};
			if ($_body.hasClass("mobile")) return;
			var t = function (e, t) {
				e.forEach(function (e) {
					if (e.isIntersecting || e.intersectionRatio > 0) {
						t.unobserve(e.target);
						switch (e.target.type) {
							case "clientsElement":
								TweenMax.staggerTo(a.find(".column"), 1, {
									opacity: 1,
									y: 0
								}, .1);
								break
						}
					}
				})
			};
			var o = new IntersectionObserver(t, e);
			a[0].type = "clientsElement";
			TweenMax.set(a.find(".column"), {
				opacity: 0,
				y: 40
			});
			o.observe(a[0])
		}
		
	}

	function P() {
		m.each(function () {
			var e = $(this),
				t = e.find("li").length;
			e.css("width", $(this).find("li").outerWidth() * t + "px");
			e.find("li").css("width", m.outerWidth() / t + "px")
		})
	}

	function H() {
		var a = $("form");
		a.find("input").on("focus", function () {
			TweenMax.staggerTo(a.find("label"), .5, {
				opacity: 0,
				ease: Expo.easeOut
			})
		});
		a.find("input").on("blur", function () {
			var e = $(this);
			if (e.val() === 0 || e.val() == "") {
				TweenMax.staggerTo(a.find("label"), .5, {
					opacity: 1,
					ease: Expo.easeOut
				})
			}
		});
		a.on("submit", function (e) {
			var t = $(this);
			e.stopImmediatePropagation();
			e.preventDefault();
			e.stopPropagation();
			a.find("button")[0].path_line.stop().animate({
				path: a.find("button").data("normal")
			}, 1e3, mina.elastic)
		})
	}

	function A() {
		var e = $(".team-person-container");
		e.each(function () {
			var e = $(this);
			e.find("svg").data("normal", "M23.145,369.802  c19.022,255.764,173.271,293.386,379.272,293.386c206.003,0,302.946-143.638,302.946-320.822 c0-177.187-96.944-320.518-302.946-320.823C179.634,21.21,2.745,95.514,23.145,369.802z");
			e.find("svg").data("active", e.find("svg path").attr("d"));
			e.find("svg path").attr("d", e.find("svg").data("normal"));
			TweenMax.set(e.find("svg"), {
				opacity: 0,
				scale: 0
			})
		});
		e.on("mousemove", function (e) {
			var t = $(this).find("svg");
			var a = e.clientX - t.parent()[0].getBoundingClientRect().left - t.width() / 2;
			var o = e.clientY - t.parent()[0].getBoundingClientRect().top - t.height() / 2;
			TweenMax.to(t, .5, {
				x: a * .1,
				y: o * .1
			});
			TweenMax.to(t, 1, {
				opacity: 1,
				scale: 1,
				ease: Elastic.easeOut.config(1, .75)
			});
			TweenMax.to(t.find("path"), 1, {
				morphSVG: t.data("active"),
				ease: Elastic.easeOut.config(1, .5)
			})
		});
		e.on("mouseleave", function () {
			var e = $(this).find("svg");
			TweenMax.to(e, .5, {
				y: 0,
				x: 0,
				ease: Elastic.easeOut.config(1, .75)
			});
			TweenMax.to(e, .2, {
				opacity: 0,
				scale: 0
			});
			TweenMax.to(e.find("path"), 1, {
				morphSVG: e.data("normal"),
				ease: Elastic.easeOut.config(1, .75)
			})
		})
	}

	function D() {
		var a = $(".clients"),
			o = $(".clients article"),
			i = $(".blob"),
			n = $(".drag-helper:not(.drag-left)"),
			s = $(".drag-helper.drag-left"),
			t = o.length,
			r = false,
			l = 0,
			c = 0,
			f = 0,
			d = 0,
			p = 0,
			u;
		var e = function () {
			$_window.on("resize.carouselClients", $.debounce(1e3, m));
			TweenMax.set(i, {
				y: (a.outerHeight() - i.outerHeight()) / 2
			});
			TweenMax.set(".clients-wrapper", {
				height: $(".clients-wrapper").find("article").outerHeight()
			});
			o.each(function () {
				var e = $(this),
					t = e.index();
				if (t == 0) {
					p = 0;
					o.eq(p).addClass("active")
				} else {
					if ($_window.width() < 1650) {
						p = 350
					} else if ($_window.width() < 1440) {
						p = 450
					} else {
						p = 500
					}
				}
				e.css({
					left: $(this).outerWidth() * t - p * t
				})
			});
			i.hover(function () {
				var e = $(this);
				TweenMax.to(e, 1, {
					scale: 1,
					force3D: true,
					ease: Elastic.easeOut.config(1, .7)
				})
			}, function () {
				var e = $(this);
				TweenMax.to(e, 1, {
					scale: .7,
					force3D: true,
					ease: Elastic.easeOut.config(1, .7)
				})
			});
			i.on("mouseleave", function () {
				if (r == false) {
					return
				}
				var e = $(this);
				u == e.attr("data-direction");
				TweenMax.to(e.find("path"), 1, {
					morphSVG: e.find("path").attr("data-original"),
					ease: Expo.easeOut
				});
				TweenMax.to(e, 1, {
					x: 0,
					force3D: true,
					ease: Elastic.easeOut.config(1, .7)
				});
				if (d < t && u == "right") {
					d++;
					TweenMax.to(a.find(".clients-wrapper"), .6, {
						x: "+=" + (-o.outerWidth() + p),
						ease: Power2.easeOut
					});
					a.find("article.active").removeClass("active");
					a.find("article").eq(d).addClass("active")
				}
				if (d > 0 && u == "left") {
					d--;
					TweenMax.to(a.find(".clients-wrapper"), .6, {
						x: "-=" + (-o.outerWidth() + p),
						ease: Power2.easeOut
					});
					a.find("article.active").removeClass("active");
					a.find("article").eq(d).addClass("active");
					TweenMax.to($(".blob-right"), 1, {
						x: 0,
						force3D: true,
						ease: Elastic.easeOut.config(1, .7)
					})
				}
				if (d < 1) {
					TweenMax.to($(".blob-left"), 1, {
						x: -450,
						force3D: true,
						ease: Elastic.easeOut.config(1, .7)
					});
					TweenMax.to(s, 1, {
						autoAlpha: 0,
						ease: Power2.easeOut
					});
					TweenMax.to(s.find("svg"), .3, {
						x: 0,
						force3D: true,
						ease: Power2.easeOut
					});
					TweenMax.to(s.find("h4"), .3, {
						x: 0,
						force3D: true,
						ease: Power2.easeOut
					})
				}
				if (d > 0) {
					TweenMax.to($(".blob-left"), 1, {
						x: 0,
						force3D: true,
						ease: Elastic.easeOut.config(1, .7)
					});
					TweenMax.to(s, 1, {
						autoAlpha: 1,
						ease: Power2.easeOut
					})
				}
				if (d > t - 2) {
					TweenMax.to($(".blob-right"), 1, {
						x: 450,
						force3D: true,
						ease: Elastic.easeOut.config(1, .7)
					});
					TweenMax.to(n, 1, {
						autoAlpha: 0,
						ease: Power2.easeOut
					});
					TweenMax.to(n.find("svg"), .3, {
						x: 0,
						force3D: true,
						ease: Power2.easeOut
					});
					TweenMax.to(n.find("h4"), .3, {
						x: 0,
						force3D: true,
						ease: Power2.easeOut
					})
				} else {
					TweenMax.to(n, 1, {
						autoAlpha: 1,
						ease: Power2.easeOut
					})
				}
				l = 0;
				r = false
			});
			i.on("mousedown", function (e) {
				r = true;
				c = e.pageX;
				var t = $(this);
				u = t.attr("data-direction");
				TweenMax.to(t.find("path"), 1, {
					morphSVG: "M.4 336.83C12 50.47 263 8.05 493 .28S915 146.62 921.65 344.5 747.26 708.75 517.24 716.87C268.47 725.64-12 643.92.4 336.83z",
					ease: Expo.easeOut
				})
			});
			i.on("mouseup", function () {
				TweenMax.to(n.find("svg"), .3, {
					x: 0,
					force3D: true,
					ease: Power2.easeOut
				});
				TweenMax.to(n.find("h4"), .3, {
					x: 0,
					force3D: true,
					ease: Power2.easeOut
				});
				var e = $(this);
				TweenMax.to(e.find("path"), 1, {
					morphSVG: e.find("path").attr("data-original"),
					ease: Expo.easeOut
				});
				TweenMax.to(e, 1, {
					x: 0,
					force3D: true,
					ease: Elastic.easeOut.config(1, .7)
				});
				l = 0;
				r = false;
				c = 0
			});
			i.on("mousemove", function (e) {
				var t = $(this);
				u = t.attr("data-direction");
				if (r) {
					if (u == "right") {
						f = c - e.pageX;
						if (f > 150) f = 150;
						if (f < 0) {
							f = 0
						}
						l = f;
						TweenMax.to(t, .3, {
							x: -l,
							force3D: true,
							ease: Power2.easeOut
						});
						TweenMax.to(n.find("svg"), .3, {
							x: -(l + l / 2),
							force3D: true,
							ease: Power2.easeOut
						});
						TweenMax.to(n.find("h4"), .3, {
							x: -(l + l / 4),
							force3D: true,
							ease: Power2.easeOut
						})
					} else {
						f = e.pageX;
						if (f > 200) f = 50;
						if (f < 0) {
							f = 0
						}
						l = f;
						TweenMax.to(t, .3, {
							x: l,
							force3D: true,
							ease: Power2.easeOut
						});
						TweenMax.to(s.find("svg"), .3, {
							x: l + l / 2,
							force3D: true,
							ease: Power2.easeOut
						});
						TweenMax.to(s.find("h4"), .3, {
							x: l + l / 4,
							force3D: true,
							ease: Power2.easeOut
						})
					}
				} else {
					TweenMax.to(t, 1, {
						x: 0,
						force3D: true,
						ease: Elastic.easeOut.config(1, .7)
					});
					l = 0
				}
			})
		};
		var h = function () {
			$_window.off("resize.carouselClients")
		};

		function m() {
			o.each(function () {
				var e = $(this),
					t = e.index();
				if (t == 0) {
					p = 0;
					o.eq(p).addClass("active")
				} else {
					if ($_window.width() < 1650) {
						p = 350
					} else if ($_window.width() < 1440) {
						p = 450
					} else {
						p = 500
					}
				}
				e.css({
					left: $(this).outerWidth() * t - p * t
				});
				TweenMax.to(a.find(".clients-wrapper"), .6, {
					x: 0,
					ease: Power2.easeOut
				});
				o.removeClass("active");
				o.eq(0).addClass("active");
				TweenMax.to($(".blob-left"), 1, {
					x: -450,
					y: (a.outerHeight() - i.outerHeight()) / 2,
					force3D: true,
					ease: Elastic.easeOut.config(1, .7)
				});
				TweenMax.to($(".blob-right"), 1, {
					x: 0,
					y: (a.outerHeight() - i.outerHeight()) / 2,
					force3D: true,
					ease: Elastic.easeOut.config(1, .7)
				});
				d = 0
			})
		}
		e();
		return {
			init: e,
			kill: h
		}
	}

	function L() {
		this.init = function () {
			_.each(function (e) {
				var t = $(this),
					a = {};
				a.element = t;
				if (e == 3) {
					a.offset = t.offset().top - parseInt(y.css("paddingTop")) - parseInt(y.css("paddingBottom"))
				} else {
					a.offset = t.offset().top
				}
				a.height = t.outerHeight(true);
				a.lineWidth = T.find("li").eq(_.length - 1 - e).find(".container-height").outerWidth();
				j.push(a)
			});
			T.find("li").click(function () {
				var e = $(this),
					t = e.index();
				e.prevAll("li").addClass("active");
				e.addClass("active");
				if (_customScroll) {
					_customScroll.scrollTo(0, j[j.length - 1 - t].offset, 2e3, function () {
						_customScroll.registerEvents(/scroll/, /wheel/, /touchstart/)
					})
				} else {
					$("html, body").animate({
						scrollTop: j[j.length - 1 - t].offset
					}, 1e3)
				}
			});
			$_window.on("resize.verticalMenu", $.debounce(500, e))
		};
		this.animateSection = function (e) {
			for (var t = 0; t < j.length; t++) {
				if (e > j[t].offset && e < j[t].offset + j[t].height) {
					var a = normalizedValue(e - j[t].offset, j[t].height, 0);
					T.find("li").eq(j.length - 1 - t).addClass("active");
					TweenMax.set(T.find("li").eq(j.length - 1 - t).find(".container-height"), {
						scaleX: a,
						force3D: true
					})
				}
				if (e < j[t].offset) {
					T.find("li").eq(j.length - 1 - t).removeClass("active");
					TweenMax.set(T.find("li").eq(j.length - 1 - t).find(".container-height"), {
						scaleX: 0,
						force3D: true
					})
				}
			}
		};
		this.show = function () {
			TweenMax.staggerTo(T.find("li"), 1, {
				y: 0,
				ease: Elastic.easeOut.config(1, .8)
			}, .1);
			T.addClass("enable")
		};
		this.hide = function () {
			TweenMax.staggerTo(T.find("li"), 1, {
				y: -200,
				ease: Elastic.easeOut.config(1, .8)
			}, .1);
			T.removeClass("enable")
		};
		this.kill = function () {
			$_window.off("resize.verticalMenu")
		};

		function e() {
			j = [];
			_.each(function (e) {
				var t = $(this),
					a = {};
				a.element = t;
				if (e == 3) {
					a.offset = t.offset().top - parseInt(y.css("paddingTop")) - parseInt(y.css("paddingBottom"))
				} else {
					a.offset = t.offset().top
				}
				a.height = t.outerHeight(true);
				a.lineWidth = T.find("li").eq(_.length - 1 - e).find(".container-height").outerWidth();
				j.push(a)
			})
		}
	}

	function I() {
		var e = $(".clients"),
			a = $(".clients article"),
			t = $(".blob"),
			o = $(".drag-helper:not(.drag-left)"),
			i = $(".drag-helper.drag-left"),
			n = a.length,
			s = false,
			r = 0,
			l = 0,
			c = 0,
			f = 0,
			d = 0,
			p;
		var u = function () {
			TweenMax.set(e, {
				height: $(".clients-wrapper").find("article").outerHeight()
			});
			TweenMax.set(".clients-wrapper", {
				height: $(".clients-wrapper").find("article").outerHeight()
			});
			a.each(function () {
				var e = $(this),
					t = e.index();
				if (t == 0) {
					d = 0;
					a.eq(d).addClass("active")
				} else {
					if ($_window.width() < 1650) {
						d = 350
					} else {
						d = 0
					}
					if ($_window.width() < 1440) {
						d = 450
					}
					if ($_window.width() <= 1024) {
						d = 0
					}
				}
				e.css({
					left: $(this).outerWidth() * t - d * t
				})
			});
			a.on("swiperight", function () {
				if (f > 0) {
					f--;
					TweenMax.to(e.find(".clients-wrapper"), .6, {
						x: "-=" + (-a.outerWidth() + d),
						ease: Power2.easeOut
					});
					e.find("article.active").removeClass("active");
					e.find("article").eq(f).addClass("active");
					TweenMax.to(o, .6, {
						autoAlpha: 1,
						ease: Power2.easeOut
					})
				}
				if (f == 0) {
					TweenMax.to(i, .6, {
						autoAlpha: 0,
						ease: Power2.easeOut
					})
				}
			});
			a.on("swipeleft", function () {
				if (f < n - 1) {
					f++;
					TweenMax.to(e.find(".clients-wrapper"), .6, {
						x: "+=" + (-a.outerWidth() + d),
						ease: Power2.easeOut
					});
					e.find("article.active").removeClass("active");
					e.find("article").eq(f).addClass("active");
					TweenMax.to(i, .6, {
						autoAlpha: 1,
						ease: Power2.easeOut
					})
				}
				if (f == n - 1) {
					TweenMax.to(o, .6, {
						autoAlpha: 0,
						ease: Power2.easeOut
					})
				}
			})
		};
		u();
		return {
			init: u
		}
	}
	if (!$_body.hasClass("mobile")) D();
	else I();

	function V(e) {
		if (verge.inViewport(g, -100) && !g.hasClass("js-animated")) {
			g.addClass("js-animated");
			s.animate()
		}
		if (verge.inViewport(x, -_globalViewportH)) {
			n.animateIn()
		}
		if (!$_html.hasClass("ie") && !$_html.hasClass("edge") && !$_body.hasClass("mobile")) {
			if (_customScroll) {
				T.css("top", e.offset.y)
			}
		}
		if (!_browserObj.isMobile) {
			if (_scrollY > 10) {
				if (!$_pageHeader.hasClass("js-animated")) {
					$_pageHeader.addClass("js-animated");
					h.pause()
				}
			} else {
				if ($_pageHeader.hasClass("js-animated")) {
					$_pageHeader.removeClass("js-animated");
					h.resume()
				}
			}
		}

		var ie_check = msieversion();
		if (!_browserObj.isMobile || ie_check===false) {
			if (!_browserObj.isSafari) {
				v.each(function () {
					var e = $(this);
					if (verge.inViewport(e) && !e.hasClass("js-animated")) {
						e.addClass("js-animated");
						e.data("animation").play()
					} else if (!verge.inViewport(e) && e.hasClass("js-animated")) {
						e.removeClass("js-animated");
						e.data("animation").stop()
					}
				})
			}
		}
		if (!_browserObj.isPhone) {
			r.animateSection(_scrollY);
			if (_scrollY < _globalViewportH) {
				r.hide()
			}
			if (verge.inViewport(k, -100) && !k.hasClass("js-animated-2")) {
				k.addClass("js-animated-2");
				k.addClass("add-bg-color");
				k.prev("section").addClass("add-bg-color");
				r.hide();
				$.doTimeout(250, function () {
					d.animate();
					p.animate();
					$.doTimeout(250, function () {
						if (!$_body.hasClass("mobile")) {
							i.animate()
						}
					})
				})
			} else if (!verge.inViewport(k, -100) && k.hasClass("js-animated-2")) {
				k.removeClass("js-animated-2");
				k.removeClass("add-bg-color");
				k.prev("section").removeClass("add-bg-color");
				r.show();
				i.pause()
			}
			if (verge.inViewport(M, -500) && !M.hasClass("js-animated")) {
				r.show()
			}
		}
	}
	return {
		init: C,
		kill: E
	}
}


function msieversion() {

    var ua = window.navigator.userAgent;
    var msie = ua.indexOf("MSIE ");
    var ie_response;

    if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./))  // If Internet Explorer, return version number
    {
       ie_response = true;
    }
    else  // If another browser, return 0
    {
        ie_response = false;
    }

    return ie_response;
}



 var _captchaTries = 0;

function recaptchaOnload() {
    _captchaTries++;
    if (_captchaTries > 9)
        return;
    if ($('.g-recaptcha').length > 0) {
        grecaptcha.render("recaptcha", {
            sitekey: '6LcgIZIUAAAAAI861ymnRHtqR1obORvsu8P6SR8X',
            callback: function() {
                console.log('recaptcha callback');
            }
        });
        return;
    }
    window.setTimeout(recaptchaOnload, 1000);
}