var keydown_timeout;
var keydown_xhr;
var uploadedInitiated = false;

function initContactForm() {

    $('.contact-form').validate({
        submitHandler: function() {
            var data = $('.contact-form').serialize();
            var object = {
                'success': function (response){
                    $('.contact-form .form-response').removeClass('success');
                    $('.contact-form .form-response').removeClass('error');
                    $('.contact-form .form-response').removeClass('show');
                    if(response['status']=='ok'){
                        $('.contact-form .form-response').addClass('success').addClass('show').html(response['message']);
                    }else{
                        $('.contact-form .form-response').addClass('error').addClass('show').html(response['error']);
                    }
                },
                'data' : data
            };
            $.ajax(object);
            return false;
        },
        errorPlacement: function(error, element) {
            // error.hide();
        },
        highlight: function (element, errorClass, validClass) {
            /*$(element).parent().find('.form-control-feedback').remove();
             $(element).closest('.form-group').append('<span class=" form-control-feedback"><i class="fa fa-exclamation-triangle"></i></span>');
             $(element).parent().addClass('has-error has-feedback');*/
        },
        unhighlight: function (element, errorClass, validClass) {
            /*$(element).parent().find('.form-control-feedback').remove();
             $(element).parent().removeClass('has-error has-feedback');*/
        }
    });
}


function getHash() {
    var hash = window.location.hash;
    return hash.substring(2); // remove #!
}


function confirm_overwrite(message, callback) {
    var c = confirm(message);
    if (c) {
        callback();
    }
}

function confirmation(callback, id, message) {
    var c = confirm(message);
    if (c) {
        callback(id);
    }
}

function scroll2element(el) {
    var position = $(el).offset();
    var $padding = $body.css('paddingTop').replace('px','');
    var y = position.top - 200;
    $('html, body').stop().animate({scrollTop: y}, 'slow');
}


$(function(){

    $.validator.setDefaults({
        debug: false,
        errorPlacement: function(error, element) {
            error.hide();
        },
        highlight: function (element, errorClass, validClass) {
            $(element).parent().find('.form-control-feedback').remove();
            $(element).parent().append('<span class=" form-control-feedback"><i class="fa fa-times"></i></span>');
            $(element).parent().addClass('has-error has-feedback');
        },
        unhighlight: function (element, errorClass, validClass) {
            $(element).parent().find('.form-control-feedback').remove();
            $(element).parent().removeClass('has-error has-feedback');
        }
    });
});


function limitText(limitField, limitCount, limitNum) {
    if (limitField.value.length > limitNum) {
        limitField.value = limitField.value.substring(0, limitNum);
    } else {
        limitCount.html(limitNum - limitField.value.length);
    }
}

function isNumber(evt) {
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        return false;
    }
    return true;
}
function checkEmail(emailAddress) {
    var reValidEmail = new RegExp("^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$");

    return reValidEmail.test(emailAddress);
}