var xhr, scheduled = false, _throttleDelay = 200, $body, $modal, $secondaryModal,$window;
var timeout = false;
var delta = 200;
var subnavOrigin = 0;
var body = document.querySelector("body");

$(function(){	
	"use strict";
	
	$.cookieBar({
		element: 'body',
		zindex: '999999',
		message: 'This website uses cookies to ensure that we can provide you with the best user experience. Select "Accept" if you consent to this or find out more by reading the following Privacy Policy.',
		acceptText: 'Accept',
		policyText: 'Privacy',
		policyURL: 'http://humble-holdings.com/us/en/privacy-policy/',
		bottom: true,
		domain: 'http://humble-holdings.com/',
		referrer: 'http://humble-holdings.com/'
	});
	
	callbacks();
	
});

$(window).load( function(){
    
   
    if (window.location.hash) {
      var target = location.hash.split('#');
      setTimeout(function() {
        window.scrollTo(0, 0);
      }, 1);
    }
   if (window.location.hash) {
      var anchorpage = location.hash.split('#');
      setTimeout(function () {
        goToAnchorPage(anchorpage[1]);
      }, 500);
    }
    
});

$(document).ready(function () {
	"use strict";
    $(document).on('click', 'a.filter', function (e) {
        var key = $(this).data('filterName'),
            target = $(this).data('target'),
            value = $(this).data('value'),
            label = $(this).text(),
            multiple = $(this).data('multiple');

        if($(this).closest('li').hasClass('active') || $(this).hasClass('active')){
            return false;
        }

        if (multiple == true) {

            if ($(this).parent().hasClass('active')) {
                // it's already checked. Uncheck it please
                $(this).parent().removeClass('active');
                $('form[name="' + target + '-nav"] input[name="' + key + '[]"][value="' + value + '"]').remove();

            } else if (value == '') {
                $('a.filter[data-filter-name="' + key + '"]').parent().removeClass('active');
                $('form[name="' + target + '-nav"] input[name="' + key + '[]"]').remove();
            } else {
                // Not checked
                $(this).parent().addClass('active');
                $('form[name="' + target + '-nav"]').append('<input type="hidden" name="' + key + '[]" value="' + value + '"/>');
            }
        } else {
            $('.filter[data-filter-name="' + key + '"]').parent().removeClass('active');
            $(this).parent().addClass('active');
            var t = $(this).closest('.filter-group').find('.current-filter-value');
            if(value!==''){
                t.html(label);
            }else if(value===''){
                t.html(t.data('default'));
            }

            $('form[name="' + target + '-nav"] input[name="' + key + '"]').val(value);
        }


        filter(target);

        e.preventDefault();
    });



	if ("ontouchstart" in window || navigator.msMaxTouchPoints) {
		$('body').addClass('touch');
	} else {
		$('body').addClass('no-touch');		
	}

	
    var keydown_timeout;
    $body = $('body');
    $window = $(window);

    $body.append('<div id="modalSecondary" class="modal fade"><div class="modal-content"></div></div>');
    $body.append('<div id="modal" class="modal fade"><div class="modal-content"></div></div>');

    $modal = $('#modal');
    $secondaryModal = $('#modalSecondary');

    $(document).on('ifToggled','.toggle-select-all', function(event){
        var target = $(this).data('toggleSelectAllTarget');
        $(target).each(function () {
            $(this).iCheck('toggle');
        });
    });

  

    $(document).on("click", ".load-more", function () {
        var target = $(this).data('target');
        more(target);
        return false;
    });

    $(document).on('click','a[data-target="#search-collapsible"]',function(){
        registerGAEvent('button','click','search');
        scroll2position(0);
        setTimeout(function(){
            $('input[name="search_param"]').focus();
        },500);
    });



      $(document).on('click', '.scroll-to-element', function (e) {
        scroll2element($(this).attr('href'));
        e.preventDefault();
    });



    $(document).on('click', ".ajax-modal", function (e) {
		
        try{
            if(typeof ($(this).attr("href"))!=="undefined"){
				openAjaxModal($(this).attr('href'), $('#'+ $(this).data('modal')));				
			}else{
				openAjaxModal($(this).data('href'), $('#'+ $(this).data('modal')));
			}
			e.preventDefault();
        }catch(Error){
            console.log(Error);
        }

        return false;
    });

   

    $(document).on('click', '[data-action="submit"]', function () {
        var target = $(this).data('target');
        $(target).submit();
        return false;
    });
    $(document).on('click', '.go-to-top', function () {
        scroll2position(0);
        return false;
    });
   
});

function openAjaxModal(href,$mm){

     $m = $mm || $modal;

    $m.removeData('bs.modal');
    $m.empty();
    $m.load(href,function(){
        $m.modal('show');
        //skinFormElements('#'+$m.attr('id'));
        callbacks('#'+$m.attr('id'));
    });

}


function calculateHeight(target){

}

function equalizeHeight(target){
	$('.'+ target).each(function(){
		$('.'+ target).addClass('autoHeight');
		$('.'+ target).css('min-height','auto');
		if($('.'+ target).parent().hasClass('card')){
			$('.'+ target).css('height','auto');
		}
	});
	
    var min_height = 0;
    setTimeout(function(){
        $('.'+ target).each(function(){
            if($(this).innerHeight() > min_height){
                min_height = $(this).innerHeight();
            }
        });
        if(min_height>0){
            $('.'+ target).css('min-height',min_height);
			if($('.'+ target).parent().hasClass('card')){
				$('.'+ target).css('height',min_height);
			}
        }
		$('.'+ target).each(function(){			 
			$('.'+ target).removeClass('autoHeight');
		});
		
    },500);
	
}


function form_submit_response(target, type, message, r) {
    var redirect = r || false;

    var new_type = 'notice';
    var icon = 'fa fa-check';
    var time = 5000;
    if(type!='success'){
        new_type = type;
        icon='fa fa-warning'
        time = 20000;
    }

    $('#toast-container').empty();


    var $toastContent = $('<p><i class="icon '+icon+' fa-2x"></i> '+message+'</p>');
    Materialize.toast($toastContent, time);

    if (redirect) {
        setTimeout(function () {
            window.location.reload();
        }, 2000);
    }
}

function triggerClick(target) {
	$('.'+target).trigger('click');
}

function callbacks(c) {
    var container = c || '';
    ajaxContent(container + ' .ajax-content');

    $(container + ' .callbacks').each(function () {
        if(!$(this).hasClass('callbacks-executed')){
            if(container !=='' || !$(this).hasClass('callbacks-executed')){
            var c = $(this).data();
            for (var i in c) {
                if (i.indexOf('fn') == 0 && c[i] != '') {
                    var fn = c[i];
                    var params = c[ 'params' + i ] || null;

                    $(this).addClass('callbacks-executed');

                    try {
                        if (c[ 'after' + i ] && typeof(window[c[ 'after' + i ]]) === 'function') {

                            var callbackAfter = c[ 'after' + i ];
                            var callbackAfterParams = c[ 'after' + i + 'Params' ] || null;

                            window[ fn ](params, function(){
                                window[callbackAfter](callbackAfterParams);
                            });
                        } else {
                            window[ fn ](params);
                        }
                    } catch (Error) {
                        console.log(Error + ' '+fn);
                    }
                }
            }
        }
        }
    });
}

function testimonialsCarousel(){
	$(".testimonials-section").slick({
		dots: true,
		dotsClass: "testimonials-dots",
		arrows: false,
		draggable: true,
		cssEase: "cubic-bezier(0.175, 0.885, 0.320, 1.275)",
		speed: 1e3
	});

}


function ScrollHandler(e) {
    if (!scheduled) {
        scheduled = true;
        setTimeout(function () {
            if ($(window).scrollTop() + $(window).height() > $(document).height() - 300) {
                more(e.data.target);
            }
            scheduled = false;
        }, _throttleDelay);
    }
}


function scroll2position(pos,t) {
    $('html, body').animate({scrollTop: pos}, t || 500);
}


function more(target) {
    if ($('form[name="' + target + '-nav"] ').hasClass('busy')) {
        return false;
    }

    var c = $('form[name="' + target + '-nav"] input[name="page"]').val();
    var offset = $('form[name="' + target + '-nav"] input[name="offset"]').val();
    $('form[name="' + target + '-nav"] input[name="page"]').val(Number(c) + 1);
    $('form[name="' + target + '-nav"]').addClass('busy');
    $('.load-more[data-target="'+target+'"]').hide();
    $.ajax({data: $('form[name="' + target + '-nav"]').serialize(), dataType: 'html', success: function (result) {
        $('form[name="' + target + '-nav"]').removeClass('busy');

        var $result = $('<div>' + result + '</div>');

        var num_items = $result.find('.item').length;
        if ( num_items > 0) {
            $result.find('.item').hide();
            $('.' + target + '-list').append($result.html());
            $('.' + target + '-list .item').fadeIn('fast');

            callbacks('.' + target + '-list');

            $('.load-more[data-target="'+target+'"]').show();
            if(num_items<offset){
                $(document).off('scroll', ScrollHandler);
            }
        } else {
            $('.load-more[data-target="'+target+'"]').hide();
            $(document).off('scroll', ScrollHandler);
        }

        $('.' + target + '-list .way-point-animate').waypoint(function(direction) {
            $(this.element).addClass('shown');
        }, {
            offset: '95%'
        });

        if($('.' + target + '-list').data('afterFilter')){
            window[$('.' + target + '-list').data('afterFilter')]($('.' + target + '-list').data('afterFilterParams'));
        }
    }
    });
}

var ajax_contents = [];
function ajaxContent(c){
    $modal = $('#modal');
    var contents = c || '.ajax-content';
    $(contents).each(function () {
        ajax_contents.push(
            {
                'target': $(this),
                'content':$(this).data('ajaxContent')
            }
        );
    });
    loadAjaxContent();
}

function loadAjaxContent(){
    if(ajax_contents.length > 0){
        var target  = ajax_contents[0].target,
            content = ajax_contents[0].content;
            
        /*if(target.hasClass('ajax-load-initiated')) {
            ajax_contents.splice(0, 1);
            if(ajax_contents.length > 0){
                loadAjaxContent();
            }
            return false;
        }
*/
        target.addClass('ajax-load-initiated');

        target.load(content,function(){
            var uniq = (Math.random() + '').replace('0.', '');
            target.addClass('ajax-loaded-'+uniq);
            callbacks('.ajax-loaded-'+uniq);
            ajax_contents.splice(0, 1);
            if(ajax_contents.length > 0){
                loadAjaxContent();
            }
        });
    }else{
        $('.ajax-load-initiated').removeClass('ajax-load-initiated');
    }
}

function copyToClipboard(target){
	$('#'+target).on('click', function(){
		$('#'+target+' .copy-text').select();
		var successful = document.execCommand('copy');
		if(successful) {
			Materialize.toast('URL copied to clipboard', 4000);
		} else {
			Materialize.toast('Oops, unable to copy', 4000);
		}
    });
}



function filter() {
    var target = arguments[0];
    var after = arguments[1];
    $('form[name="' + target + '-nav"] input[name="page"]').val(0);
    var request_data = $('form[name="' + target + '-nav"]').serializeArray(),
        excludes = ['module', 'action', 'active_for', 'language_id', 'data_type', 'country_code'],
        includes = ['cid[]','sort_term','sort_way'],
        elements = {};

    var initialHeight = $('.' + target + '-list').innerHeight();
    $('.' + target + '-list').height(initialHeight).css({'position':'relative','min-height':100,'overflow':'hidden'}).html('<div class="loading la-animate"></div>');

    $.ajax({data: request_data, dataType: 'html', success: function (data) {
        $('.' + target + '-list').css({'overflow':'visible'});
        $('.' + target + '-list').html(data).height('auto');



        var num_items = $('.' + target + '-list .item').length;
        var offset = $('form[name="' + target + '-nav"] input[name="offset"]').val();

        if (after) {
            after.call();
        }else if($('.' + target + '-list').data('afterFilter')){
            window[$('.' + target + '-list').data('afterFilter')]($('.' + target + '-list').data('afterFilterParams'));
        }

        $(document).off('scroll', ScrollHandler);
        if($('.' + target + '-list').hasClass('infinite-scroll') &&  offset != 0 && num_items == offset) {
            $(document).on('scroll', {target: target}, ScrollHandler);


            //$('.loading-more').remove();
            //$('.' + target + '-list').append('<div class="loading-more">Scroll to load more<br><i class="fa fa-arrow-down"></i></div>');
            //$('.loading-more').fadeIn('fast');
            //setTimeout(function(){
            //    $('.loading-more').fadeOut('slow',function(){
            //        $(this).remove();
            //    });
            //},5000);

        }

        if(num_items == offset){
            $('.load-more[data-target="'+target+'"]').show();
            if($('.' + target + '-list').height() < $(window).height() - $('header').height()){
                more(target);
            }
        }else{
            $('.load-more[data-target="'+target+'"]').hide();
        }



    }});

    resize();
}

function registerGAEvent(title,content){
    dataLayer.push({
        'content': content,
        'event': title
    });
};


function goToAnchor(anchor){
    $(document.body).animate({
        'scrollTop':   $('#'+anchor).offset().top
    }, 500);
}

function goToAnchorPage(anchor){
    $('html, body').animate({
        'scrollTop':   $('#'+anchor).offset().top - 200
    }, 500);
}


function goToPageAnchor(page , anchor){
    window.location.href = page+'#'+anchor;
}

